import React, {useCallback, useEffect, useState} from 'react';
import './ListCountDown.scss';
import moment from 'moment';

// import Swiper core and required modules
import SwiperCore, {Autoplay, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {CalenderEvent} from '../../../../models/calenderEvent';
import {ReactComponent as Map} from "../../../../assets/event-calender/notification/svg/map-location.svg";


// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import {useEventContext} from "../../EventContext";

SwiperCore.use([Pagination, Autoplay]);

const ListCountDown = () => {
    const {events} = useEventContext();
    // Function to determine if an event is in the future
    // const isFutureEvent = (eventDate: Date): boolean => {
    //     return eventDate.getTime() > new Date().getTime();
    // };

    // Function to extract links from a text
    function extractLinks(text: string) {
        const regex = /(https?:\/\/[^\s]+)/g;
        return text.match(regex) || [];
    }

    // Countdown component to show the time remaining for an event
    const CountdownComponent: React.FC<{ event: CalenderEvent }> = ({event}) => {
        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;
        const day = hour * 24;

        const [days, setDays] = useState(0);
        const [hours, setHours] = useState(0);
        const [minutes, setMinutes] = useState(0);
        const [seconds, setSeconds] = useState(0);
        const isEventEndingAfterToday = event.end && moment(event.end).isAfter(moment());

        const calculateCountdown = useCallback(() => {
            if (event.start) {
                const now = new Date().getTime();
                const eventTime = new Date(event.start).getTime();
                const distance = eventTime - now;
                if (!isEventEndingAfterToday || distance <= 0) {
                    setDays(0);
                    setHours(0);
                    setMinutes(0);
                    setSeconds(0);
                } else {
                    // Calcul du compte à rebours
                    setDays(Math.floor(distance / day));
                    setHours(Math.floor((distance % day) / hour));
                    setMinutes(Math.floor((distance % hour) / minute));
                    setSeconds(Math.floor((distance % minute) / second));
                }
            }
            else {
                setDays(0);
                setHours(0);
                setMinutes(0);
                setSeconds(0);
            }
        }, [event.start, day, hour, minute, second,isEventEndingAfterToday]);


        useEffect(() => {
            calculateCountdown();
            const interval = setInterval(calculateCountdown, 1000); // Update countdown every second

            return () => {
                clearInterval(interval); // Clear the interval when the component is unmounted
            };
        }, [event.start, calculateCountdown]);

        return (
            <div className="element-55d00d1">
                <div className="element-populated">

                    <p className="heading-title size-default">Starting in :</p>

                    <div className="ts-count-down block-count-down">
                        <div data-start-date="31 Dec 2023 12:00:00" className="countdown etn-countdown-wrap clearfix ">
                            <div className="counter-item">
                                <span id="days" className="day-count days countdown-number">{days}</span>
                                <span className="smalltext">d</span>
                            </div>
                            <div className="counter-item">
                                <span id="hours" className="hr-count hours countdown-number">{hours}</span>
                                <span className="smalltext">h</span>

                            </div>
                            <div className="counter-item">
                                <span id="minutes" className="min-count minutes countdown-number">{minutes}</span>
                                <span className="smalltext">m</span>
                            </div>
                            <div className="counter-item">
                                <span id="seconds" className="sec-count seconds countdown-number">{seconds}</span>
                                <span className="smalltext">s</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // Sort the filtered events in ascending order
    const sortedEvents = events
        .filter(event => {
            // Check if event has a start date.
            if (!event.start || !event.isInCountdown) return false;
            // Include all events (past and future).
            return true;
        })
        .sort((a, b) => {
            // Ensure a.start and b.start are Date objects.
            const startDateA = typeof a.start === 'string' ? new Date(a.start) : a.start!;
            const startDateB = typeof b.start === 'string' ? new Date(b.start) : b.start!;

            // Compare the start dates of two events for ascending order.
            return startDateA.getTime() - startDateB.getTime(); // Invert this comparison for ascending order
        });

    useEffect(() => {
    }, []);
    return (
        <div className="carde fade-in">
            <div className="background-overlay"></div>
            <Swiper
                spaceBetween={30}
                slidesPerView={1}
                pagination={{clickable: true}}
            >
                {sortedEvents.map((event) => (
                    <SwiperSlide key={event.id}>
                        <div className="container column-gap-default">
                            <div className="element-885c050 event-date">
                                <div className="element-populated">
                                    <section className="element-178164d">
                                        <div className="container column-gap-default">
                                            <div className="element-6af0eb9">
                                                <div className="element-populated">

                                                    <h2 className="heading-title">{moment(event.start).format('DD').toString()}</h2>

                                                    <span className="divider-separator"></span>

                                                    <h2 className="heading-title">{moment(event.end).format('DD').toString()}</h2>

                                                    <div className="date-item">
                                                        <span className="event-day"></span>
                                                        <span
                                                            className="event-month">{moment(event.start).format('MM').toString()}</span>
                                                        <span
                                                            className="event-year">-{moment(event.start).format('YYYY').toString()}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className="element-440a43a">
                                <div className="element-populated">
                                    <p className="heading-title">{event.title}</p>
                                    {(event.description ?(
                                        <h1 className="heading-sub-title">
                                            {event.description.slice(0, 350)}...
                                        </h1>
                                    ) : (
                                        <></>
                                    ))}
                                    <div style={{maxHeight: '150px', overflowY: 'auto'}}>
                                        {extractLinks(event.description).map((link, index) => (
                                            <button key={index} onClick={() => window.open(link, '_blank')}>
                                            <span className="button-icon align-icon-left">
                                                <Map/>
                                            </span>
                                                <span className="button-text">view link</span>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <CountdownComponent event={event}></CountdownComponent>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default ListCountDown;