import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18n from 'i18next';
import {
  initReactI18next,
} from 'react-i18next';
import translationEN from './services/locales/en/translation.json'
import translationDE from './services/locales/de/translation.json'
import "antd/dist/antd.less";
import 'bootstrap/dist/css/bootstrap.min.css';

//sentry error tracking
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {EventProvider} from "./routes/core/EventContext";




// Sentry.init({
//    dsn: "https://c68a51ce49184a5488703e6a5a9a4257@o1043206.ingest.sentry.io/6085908",
//    integrations: [new Integrations.BrowserTracing()],
//    environment: 'dev',
//    // Set tracesSampleRate to 1.0 to capture 100%
//    // of transactions for performance monitoring.
//    // We recommend adjusting this value in production
//    tracesSampleRate: 1.0,
// });




i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN
    },
    de: {
      translation: translationDE
    },
  },
  lng: 'en',
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false,
  },
});

ReactDOM.render(
    <Router>
        <EventProvider>
            <App />
        </EventProvider>
    </Router>,
    document.getElementById('root')
);

serviceWorker.unregister();
