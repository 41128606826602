import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ROUTES} from "../../../parameters/routes";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import logo from "../../../assets/logo.svg";
import {Button, FormControl, Grid, IconButton, Input, InputLabel, InputAdornment, Paper} from "@material-ui/core";
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {notification, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import TooltipPassword from "../../core/Widgets/TooltipPassword";

interface RouteParams {
    token: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 60,
            flexDirection: 'column',
            alignItems: 'center',
        },
        headerTitle: {
            textAlign: 'center',
            padding: '2rem 0',
            margin: '0',
            fontSize: '2rem',
        },
        logo: {
            width: 250,
            marginTop: 13,
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
        },
        paper: {
            paddingTop: 16,
            paddingBottom: 16,
            paddingRight: 25,
            paddingLeft: 25,
            marginTop: theme.spacing(3),
            maxWidth: '500px',
            minWidth: '300px',
            maxHeight: '700px',
            width: '80%',
            height: '60%',
            margin: '100px auto',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            boxShadow: '0px 0px 25px -4px rgba(150, 150, 150, 0.24)',
            border: '1px solid rgba(150, 150, 150, 0.18)',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        field: {
            marginTop: theme.spacing(2)
        },
        actions: {
            paddingTop: 16,
            paddingBottom: 16,
            marginTop: theme.spacing(3),
            display: 'flex',
            flexDirection: 'row',
        },
        button: {
            marginRight: theme.spacing(3),
            border: '2px solid #424766',
            height: '35px',
            width: '205px',
            background: "white",
            color: "#424766 !important",
            '&:hover': {
                background: '#424766 !important',
                color: "white",
            }
        },
        buttonCancel: {
            background: '#424766',
            height: '35px',
            width: '200px',
            color: "white",
        },
    }),
);

const ResetPassword = () => {
    const classes = useStyles();

    const history = useHistory();
    const { token } = useParams<RouteParams>();
    const {t} = useTranslation();

    // State variables to manage the reset password form
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loadingIcon = <LoadingOutlined style={{fontSize: 24, color: "white", marginLeft: '5px'}}/>
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [passwordVal, setPasswordValidation] = useState({
        displayVal: "none",
        uppercase: false,
        lowercase: false,
        specialChar: false,
        numeral: false,
        minChar: false,
        maxChar: true,
        valid: false
    });


    // Get the account data by token
    const getAccountInfo = useCallback( async () => {
        try {
            const endpoint = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/getAccountInfo?key=${process.env.REACT_APP_API_KEY}`;

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idToken: token
                })
            });

            if(!response.ok || response.status !== 200) {
                setIsTokenExpired(true);
            }

            else {
                const data = await response.json();
                data.users.map((user: any) => setEmail(user.email));
                setIsTokenExpired(false);
            }
        } catch (error) {
            setIsTokenExpired(true);
        }
    }, [token]);

    const validatePassword = (password: string) => {
        const isValidLength = password.length >= 8 && password.length <= 20;
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);

        return {
            isValidLength,
            hasUppercase,
            hasLowercase,
            hasNumber,
            hasSpecialChar
        };
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        const { isValidLength, hasUppercase, hasLowercase, hasNumber, hasSpecialChar } = validatePassword(password);

        setNewPassword(password);

        if (password.length === 0) {
            setIsValidPassword(true);
        } else {
            setIsValidPassword(isValidLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar);
        }

        setPasswordValidation({
            displayVal: "block",
            uppercase: hasUppercase,
            lowercase: hasLowercase,
            specialChar: hasSpecialChar,
            numeral: hasNumber,
            minChar: isValidLength,
            maxChar: password.length <= 20 && password.length !== 0,
            valid: isValidLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar
        });
    };

    // Update the user password
    const handleUpdatePassword = useCallback(async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/updatePasswordUsersFunction`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    newPassword: newPassword
                })
            });

            const responseData = await response.json();

            if(response.status !== 200) {
                throw new Error(responseData.error);
            } else {
                notification['success']({message: 'Success', description: t('Password updated successfully')});
                setNewPassword('');
                history.push(ROUTES.login);
            }
        } catch (error) {
            notification['error']({message: 'Error', description: t('Error updating password')});
        } finally {
            setIsLoading(false);
        }
    }, [history, t, email, newPassword]);

   useEffect(() => {
        getAccountInfo().then();
    }, [getAccountInfo]);

    return (
        <div className={classes.container}>
            <img src={logo} className={classes.logo} alt="Logo"/>
            <Paper className={classes.paper}>
                <h2 className={classes.headerTitle}>{'Reset Password'}</h2>
                {isTokenExpired ? (
                    <p>Your request to reset your password has expired or the link has already been used.</p>
                ) : (
                    <>
                           <p>Reset password for <b>{email}</b></p>
                           <form onSubmit={handleUpdatePassword} className={"messageContainer"}>
                               <FormControl required={true} fullWidth={true} className={classes.field}>
                                   <InputLabel style={{lineHeight:"0"}} htmlFor="password">New password</InputLabel>
                                   <Input
                                       id="password"
                                       required
                                       type={showPassword ? 'text' : 'password'}
                                       inputProps={{style: {color: "rgb(104, 29, 73 )"}}}
                                       value={newPassword}
                                       onChange={handlePasswordChange}
                                       endAdornment={
                                           <InputAdornment position="end">
                                               <IconButton
                                                   aria-label="toggle password visibility"
                                                   onClick={() => setShowPassword(!showPassword)}
                                                   onMouseDown={(e) => e.preventDefault()}
                                               >
                                                   {showPassword ? <Visibility /> : <VisibilityOff />}
                                               </IconButton>
                                           </InputAdornment>
                                       }
                                       style={{
                                           marginTop: "10px",
                                           borderColor: isValidPassword ? "" : "red",
                                       }}
                                   />
                                   {!isValidPassword && (
                                       <div id="message">
                                           <TooltipPassword displayVal={passwordVal.displayVal} lowercase={passwordVal.lowercase} uppercase={passwordVal.uppercase} maxChar={passwordVal.maxChar} minChar={passwordVal.minChar} numeral={passwordVal.numeral} specialChar={passwordVal.specialChar} />
                                       </div>
                                   )}
                               </FormControl>
                               <div className={classes.actions}>
                                   <Grid container spacing={2}>
                                       <Grid item xs={12} sm={6} md={6}>
                                           <Button
                                               variant="contained"
                                               fullWidth
                                               className="buttonCancel"
                                               onClick={() => history.push(ROUTES.login)}
                                           >
                                               Cancel
                                           </Button>
                                       </Grid>
                                       <Grid item xs={12} sm={6} md={6}>
                                           <Button
                                               color="primary"
                                               type="submit"
                                               variant="contained"
                                               fullWidth
                                               disabled={newPassword === '' || !isValidPassword}
                                               className="buttonUpdate">
                                               Update Password
                                               <Spin spinning={isLoading} indicator={loadingIcon}/>
                                           </Button>
                                       </Grid>
                                   </Grid>
                               </div>
                           </form>
                       </>
                ) }
            </Paper>
        </div>
    );
};

export default ResetPassword;