import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ROUTES} from '../../../parameters/routes';
import {useHistory} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {API} from '../../../api';
import logo from "../../../assets/logo.svg";

import { Button, Input   } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { formData } from '../../../Types/Login/LoginType';
import {  submitWorker } from '../../../services/LoginServices/LoginService';
import firebase from 'firebase/app';

const Login = () => {
    const history = useHistory();
    const [data, setData] = useState<formData>({email: '', password: ''});
    const canSubmit = useMemo(() => !!data.email && !!data.password, [data]);
    const login = API.login;
    const {t} = useTranslation();
    const [loginPending, setLoginPending] = useState(false);
    const [token, setToken] = useState('');

    const handleDataChange = useCallback(
        ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
                setData((d) => ({ ...d, [currentTarget.id]: currentTarget.value }));

        },
        []
    );

    const handleLogin = async (email: string, password: string) => {
        try {
            const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
                const user = userCredential.user;
                console.log("user", user?.uid)
                if (user && user.uid && user.email ) {
                    await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/recordUserLoginFunction`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({uid: user.uid, email: user.email}),
                    });
                }

        } catch (error) {
                console.error("Error during user login:", error);

        }
    };

    const handleSubmit = useCallback(
        async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            if (!canSubmit) return;
                setLoginPending(true);

            await submitWorker({ e, canSubmit, data, login, setData, t, history, setLoginPending });
                await handleLogin(data.email, data.password);
                setLoginPending(false);

        },
        // eslint-disable-next-line
        [canSubmit, data, login, t, history]
    );

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(async function (user) {
                if (user) {
                    const token = await user.getIdToken();
                    setToken(token);
                } else {
                    setToken('');
                }
        });

        return () => {
            unsubscribe();
        };
    }, []);


    return (
        <div className="LoginContainer">
            <form className="LoginLeftSide" autoComplete="off">
                <img src={logo} alt="Logo" className="logo" />
                <div className="LoginHeader">
                    <h2 className="LoginTitle">{"Sign in"}</h2>
                    <span className="LoginParagraph">
                        {"Enter your details to login to your account:"}
                    </span>
                </div>

                <div className="FormContainer">
                    <Input
                        autoComplete="off"
                        value={data.email}
                        onChange={handleDataChange}
                        style={{marginTop : '20px'}}
                        id="email"
                        required
                        size="large"
                        placeholder="Enter your email address"
                        prefix={<MailOutlined style={{color :"#222850"}}/>}
                    />
                    <Input.Password
                        autoComplete="new-password"
                        value={data.password}
                        onChange={handleDataChange}
                        style={{marginTop : '20px'}}
                        id="password"
                        size="large"
                        required
                        placeholder="Enter your password"
                        prefix={<LockOutlined style={{color :"#222850"}} />}
                    />
                    <br /><br />
                    <span
                        className='forgotPassword'
                        onClick={() => history.push(ROUTES.forgotPassword)}
                    >
                        Forgot password?
                    </span>
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={handleSubmit}
                            disabled={!canSubmit}
                            loading={loginPending}
                            style={{marginTop : '20px'}}
                            size={"middle"}
                        >
                            Sign in
                        </Button>
                    </div>
                </div>
            </form>

            <div className="LoginRightSide"></div>
        </div>
    );
};

export default inject('messageStore')(observer(Login));


