import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import {useEventContext} from "../EventContext";
import { Chart } from 'react-google-charts';
import { Tabs, notification, Tag, Table, Tooltip, Button, Select, Pagination, Card, Row, Col, Typography } from "antd";
import { MailgunLog } from "../../../models/MailgunLog";
import { Post } from "../../../models/post";
import firebase from 'firebase/app';
// Import Swiper styles
import SwiperCore, {Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import './Dashboard.scss';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment/moment";
import Search from "antd/es/input/Search";
import { ReloadOutlined } from "@ant-design/icons";
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as RechartsTooltip,
    Legend,
} from 'recharts';

import { Parser } from 'json2csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

SwiperCore.use([Pagination, Autoplay]);

const { Title } = Typography;

const { TabPane } = Tabs;

interface UserDetail {
    country: string;
    firm: string;
    lastSignInTime: string;
    isDeleted: boolean;
}

interface ChartData {
    country: string;
    data: {
        month: string;
        count: number;
    }[];
}

const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const [token] = useState(''); // State hook for Firebase authentication token
    const [sumCountry, setSumCountry] = useState<any[]>([]);
    const [sumFirm, setSumFirm] = useState<any[]>([]);
    const [dataLastConnection, setDataLastConnection] = useState([['Country', 'Connections']]);
    const [dataLastConnectionDeleted, setDataLastConnectionDeleted] = useState([['Country', 'Connections']]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalUsersDeleted, setTotalUsersDeleted] = useState(0);
    const {t} = useTranslation();
    // State variables
    const [posts, setPosts] = useState<Post[]>([]);
    // State hook for Mailgun logs
    const [mailgunLogs, setMailgunLogs] = useState<MailgunLog[]>([]);
    const [todayFiles, setTodayFiles] = useState<any[]>([]);
    // State hook for searching Mailgun logs
    const [searchParam, setSearchParam] = useState('');
    const [loadingSearch, setLoadingSearch] = React.useState(false);
    // State hook for refreshing Mailgun logs
    const [loadingRefresh, setLoadingRefresh] = React.useState(false);
    // const [oldGrade, setOldGrade] = useState('');
    // const [newGrade, setNewGrade] = useState('');

    // Link of WordPress posts API
    const baseUrl = process.env.REACT_APP_WORDPRESS_API;
    const postsEndpoint = '/wp-json/wp/v2/posts?categories=16';
    const endpoint = `${baseUrl}${postsEndpoint}`;

    // Mailgun api data
    const apiKey = process.env.REACT_APP_MAILGUN_API;
    const domain = process.env.REACT_APP_MAILGUN_DOMAIN;
    const apiUrl = process.env.REACT_APP_MAILGUN_API_URL;
    const sender = process.env.REACT_APP_MAILGUN_SENDER_MAIL;
    const [chartData, setChartData] = useState<ChartData[]>([]);
    const {events} = useEventContext();
    const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const [selectedYears, setSelectedYears] = useState<number[]>([]);
    const [years, setYears] = useState<number[]>([]);


    // Fetch all the posts in WordPress by using the API
    const getPostsData = useCallback(async () => {
        try {
            const response = await fetch(endpoint);
            const resJson = await response.json();
            setPosts((prevPosts) => [...prevPosts, ...resJson]);
        } catch (error) {
            notification['error']({
                message: 'Error',
                description: t('There was an error displaying the news, please refresh the page')
            });
        }
    }, [endpoint, t]);

    // Get Mailgun events for today
    const getMailgunLogs = useCallback(async () => {
        // Calculate the start date and time for the current date
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const todayTimestamp = Math.floor(today.getTime() / 1000);

        // Calculate the end date and time for the next day
        const nextDay = new Date(today);
        nextDay.setDate(nextDay.getDate() + 1);
        const nextDayTimestamp = Math.floor(nextDay.getTime() / 1000);

        // Mailgun Events API URL
        const fullPath = `${apiUrl}${domain}/events?from=${sender}&begin=${todayTimestamp}&end=${nextDayTimestamp}&limit=300`;

        try {
            const response = await fetch(fullPath, {
                headers: {
                    Authorization: `Basic ${btoa('api:' + apiKey)}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch Mailgun logs: ${response.statusText}`);
            } else {
                const resJson = await response.json();
                // Filter logs for events of today
                const todayLogs = resJson.items.filter((log: MailgunLog) => log.timestamp >= todayTimestamp && log.timestamp < nextDayTimestamp);
                // Sort logs in descending order
                todayLogs.sort((a: any, b: any) => b.timestamp - a.timestamp);
                setMailgunLogs(todayLogs);
            }
        } catch (error) {
            setMailgunLogs([]);
            throw new Error(`Error fetching Mailgun logs: ${error}`);
        }
    }, [apiKey, apiUrl, domain, sender]);


    // Get Mailgun logs by receiver email
    const getMailgunLogsByReceiver = useCallback(async (email: string) => {
        // Mailgun Events API URL
        const fullPath = `${apiUrl}${domain}/events?to=${email}&limit=300`;

        try {
            const response = await fetch(fullPath, {
                headers: {
                    Authorization: `Basic ${btoa('api:' + apiKey)}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch Mailgun logs: ${response.statusText}`);
            } else {
                const resJson = await response.json();
                setMailgunLogs(resJson.items);
            }
        } catch (error) {
            setMailgunLogs([]);
            throw new Error(`Error fetching Mailgun logs: ${error}`);
        }
    }, [apiKey, apiUrl, domain]);

    // Search Mailgun logs by receiver email
    const onSearch = async () => {
        if (searchParam) {
            setLoadingSearch(true);
            await getMailgunLogsByReceiver(searchParam);
            setLoadingSearch(false);
        } else {
            await getMailgunLogs();
        }
    }

    // Refresh Mailgun logs data
    const onRefresh = async () => {
        setLoadingRefresh(true);
        setSearchParam("");
        await getMailgunLogs();
        setLoadingRefresh(false);
    }

    const handleUsersDetailsWithLastSignInAfter = useCallback(async () => {
        // Get the current date and time
        const currentDate = new Date();
        const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()); // Set hours, minutes, seconds, and milliseconds to 0

        // Send the selected date as the thresholdDate in the request
        const responseGetUsersDetails = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/getUsersDetailsWithLastSignInAfter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({thresholdDate: today}) // selectedDate is the start of the day in UTC
        });

        if (responseGetUsersDetails.ok) {
            const usersDetails: UserDetail[] = await responseGetUsersDetails.json();
            // Map users details to have the correct keys for column headers
            const formattedUsersDetails = usersDetails.map(user => ({
                Country: user.country,
                Firm: user.firm,
                "Last Sign In Time": user.lastSignInTime,
            }));

            // Calculate the number of users per country for the summary
            const usersPerCountry = formattedUsersDetails.reduce((acc: any, user: any) => {
                const country = user.Country || 'Other';
                acc[country] = (acc[country] || 0) + 1;
                return acc;
            }, {});

            const summaryCountry = [
                ["Country", "Count"], // Header row
                ...Object.entries(usersPerCountry).map(([country, count]) => [country, count]),
            ];

            setSumCountry(summaryCountry);


            // Calculate the number of users per country for the firms
            const usersPerFirms = formattedUsersDetails.reduce((acc: any, user: any) => {
                const firm = user.Firm || 'Other';
                acc[firm] = (acc[firm] || 0) + 1;
                return acc;
            }, {});

            const summaryFirm = [
                ["Firm", "Count"], // Header row
                ...Object.entries(usersPerFirms).map(([firm, count]) => [firm, count]),
            ];

            setSumFirm(summaryFirm);
        } else {
            // Handle errors
            const errorResponse = await responseGetUsersDetails.text();
            throw new Error(`Server response error: ${errorResponse}`);
        }
    }, [token]);

    interface Event {
        start: Date;
        end: Date;
        description: String,
        id: String,
        title: String,
    }

    function getEventsForToday(allEvents: Event[]): Event[] {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight for accurate date comparison

        const eventsToday: Event[] = [];

        for (const event of allEvents) {
            const eventStartDate = new Date(event.start);
            const eventEndDate = new Date(event.end);

            if (eventStartDate <= today && today <= eventEndDate) {
                eventsToday.push(event);
            }
        }

        return eventsToday;
    }

    const todayEvents: Event[] = getEventsForToday(events);

    // Render a list of Logs Emails
    const renderLogsEmails = () => {
        // Define the columns for the table
        const columns = [
            {
                title: 'Date',
                dataIndex: 'date',
            },
            {
                title: 'Sender',
                dataIndex: 'from',
            },
            {
                title: 'Recipient',
                dataIndex: 'to',
            },
            {
                title: 'Delivery status',
                dataIndex: 'deliveryStatus',
            },
            {
                title: 'Status',
                dataIndex: 'event',
                render: (text: any) => (
                    <Tag color={text === 'rejected' || text === 'failed' ? 'red' : 'green'}>
                        {text.charAt(0).toUpperCase() + text.slice(1)}
                    </Tag>
                ),
            }
        ];
        const dataSource: any[] | undefined = [];
        mailgunLogs.forEach((log, index) => {
            if (log.message && log.message.headers && log.message.headers.from && log.message.headers.to) {
                const date = moment.unix(log.timestamp).format('YYYY-MM-DD HH:mm:ss');
                const from = log.message.headers.from;
                const to = log.message.headers.to;
                const event = log.event;
                const deliveryStatus = log["delivery-status"]?.message || "--";
                dataSource.push({date, from, to, deliveryStatus, event, key: index.toString()});
            }
        });
        return (
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={{ pageSize: 3, hideOnSinglePage: true }}
            />
        );
    };

    const fetchTodayFiles = async () => {
        // Get the current date and define the start and end times for today
        const today = new Date();
        const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

        // Reference to the Firebase Storage directory
        const storageRef = firebase.storage().ref('/files/');

        // Function to fetch metadata of a file reference
        const getFileMetadata = async (fileRef: firebase.storage.Reference) => {
            try {
                return await fileRef.getMetadata();
            } catch (error) {
                console.error('Error while fetching file metadata', error);
                throw error;
            }
        };

        // Function to get the parent folder name from the full path
        const getParentFolderName = (fullPath: string) => {
            const parts = fullPath.split('/');
            return parts[parts.length - 1];
        };

        try {
            // List all files and folders in the specified directory
            const result = await storageRef.listAll();
            const todayFiles = [];

            // Iterate through each file in the directory
            for (const item of result.items) {
                try {
                    // Fetch metadata for the current file
                    const metadata = await getFileMetadata(item);

                    // Check if the file was created today
                    const itemCreatedTime = new Date(metadata.timeCreated);
                    if (itemCreatedTime >= startOfDay && itemCreatedTime < endOfDay) {
                        // Get the parent folder name and add it to the list
                        const parentFolderName = getParentFolderName(item.fullPath);
                        todayFiles.push(parentFolderName);
                    }
                } catch (error) {
                    console.error('Error while fetching file metadata', error);
                }
            }

            // Iterate through each subfolder (prefix) in the directory
            for (const prefix of result.prefixes) {
                try {
                    // List all items (files) in the subfolder
                    const subFiles = await prefix.list();
                    const fakeFile = subFiles.items.find((subItem) => subItem.name === 'fakeFile');

                    // If a 'fakeFile' is found, fetch its metadata
                    if (fakeFile) {
                        const metadata = await getFileMetadata(fakeFile);

                        // Check if the 'fakeFile' was created today
                        const itemCreatedTime = new Date(metadata.timeCreated);
                        if (itemCreatedTime >= startOfDay && itemCreatedTime < endOfDay) {
                            // Get the parent folder name and add it to the list
                            const parentFolderName = getParentFolderName(prefix.fullPath);
                            todayFiles.push(parentFolderName);
                        }
                    }
                } catch (error) {
                    console.error('Error while fetching file metadata', error);
                }
            }

            // set the list of parent folder names for files created today
            setTodayFiles(todayFiles);

        } catch (error) {
            console.error('Error while listing files', error);
        }
    };

    // const updateGrades = async () => {
    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/updateGrades`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json',
    //                 'Authorization': `Bearer ${token}`, // Ensure token is available in scope
    //             },
    //             body: JSON.stringify({
    //                 oldGrade,
    //                 newGrade
    //             })
    //         });
    //
    //         const data = await response.json();
    //         if (response.ok) {
    //             alert('Grades updated successfully');
    //         } else {
    //             alert(`Error: ${data.message}`);
    //         }
    //     } catch (error) {
    //         console.error('Error updating grades: ', error);
    //         alert('Error updating grades');
    //     }
    // };

    const handleLastSignInAfter = useCallback(async () => {
        const currentYear = new Date().getFullYear();
        const selectedDate = new Date(`${currentYear - 1}-12-01`); // Creating a Date object for December 1st of the previous year
        const responseGetUsersDetails = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/getUsersDetailsWithLastSignInAfter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({thresholdDate: selectedDate.toISOString()}) // Convert Date object to ISO string
        });

        if (responseGetUsersDetails.ok) {
            const usersDetails: UserDetail[] = await responseGetUsersDetails.json();
            console.log(usersDetails);

            // Example transformation to aggregate by date and country
            const aggregatedData:any = {};
            const aggregatedDataDeleted:any = {};
            let totalUsersCount = 0;
            let totalUsersDeletedCount = 0;

            usersDetails.forEach(user => {
                const date = new Date(user.lastSignInTime); // Assuming lastSignInTime is in ISO date format
                if (date >= selectedDate && date <= new Date()) { // Check if date is within the range
                    const country = user.country;

                    // Check if the country is not empty
                    if (country) {
                        // Aggregate data for active users
                        if (aggregatedData[country]) {
                            aggregatedData[country] += 1;
                        } else {
                            aggregatedData[country] = 1;
                        }
                        totalUsersCount += 1;

                        // Aggregate data for deleted users
                        if (user.isDeleted) {
                            if (aggregatedDataDeleted[country]) {
                                aggregatedDataDeleted[country] += 1;
                            } else {
                                aggregatedDataDeleted[country] = 1;
                            }
                            totalUsersDeletedCount += 1;
                        }
                    }
                }
            });

            // Convert aggregatedData to the desired format
            const data = [['Country', 'Connections']];
            Object.keys(aggregatedData).forEach(country => {
                data.push([country, aggregatedData[country]]);
            });
            const dataDeleted = [['Country', 'Connections']];
            Object.keys(aggregatedDataDeleted).forEach(country => {
                dataDeleted.push([country, aggregatedDataDeleted[country]]);
            });

            setDataLastConnection(data);
            setDataLastConnectionDeleted(dataDeleted);

            setTotalUsers(totalUsersCount);
            setTotalUsersDeleted(totalUsersDeletedCount);
            // console.log("dataLastConnection:", dataLastConnection);
            // console.log("data:", data);
        }
    }, [token]);

    const fetchLoginsData = useCallback(async () => {
        try {
            const userLoginsSnapshot = await firebase.database().ref('/user_logins').once('value');
            const userLogins = userLoginsSnapshot.val();

            const usersSnapshot = await firebase.database().ref('/users').once('value');
            const users = usersSnapshot.val();

            const loginsPerCountryPerMonth: { [country: string]: { [month: string]: number } } = {};

            for (let key in userLogins) {
                const login = userLogins[key];
                const uid = login.uid;
                const userCountry = users[uid]?.country;

                // Skip processing if userCountry is undefined or null
                if (!userCountry) continue;

                const loginTime = new Date(login.loginTime);
                const year = loginTime.getFullYear();
                const month = `${year}-${String(loginTime.getMonth() + 1).padStart(2, '0')}`;

                if (selectedYears.length === 0 || selectedYears.includes(year)) {
                    if (!loginsPerCountryPerMonth[userCountry]) {
                        loginsPerCountryPerMonth[userCountry] = {};
                    }

                    if (loginsPerCountryPerMonth[userCountry][month]) {
                        loginsPerCountryPerMonth[userCountry][month]++;
                    } else {
                        loginsPerCountryPerMonth[userCountry][month] = 1;
                    }
                }
            }

            const chartData: ChartData[] = Object.keys(loginsPerCountryPerMonth).map(country => ({
                country,
                data: Object.keys(loginsPerCountryPerMonth[country]).map(month => ({
                    month,
                    count: loginsPerCountryPerMonth[country][month],
                })),
            }));

            setChartData(chartData);
        } catch (error) {
            console.error("Error fetching login data: ", error);
        }
    }, [selectedYears]);

    const filteredData = chartData.reduce((acc: any[], { country, data }) => {
        if (selectedCountries.length === 0 || selectedCountries.includes(country)) {
            data.forEach(({ month, count }) => {
                let monthData = acc.find(entry => entry.month === month);
                if (!monthData) {
                    monthData = { month };
                    acc.push(monthData);
                }
                monthData[country] = count;
            });
        }
        return acc;
    }, []);

    const filteredTotal = filteredData.length;

    const colorMap: { [key: string]: string } = {};

    const renderBarChart = () => {
        const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

        return (
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={paginatedData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <RechartsTooltip />
                    <Legend />
                    {chartData.map(({ country }) => (
                        selectedCountries.length === 0 || selectedCountries.includes(country) ? (
                            <Bar
                                key={country}
                                dataKey={country}
                                stackId="a"
                                fill={getColorForCountry(country, colorMap)}
                            />
                        ) : null
                    ))}
                </BarChart>
            </ResponsiveContainer>
        );
    };

    let colorIndex = 0;

    const generateSoftPastelColor = (index: number): string => {
        // Base hue range for pastel colors (0-360 degrees)
        const hue = (index * 30) % 360; // Spread hues around the color wheel

        // Generate pastel colors by setting lightness high and saturation moderate
        const saturation = 30 + (index % 5) * 10; // Adjust saturation for variety
        const lightness = 85 - (index % 5) * 5;  // Adjust lightness for a pastel look

        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    const getColorForCountry = (country: string, colorMap: { [key: string]: string }) => {
        if (!colorMap[country]) {
            const newColor = generateSoftPastelColor(colorIndex);
            colorMap[country] = newColor;
            colorIndex++;
        }
        return colorMap[country];
    };

    // Handle country selection change
    const handleCountryChange = (value: string[]) => {
        setSelectedCountries(value);
    };

    // Handle pagination change
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const exportChartData = () => {
        const doc = new jsPDF();
        const filteredData = chartData
            .filter(({ country }) => selectedCountries.length === 0 || selectedCountries.includes(country))
            .map(({ country, data }) => {
                return data.map(({ month, count }) => ({
                    country,
                    month,
                    count,
                }));
            })
            .flat();

        // Pour PDF
        doc.text('Login Stats Per Country and Month', 20, 10);
        // @ts-ignore
        doc.autoTable({
            head: [['Country', 'Month', 'Count']],
            body: filteredData.map(row => [row.country, row.month, row.count]),
        });

        doc.save('chart-data.pdf');

        // Pour CSV
        const json2csvParser = new Parser();
        const csv = json2csvParser.parse(filteredData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "chart-data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        setLoading(true);
        fetchLoginsData().then();
        getPostsData().then();
        setLoading(false);
    }, [fetchLoginsData, getPostsData]);

    useEffect(() => {
        // Simulate an asynchronous operation
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 3000);

        fetchTodayFiles().then();
        getPostsData().then();
        getMailgunLogs().then();
        handleUsersDetailsWithLastSignInAfter().then();
        handleLastSignInAfter().then();


        // Clean up the timeout
        return () => clearTimeout(timeout);
    }, [getPostsData, handleUsersDetailsWithLastSignInAfter,token,getMailgunLogs,handleLastSignInAfter]);

    // Fetch login data and extract years
    useEffect(() => {
        const fetchLoginData = async () => {
            try {
                const userLoginsSnapshot = await firebase.database().ref('/user_logins').once('value');
                const userLogins = userLoginsSnapshot.val();

                const extractedYears: Set<number> = new Set();

                Object.keys(userLogins).forEach((key) => {
                    const loginTime = new Date(userLogins[key].loginTime);
                    const year = loginTime.getFullYear();
                    extractedYears.add(year);
                });

                setYears(Array.from(extractedYears).sort()); // Sort the years in ascending order
            } catch (error) {
                console.error("Error fetching login data: ", error);
            }
        };

        fetchLoginData().then();
    }, []);

    return (
        <div>
            {loading ? (
                <section className="dots-container">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </section>
            ) : (
                <div id="wrapper" className="overflow-hidden">
                    <div className="d-flex flex-column" id="content-wrapper">
                        <div id="content">
                            <div className="container-fluid">
                                <Row justify="space-between" align="middle" className="mb-4">
                                    <Typography.Title level={3} className="text-dark text-uppercase mb-0 fw-normal">
                                        the 10 latest news
                                    </Typography.Title>
                                </Row>
                                <div className="row mb-4">
                                    <div className="col-lg-12 col-xl-12">
                                        <Card>
                                            <Row gutter={[16, 16]}>
                                                <Typography.Title level={4}>Recent News</Typography.Title>
                                            </Row>
                                            <div className="card-body w-100 pb-0">
                                                <Swiper
                                                    spaceBetween={10}
                                                    slidesPerView={1}
                                                    autoplay={true}
                                                    pagination={{clickable: true}}
                                                    breakpoints={{
                                                        640: {
                                                            slidesPerView: 2,
                                                            spaceBetween: 10,
                                                        },
                                                        768: {
                                                            slidesPerView: 3,
                                                            spaceBetween: 10,
                                                        },
                                                        1024: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 20,
                                                        },
                                                    }}
                                                >
                                                    {posts.map((post, index) => (
                                                        <SwiperSlide key={index}>
                                                            <article className={"mb-4"}>
                                                                <div className="article-wrapper">
                                                                    <figure>
                                                                        <img alt={post.acf.image.title}
                                                                             src={post.acf.image.url + '.webp'}/>
                                                                    </figure>
                                                                    <div className="article-body">
                                                                        <h2 dangerouslySetInnerHTML={{__html: post.title.rendered}}></h2>
                                                                        <time>
                                                                            <CalendarTodayIcon
                                                                                className="calendar-icon"/>
                                                                            {moment(post.date).format('LL')}
                                                                        </time>
                                                                        {/*<p>{post.yoast_head_json.description?.slice(0,60)}...</p>*/}

                                                                        <a className="read-more" href={post.link}
                                                                           target="_blank" rel="noopener noreferrer">
                                                                            Read more <span className="sr-only">about this is some title</span>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 className="icon"
                                                                                 viewBox="0 0 20 20"
                                                                                 fill="currentColor">
                                                                                <path fillRule="evenodd"
                                                                                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                                                                      clipRule={"evenodd"}/>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                            </div>
                                        </Card>
                                    </div>
                                </div>

                                <Row justify="space-between" align="middle" className="mt-5 mb-4">
                                    <Typography.Title level={3 } className="text-dark text-uppercase mb-0 fw-normal">
                                        Number of Daily Logins Since December 1st
                                    </Typography.Title>
                                </Row>

                                <div className="row mb-4">
                                    <div className="col-lg-12 col-xl-12">
                                        <Card>
                                            <Tabs defaultActiveKey="1">
                                                <TabPane tab="All Users" key="1">
                                                    <div className="chart-area">
                                                        {loading ? (
                                                            <section className="dots-container">
                                                                <div className="dot"></div>
                                                                <div className="dot"></div>
                                                                <div className="dot"></div>
                                                                <div className="dot"></div>
                                                                <div className="dot"></div>
                                                            </section>
                                                        ) : (
                                                            <>
                                                                {dataLastConnection && dataLastConnection.length > 1 ? (
                                                                    <Chart
                                                                        width={"100%"}
                                                                        height={"100%"}
                                                                        chartType="BarChart"
                                                                        loader={<div>Loading Chart</div>}
                                                                        data={dataLastConnection}
                                                                        rootProps={{ "data-testid": "5" }}
                                                                        chartPackages={["corechart", "controls"]}
                                                                        render={({ renderControl, renderChart }) => {
                                                                            return (
                                                                                <>
                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        marginTop: '20px',
                                                                                        justifyContent: 'space-between'
                                                                                    }}>
                                                                                        {renderControl(() => true)}
                                                                                        <div style={{display: 'flex'}}>
                                                                                            <h3 style={{
                                                                                                fontSize: '18px',
                                                                                                marginRight: '10px'
                                                                                            }}>Active Users: </h3>
                                                                                            <div style={{
                                                                                                fontSize: '32px',
                                                                                                fontWeight: 'bold',
                                                                                                lineHeight: '22px'
                                                                                            }}>{totalUsers.toLocaleString()}</div>
                                                                                            <div style={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                justifyContent: 'center',
                                                                                                color: 'green',
                                                                                                fontSize: '24px'
                                                                                            }}>
                                                                                                <svg height="20" width="20" viewBox="0 0 24 24" fill="currentColor">
                                                                                                    <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.59 5.59L20 12l-8-8-8 8z"/>
                                                                                                </svg>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{width: '100%'}}>
                                                                                        {renderChart()}
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        }}
                                                                        controls={[
                                                                            {
                                                                                controlType: "StringFilter",
                                                                                controlID: "search-country",
                                                                                options: {
                                                                                    filterColumnIndex: 0,
                                                                                    matchType: "any",
                                                                                    ui: {label: "Search by Country"}
                                                                                }
                                                                            }
                                                                        ]}
                                                                        options={{
                                                                            colors: [`#222850`],
                                                                            chartArea: {width: '70%'},
                                                                            hAxis: {
                                                                                title: 'Value',
                                                                                minValue: 0
                                                                            },
                                                                            vAxis: {
                                                                                title: 'Country'
                                                                            }
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div>Loading Data...</div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Users Deleted" key="2">
                                                    <div className="chart-area">
                                                        {loading ? (
                                                            <section className="dots-container">
                                                                <div className="dot"></div>
                                                                <div className="dot"></div>
                                                                <div className="dot"></div>
                                                                <div className="dot"></div>
                                                                <div className="dot"></div>
                                                            </section>
                                                        ) : (
                                                            <>
                                                                {dataLastConnectionDeleted && dataLastConnectionDeleted.length > 1 ? (
                                                                    <Chart
                                                                        width={"100%"}
                                                                        height={"100%"}
                                                                        chartType="BarChart"
                                                                        loader={<div>Loading Chart</div>}
                                                                        data={dataLastConnectionDeleted}
                                                                        rootProps={{ "data-testid": "5" }}
                                                                        chartPackages={["corechart", "controls"]}
                                                                        render={({ renderControl, renderChart }) => {
                                                                            return (
                                                                                <>
                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        marginTop: '20px',
                                                                                        justifyContent: 'space-between'
                                                                                    }}>
                                                                                        {renderControl(() => true)}
                                                                                        <div style={{display: 'flex'}}>
                                                                                            <h3 style={{
                                                                                                fontSize: '18px',
                                                                                                marginRight: '10px'
                                                                                            }}>Active Users: </h3>
                                                                                            <div style={{
                                                                                                fontSize: '32px',
                                                                                                fontWeight: 'bold',
                                                                                                lineHeight: '22px'
                                                                                            }}>{totalUsersDeleted.toLocaleString()}</div>
                                                                                            <div style={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                justifyContent: 'center',
                                                                                                color: 'green',
                                                                                                fontSize: '24px'
                                                                                            }}>
                                                                                                <svg height="20" width="20" viewBox="0 0 24 24" fill="currentColor">
                                                                                                    <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.59 5.59L20 12l-8-8-8 8z"/>
                                                                                                </svg>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{width: '100%'}}>
                                                                                        {renderChart()}
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        }}
                                                                        controls={[
                                                                            {
                                                                                controlType: "StringFilter",
                                                                                controlID: "search-country",
                                                                                options: {
                                                                                    filterColumnIndex: 0,
                                                                                    matchType: "any",
                                                                                    ui: {label: "Search by Country"}
                                                                                }
                                                                            }
                                                                        ]}
                                                                        options={{
                                                                            colors: [`#222850`],
                                                                            chartArea: {width: '70%'},
                                                                            hAxis: {
                                                                                title: 'Value',
                                                                                minValue: 0
                                                                            },
                                                                            vAxis: {
                                                                                title: 'Country'
                                                                            }
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div>Loading Data...</div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </Card>
                                    </div>
                                </div>

                                <Row justify="space-between" align="middle" className="mt-5 mb-4">
                                    <Col span={18}>
                                        <Typography.Title level={3 } className="text-dark text-uppercase mb-0 fw-normal">
                                            Login Stats Per Country and Month
                                        </Typography.Title>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={16} align="middle" justify="end">
                                            <Button onClick={exportChartData} type="primary">
                                                Export Report
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>

                                <div className="row mb-4">
                                    <div className="col-lg-12 col-xl-12">
                                        <Card>
                                            <div className="row">
                                                <div className="col-6">
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        style={{width: '100%', marginBottom: '1rem'}}
                                                        placeholder="Filter by Country"
                                                        onChange={handleCountryChange}
                                                    >
                                                        {chartData.map(({country}) => (
                                                            <Select.Option key={country} value={country}>
                                                                {country}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="col-6">
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        style={{width: '100%', marginBottom: '1rem'}}
                                                        placeholder="Filter by Year"
                                                        onChange={(years) => setSelectedYears(years as number[])}
                                                    >
                                                        {years.map((year) => (
                                                            <Select.Option key={year} value={year}>
                                                                {year}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </div>
                                            {renderBarChart()}
                                            <Pagination
                                                current={currentPage}
                                                pageSize={itemsPerPage}
                                                onChange={handlePageChange}
                                                total={filteredTotal}
                                                style={{ marginTop: '1rem' }}
                                            />
                                        </Card>
                                    </div>
                                </div>

                                <Row justify="space-between" align="middle" className="mt-5 mb-3">
                                    <Typography.Title level={3} className="text-dark text-uppercase mb-0 fw-normal">
                                        Today's Insights
                                    </Typography.Title>
                                </Row>
                                <Row gutter={[16, 16]} style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                    {/* Left Column - Events Card */}
                                    <Col span={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'stretch' }} >
                                        <Card bodyStyle={{ padding: "0px", display: 'flex', flexDirection: 'column', height: '100%' }}>
                                            <Row style={{ margin: "5px 10px 0px" }} gutter={[16, 16]}>
                                                <Title level={4}>Events</Title>
                                            </Row>
                                            <section className="text-white py-0 py-xl-0 h-100" style={{ padding: "0px", minHeight: '369px' }}>
                                                <div className="w-100 container-fluid h-100" style={{ padding: "0px", height: '100%' }}>
                                                    <div className="border border-0 d-flex flex-column bg-event justify-content-center align-items-center p-0 py-3 w-100 h-100">
                                                        <div className="w-100 h-100 row">
                                                            {todayEvents.length === 0 ? (
                                                                <h2 className="m-auto text-center text-white">No Event Today</h2>
                                                            ) : (
                                                                <Swiper spaceBetween={20} pagination={{ clickable: true }} autoplay={true}>
                                                                    {todayEvents.map((event, index) => (
                                                                        <SwiperSlide key={index}>
                                                                            <div className="col-md-10 col-xl-8 text-center d-flex justify-content-center align-items-center mx-auto">
                                                                                <div>
                                                                                    <h4 className="text-uppercase fw-bold mb-1 text-white" style={{ fontSize: "15px" }}>
                                                                                        {event.title}
                                                                                    </h4>
                                                                                    {event.description && (
                                                                                        <p className="mb-0" style={{ fontSize: "13px" }}>
                                                                                            {event.description.slice(0, 250)}
                                                                                            <Tooltip title={event.description}>...</Tooltip>
                                                                                        </p>
                                                                                    )}
                                                                                    <button className="d-none btn btn-primary fs-5 me-2 py-2 px-4" type="button">
                                                                                        Button
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </SwiperSlide>
                                                                    ))}
                                                                </Swiper>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </Card>
                                    </Col>

                                    {/* Right Column - Logs Emails Card */}
                                    <Col span={16} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'stretch' }}>
                                        <Card style={{ flex: 1 }}>
                                            <Row gutter={[16, 16]}>
                                                <Title level={4}>Logs Emails</Title>
                                            </Row>
                                            <div className="d-flex mb-2">
                                                <Search
                                                    placeholder="Search..."
                                                    onSearch={() => onSearch()}
                                                    onChange={(e) => setSearchParam(e.target.value)}
                                                    enterButton
                                                    loading={loadingSearch}
                                                    value={searchParam}
                                                />
                                                <Tooltip title="Refresh">
                                                    <Button
                                                        shape="circle"
                                                        icon={<ReloadOutlined />}
                                                        loading={loadingRefresh}
                                                        onClick={() => onRefresh()}
                                                    />
                                                </Tooltip>
                                            </div>
                                            {renderLogsEmails()}
                                        </Card>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    {/* Country Connections Card */}
                                    <Col lg={8} xl={8}>
                                        <Card>
                                            <Row gutter={[16, 16]}>
                                                <Typography.Title level={4}>Country Connections</Typography.Title>
                                            </Row>
                                            <div className="chart-area">
                                                <Chart
                                                    chartType="PieChart"
                                                    data={sumCountry}
                                                    width={"100%"}
                                                    height={"300px"}
                                                />
                                            </div>
                                        </Card>
                                    </Col>

                                    {/* Documents Created Card */}
                                    <Col lg={8} xl={8}>
                                        <Card>
                                            <Row gutter={[16, 16]}>
                                                <Typography.Title level={4}>Documents Created</Typography.Title>
                                            </Row>
                                            {todayFiles.length === 0 ? (
                                                <div style={{ display: 'grid', height: '100%', minHeight: '300px' }}>
                                                    <p style={{ margin: 'auto', textAlign: 'center', fontSize: '11px' }}>No data</p>
                                                </div>
                                            ) : (
                                                <>
                                                    {todayFiles.map((file, index) => (
                                                        <Title level={4} key={index} style={{ marginBottom: 0 }}>{file}</Title>
                                                    ))}
                                                </>
                                            )}
                                        </Card>
                                    </Col>

                                    {/* Firms Connections Card */}
                                    <Col lg={8} xl={8}>
                                        <Card>
                                            <Row gutter={[16, 16]}>
                                                <Typography.Title level={4}>Firms Connections</Typography.Title>
                                            </Row>
                                            <div className="chart-area">
                                                <Chart
                                                    chartType="PieChart"
                                                    data={sumFirm}
                                                    width={"100%"}
                                                    height={"300px"}
                                                />
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
