import { ReactComponent as Document } from 'assets/documents/svg/document.svg';
import { ReactComponent as Pdf } from 'assets/documents/svg/pdf.svg';
import { ReactComponent as Excel } from 'assets/documents/svg/excel.svg';
import { ReactComponent as Word } from 'assets/documents/svg/word.svg';
import { ReactComponent as PowerPoint } from 'assets/documents/svg/powerpoint.svg';
import { ReactComponent as Png } from 'assets/documents/svg/png.svg';
import { ReactComponent as Svg } from 'assets/documents/svg/svg.svg';
import { ReactComponent as Jpg } from 'assets/documents/svg/jpg.svg';
import { ReactComponent as Json } from 'assets/documents/svg/json.svg';
import { ReactComponent as FolderSvg } from 'assets/documents/svg/folder.svg';
import { ReactComponent as Mp4 } from 'assets/documents/svg/mp4.svg';
import { ReactComponent as Csv } from 'assets/documents/svg/csv.svg';
import React from 'react';

export const extensionsIcons = {
    'folder': <FolderSvg/>,
    'pdf': <Pdf/>,
    'docx': <Word/>,
    'doc': <Word/>,
    'xlsx': <Excel/>,
    'xls': <Excel/>,
    'pptx': <PowerPoint/>,
    'ppt': <PowerPoint/>,
    'png': <Png/>,
    'svg': <Svg/>,
    'jpg': <Jpg/>,
    'json': <Json/>,
    'csv': <Csv/>,
    'mp4': <Mp4/>,
    'default': <Document/>

}