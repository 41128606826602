import {ApprovalAction, ApprovalState} from "../../Types/Approval/ApprovalTypes";

export const approvalReducer = (state: ApprovalState, action: ApprovalAction): ApprovalState => {
    switch (action.type) {
        case 'SET_APPROVALS':
            return action.payload;
        case 'ADD_APPROVAL':
            return [...state, action.payload];
        default:
            return state;
    }
};

