import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import SideBar from "./SideBar";
import {Button,Drawer,Modal,Input} from "antd";
import Header from "./Header";
import dataStore from "../../stores/dataStore";
import {Route, useHistory, useLocation} from 'react-router-dom';
import LoadingPage from "./LoadingPage";
// Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import {API} from "../../api";
import {AppRoutes} from "../../Utils/Constants/RoutesConstants";
import authStore from "../../stores/authStore";
import messageStore from "../../stores/messageStore";
import {ROUTES} from "../../parameters/routes";
import DocumentDetails from "../../routes/core/documents/DocumentDetails/DocumentDetails";
import NotFound from "../../routes/core/NotFound/NotFound";
import {approvalRequestRef} from "../../services/firebase/firebase";
import {RoutesTypes} from "../../Types/Sidebar/routesType";
import {useTranslation} from "react-i18next";
import {useApprovals} from "../../routes/core/ApprovalContext";


interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = () => {
    const history = useHistory();
    const [userRight, setUserRight] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [token, setToken] = useState('');
    const [isReauthenticateDialogShown, setIsReauthenticateDialogShown] = useState(false);
    const [password, setPassword] = useState('');
    const [isLoadingDialog, setIsLoadingDialog] = useState(false);
    const [isPasswordEmpty, setIsPasswordEmpty] = useState(true);
    // eslint-disable-next-line
    const [dataLoading , setDataLoading] = useState(true);
    const { t } = useTranslation();
    const { dispatch } = useApprovals();
    const setPage = (route: string) => {
        history.push(route);
    };
    // make default call to prepare functions before can be used
    const handleSubmitColdStart = useCallback(async () => {
        const callFirebaseFunction = async (endpoint: string, method: string, data: Record<string, any>) => {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/${endpoint}`, {
                method: method,
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },

            });
            if (!response.ok) {
                //throw new Error('Error calling Firebase function: ' + endpoint);
            }
            return await response.json();
        }
        const dataObjects = [
            {
                endpoint: 'updateUserWordpressAcf', method: 'PUT', data: {
                    userIdWordpress: "",
                    token: "",
                    image: "",
                    telephone: "",
                    mobile: "",
                    biography: "",
                    connect_url: "",
                    assistants_name: "",
                    assistants_email: "",
                    position: "",
                    user_location: "",
                    show: "",
                    area_of_expertise: "",
                    company_logo: "",
                    callType: "coldStart"
                }
            },
            {
                endpoint: 'updateUserWordpressNative', method: 'PUT', data: {
                    userIdWordpress: "",
                    token: "",
                    first_name: "",
                    last_name: "",
                    name: "",
                    url: "",
                    callType: "coldStart"
                }
            },
            /*{
                endpoint: 'createUserWordPressNativeFunction', method: 'POST', data: {
                    token: "",
                    name: "",
                    username: "",
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "",
                    url: "",
                    description: "",
                    callType: "coldStart"
                }
            },*/
            {endpoint: 'deleteUser', method: 'DELETE', data: {uid: "", callType: "coldStart"}},
            {
                endpoint: 'deleteUserWordpress',
                method: 'DELETE',
                data: {userIdWordpress: "", token: "", callType: "coldStart"}
            },
            {
                endpoint: 'createUser', method: 'POST', data: {
                    email: "",
                    password: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    mobile: "",
                    linkedInProfileLink: "",
                    twitterProfileLink: "",
                    assistantId: "",
                    assistantName: "",
                    assistantEmail: "",
                    assistantTelephone: "",
                    assistantMobile: "",
                    firm: "",
                    grade: "",
                    country: "",
                    areaOfIndustryExpertise: "",
                    taxSpecialisme: "",
                    personnalBiography: "",
                    profesionnalBiography: "",
                    right: "",
                    isVisible: "",
                    deleted: "",
                    wordpress_id: "",
                    showLandingModal:"",
                    approvalStatus: "",
                    callType: "coldStart"
                }
            },
            {
                endpoint: 'createRequestProfileUpdate', method: 'POST', data: {
                    requestId: "",
                    userId: "",
                    email: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    mobile: "",
                    right: "",
                    isVisible: "",
                    firm: "",
                    deleted: "",
                    type: "",
                    requestedBy: "",
                    subject: "",
                    profesionnalBiography: "",
                    personnalBiography: "",
                    linkedInProfileLink: "",
                    twitterProfileLink: "",
                    assistantId: "",
                    assistantName: "",
                    assistantEmail: "",
                    assistantTelephone: "",
                    assistantMobile: "",
                    taxSpecialisme: "",
                    areaOfIndustryExpertise: "",
                    grade: "",
                    country: "",
                    wordpress_id: "",
                    callType: "coldStart"
                }
            },
            {
                endpoint: 'createRequestFirmsAdd', method: 'POST', data: {
                    requestId: "",
                    email: "",
                    password: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    mobile: "",
                    right: "",
                    isVisible: "",
                    firm: "",
                    deleted: "",
                    type: "",
                    requestedBy: "",
                    subject: "",
                    profesionnalBiography: "",
                    personnalBiography: "",
                    linkedInProfileLink: "",
                    twitterProfileLink: "",
                    assistantName: "",
                    assistantEmail: "",
                    assistantTelephone: "",
                    assistantMobile: "",
                    taxSpecialisme: "",
                    areaOfIndustryExpertise: "",
                    grade: "",
                    country: "",
                    assistantId: "",
                    callType: "coldStart"
                }
            },
            /*{
                endpoint: 'createFirmFunction', method: 'POST', data: {
                    id: "",
                    firm: "",
                    webSite: "",
                    country: "",
                    logo_id: "",
                    deleted: "",
                    callType: "coldStart"
                }
            },*/
        ];

        try {
            await Promise.all(dataObjects.map(obj =>
                callFirebaseFunction(obj.endpoint, obj.method, obj.data)
            ));
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }, [token]);

    const allowedPaths: string[] = AppRoutes.map((route) => route.Path);

    const location = useLocation();
    // Access the pathname from the location object
    const currentPath = location.pathname;

    // Check if the user is authorized to access the route
    const canAccessRoute = (userRight: string, requiredRoles: string[]) => {
        return requiredRoles.includes("*") || requiredRoles.includes(userRight);
    };

    const handleReauthenticate = async () => {
        setIsLoadingDialog(true);
        const user = firebase.auth().currentUser;

        if (!user || !user.email) {
            console.error('User is not authenticated or email is missing');
            setIsLoadingDialog(false);
            return;
        }

        const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);

        try {
            await user.reauthenticateWithCredential(credential);
            setIsReauthenticateDialogShown(false);
            // Update passwordUpdated to false
            await firebase.database().ref('users/' + user.uid).update({ passwordUpdated: false });
        } catch (error) {
            console.error('Re-authentication failed:', error);
        } finally {
            setIsLoadingDialog(false);
        }
    };

    const loadData = useCallback(async () => {
        try {
            setIsLoading(true);
            approvalRequestRef.on("value", (snapshot: any) => {
                let requests = snapshot.val();
                let newRequestUpdate: any[] = [];
                for (let key in requests) {
                    newRequestUpdate.push({ id: key, ...requests[key] });
                }

                // Dispatch action to set approvals in context
                dispatch({ type: 'SET_APPROVALS', payload: newRequestUpdate });

                // Update the count dynamically
                const approvalRoute = AppRoutes.find((route: RoutesTypes) => route.Title === 'Approvals');
                if (approvalRoute) {
                    approvalRoute.count = newRequestUpdate.length;
                }
                setIsLoading(false);
            });
        } catch (error) {
            messageStore.snackbar({
                message: t("Failed fetching approval Requests"),
                type: "error",
            });
        }
        // eslint-disable-next-line
    }, [t]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        const checkPasswordUpdate = async () => {
            const user = firebase.auth().currentUser;
            if (user) {
                const userRef = firebase.database().ref('users/' + user.uid);
                userRef.on('value', (snapshot) => {
                    if (snapshot.val().passwordUpdated) {
                        setIsReauthenticateDialogShown(true);
                    }
                });
            }
        };
        firebase.auth().onAuthStateChanged(async function (user) {
                if (user) {
                    checkPasswordUpdate();
                    const userinfo = await API.getUserInfo(user.uid);
                    dataStore.updateUserInfo(userinfo);
                    const token = await user.getIdToken();
                    setToken(token);
                    if(userinfo){
                        setUserRight(userinfo.right as string);
                    }
                    setIsLoading(false);
                    if (history.location.pathname === "/") {
                        history.push(ROUTES.news);
                    }
                } else {
                    setIsLoading(false);
                    setToken('');
                }
            }
        );
    }, [history]);
    useEffect(() => {
        setIsPasswordEmpty(password.length === 0);
    }, [password]);
    useEffect(() => {
            if (token)
                handleSubmitColdStart().then();
        }, [handleSubmitColdStart, token])
    useEffect(() => {
        dataStore.updateCurrentRoute(history.location.pathname === "/" ? AppRoutes[3].Title : AppRoutes.find(el => el.Path === history.location.pathname)?.Title);
        let wordpressTimestamp: any = localStorage.getItem("wordpress/expirationToken")
        var dateTimeStamp = new Date(wordpressTimestamp * 1000);
        const timeExpiration = (dateTimeStamp.getTime() - Date.now()) / 1000 / 60 / 60;
        let wordpressTokenStorage = localStorage.getItem("wordpressToken");
        if (wordpressTokenStorage && timeExpiration < 24) {
            authStore.logout()
            messageStore.snackbar({
                message: `Session expired`,
                type: "error",
            });
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    // @ts-ignore
    return (
        <>
            {isLoading ? (
                <LoadingPage/>
            ) : (
                <>
                    <Modal
                        title="Re-authenticate"
                        visible={isReauthenticateDialogShown}
                        onCancel={() => {}}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                loading={isLoadingDialog}
                                onClick={handleReauthenticate}
                                disabled={isPasswordEmpty}
                            >
                                {isLoadingDialog ? 'Loading...' : 'Re-authenticate'}
                            </Button>
                        ]}
                        maskClosable={false}
                    >
                        <p>Your password has been changed. Please re-authenticate.</p>
                        <Input.Password
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Modal>

                    <div className="MainAppContainer">

                        <div className='WebSidebar'>
                            <SideBar
                                setIsDrawerVisible={setIsDrawerVisible}
                                userRight={userRight}
                                history={history}
                                setPage={setPage}
                            />
                        </div>
                        <div className='MobileSidebar'>
                            <Drawer
                                placement="left"
                                width={300}
                                onClose={() => setIsDrawerVisible(false)}
                                visible={isDrawerVisible}
                                drawerStyle={{backgroundColor: "#222850"}}
                            >
                                <SideBar
                                    setIsDrawerVisible={setIsDrawerVisible}
                                    userRight={userRight}
                                    history={history}
                                    setPage={setPage}
                                />
                            </Drawer>
                        </div>

                        <main className="ContentContainer">
                            <Header
                                currentRoute={dataStore.currentRoute}
                                setIsDrawerVisible={setIsDrawerVisible}
                                history={history}
                                userinfo={dataStore.userInfo}
                            />

                            {/* <Toolbar/> */}

                                {(allowedPaths.includes(currentPath) === false) ? (
                                    (currentPath.includes("/document/")) ? (
                                        <Route path={ROUTES.document} exact={true} children={<DocumentDetails/>}/>
                                    ) : (
                                        <Route path={'*'} exact={true} children={<NotFound/>}/>
                                    )
                                ) : (
                                    <div className={"ContainerRout"}>
                                        <Route path={ROUTES.document} exact={true} children={<DocumentDetails/>}/>
                                        {AppRoutes.map((route, index) => (
                                            <Route
                                                key={index}
                                                path={route.Path}
                                                exact={route.Path === ROUTES.users || route.Path === ROUTES.document ? true : false}
                                                render={() =>  canAccessRoute(userRight, route.userRight)
                                                    ? <route.Component />
                                                    : <NotFound/>
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                        </main>
                    </div>
                </>
            )}
        </>
    );
};

export default Layout;
