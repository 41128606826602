import React, {useState} from "react";
import ListCountDown from "./list_count_down/ListCountDown";
import ListNotification from "./list_notification/ListNotification";
import {ReactComponent as NotificationIcon} from 'assets/event-calender/notification/svg/event-schedule.svg';
import {ReactComponent as ArrowIcon} from "../../../assets/event-calender/notification/svg/arrow.svg";
import {ReactComponent as CalenderIcon} from "../../../assets/event-calender/notification/svg/calender.svg";
import {ReactComponent as Delete} from "../../../assets/event-calender/notification/svg/delete.svg";
import {useEventContext} from "../EventContext";
import {CalenderEvent} from "../../../models/calenderEvent";
import moment from "moment/moment";


function Notification (){
    const { events } = useEventContext();
    // Define a state variable to toggle between displaying notifications and events
    const [displayEvents, setDisplayEvents] = useState(false);
    const [isRbtActive, setIsRbtActive] = useState(false);
    // Function to toggle the visibility of the notification panel

    const handleToggleRbtActive = () => {
        setIsRbtActive(!isRbtActive);
    };

    // Function to toggle between displaying events and notifications
    const handleToggleDisplay = () => {
        setDisplayEvents(!displayEvents);
        
    };

    const isEventCreatedToday = (event: CalenderEvent) => {
        const today = moment().startOf('day');
        return moment(event.createdAt).isSameOrAfter(today);
    };
    // Check if an event is for today or future dates
    const isEventUpcomingOrToday = (event: CalenderEvent) => {
        const today = moment().startOf('day');
        return moment(event.start).isSameOrAfter(today);
    };

    // Sort events based on modified date
    const sortEvents = (events: CalenderEvent[]) => {
        return [...events].sort((eventA, eventB) => {
            const modifiedAtA = eventA.modifiedAt ? new Date(eventA.modifiedAt) : new Date(0);
            const modifiedAtB = eventB.modifiedAt ? new Date(eventB.modifiedAt) : new Date(0);
            return modifiedAtB.getTime() - modifiedAtA.getTime();
        });
    };

    // Condition to check if all events have isInCountdown set to true
    // Combine local and database events, then filter and sort
    const mergedEvents = [...events];
    const filteredEvents = mergedEvents.filter(isEventUpcomingOrToday);
    const sortedEvents = sortEvents(filteredEvents);

    let event_count = true;

    return(
        <div className="rbt-toolbar" data-theme="Conflux" data-featured="" data-button-position="76%"
             data-button-horizontal="right" data-button-alt="no">
            <section
                className={`rbt-sidearea rbt-edge rbt-btn-horizontal-right rbt-btn-alt-no rbt-loaded ${isRbtActive ? 'rbt-active' : ''}`}>
                <div className="rbt-dropdown">
                    <div className="rbt-btn" onClick={handleToggleRbtActive}>
                        {/* Notification button */}
                        <span className="rbt-icon">
                            {sortedEvents.some(event => isEventCreatedToday(event)) ? (
                                <NotificationIcon className="active" />
                            ) : (
                                event_count && <NotificationIcon />
                            )}
                               <ArrowIcon className="active" />
                        </span>
                        <span className="rbt-text-name">Notification</span>
                    </div>
                </div>
                <div className="rbt-dropdown_event">
                    <div className="rbt-btn_event" onClick={handleToggleDisplay}>
                        {/* Event button */}
                        <span className="rbt-icon">
                            <CalenderIcon />
                        </span>
                        <span className="rbt-text-name">Countdown</span>
                    </div>
                </div>
                <div className="rbt-list-holder">
                    <div className="rbt-list">
                        <ListNotification/>
                    </div>
                </div>
            </section>
            {
                events.length > 0 &&
                events.some(event => event.isInCountdown) && (
                    <div className={`popup_Bg ${displayEvents ? 'rbt-active' : ''}`}>
                        <div className="rbt-hide-popup" onClick={handleToggleDisplay}>
                            <Delete onClick={handleToggleDisplay} />
                        </div>
                        <ListCountDown />
                    </div>
                )
            }
        </div>
    )
}
export default Notification;

