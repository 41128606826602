import React from 'react';
import { Provider } from 'mobx-react';
import stores from './stores';

const AppProvider = ({ children }) => {
  return <Provider {...stores}>{children}</Provider>;
};


export default AppProvider;
