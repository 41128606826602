import { DownloadOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import React, { useEffect, useState } from 'react';
import dataStore from 'stores/dataStore';
import AssistantInfo from '../addUser/AssistantInfo';
import ContactInfo from '../addUser/ContactInfo';
import PeopleInfo from '../addUser/PeopleInfo';
type Props = {
    data : any,
    assistant : any ,
    assistants : any
}

export default function 
({
    data,assistant,assistants
}: Props) {
  const user = dataStore.selectedUser;
  const [downloadUrl,setDownloadUrl]=useState("");

  const loadDownloadUrl=async ()=>{
    const storage = firebase.storage();
    if(user?.avatarUrl) {
      storage.ref("userAvatar").child(`${user?.id}.jpg`).getDownloadURL()
          .then(async (url) => {
            setDownloadUrl(url)
          })
    }
  }


    const getHref = async () => {
        try {
            const response = await fetch(downloadUrl);

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));

            const link = document.getElementById("myLink");
            if (link) {
                link.setAttribute("href", url);
            } else {
                console.error('Link element not found');
            }
        } catch (error) {
            console.error('Failed to fetch the resource:', error);
        }
    };



  useEffect(()=>{
    loadDownloadUrl()
    getHref()
  },[user,downloadUrl]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
    <div className="UserModal">
        <div className="ProfileHeader" style={{display : "flex",alignItems:'center'}} >
            <div className="ProfileHeader">
                <Avatar
                 className={"antd-avatar"}
                  size={80}
                  icon={data.avatarUrl === undefined || data.avatarUrl==='' ? <UserOutlined /> : undefined}
                  style={{ boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)" }}
                  src={data.avatarUrl}
                />
                {downloadUrl !== "" && (
                  <a className="UploadContainer" onClick={getHref} href={'#/'} id={"myLink"}  download={`${user?.id}.jpg`}  target="_blank"  rel="noopener noreferrer">
                  <DownloadOutlined />
                </a>)}
                
            </div>
          <div style={{color: "#2a2a2a",display : 'flex' , flexDirection : "column" , marginLeft : "20px", marginBottom : "20px"}}>
            <span  style={{fontFamily: "ArialBD"}}>{data.firstName} {data.lastName}</span>
            <span  style={{fontFamily: "Arial"}}>{data.grade}</span>
          </div>
        </div>
        <div className="UserinfoContent">
          <div className='LeftColumn'>
          <h2 className="ContactTitle">Contact</h2>
          <div className="Body">
              <ContactInfo 
                  data={data}
                  action='Info'
              />
          </div>
          <h2 className="ContactTitle">Executive Assistant Contact Details</h2>
            <AssistantInfo
              data={data}
              action='Info'
              assistant={assistant}
              assistants={assistants}
            />
            
          </div>
          <div className='RightColumn'>
          <h2 className="ContactTitle">People information</h2>
            <PeopleInfo
              action='Info'
              data={data}
            />
          </div>
        </div>
    </div>
  )
}
