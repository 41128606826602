import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import {ApprovalAction, ApprovalState} from "../../Types/Approval/ApprovalTypes";
import {approvalReducer} from "./approvalReducer";

interface ApprovalContextProps {
    approvals: ApprovalState;
    dispatch: React.Dispatch<ApprovalAction>;
}

const ApprovalContext = createContext<ApprovalContextProps | undefined>(undefined);

export const ApprovalProvider = ({ children }: { children: ReactNode }) => {
    const [approvals, dispatch] = useReducer(approvalReducer, []);

    return (
        <ApprovalContext.Provider value={{ approvals, dispatch }}>
            {children}
        </ApprovalContext.Provider>
    );
};

export const useApprovals = () => {
    const context = useContext(ApprovalContext);
    if (!context) {
        throw new Error('useApprovals must be used within an ApprovalProvider');
    }
    return context;
};
