import {deleteFolder, loadDocumentStorage} from 'helpers/DocumentHandlers';
import {Item, Prefixe} from 'models/general';
import {ReactComponent as FolderSvg} from 'assets/documents/svg/folder.svg';
import {ReactComponent as MenuIcon} from 'assets/documents/svg/menu.svg';
import React, {FormEvent, useEffect, useRef, useState} from 'react'
import {Dropdown, Menu, message, Modal, Popconfirm, Spin} from 'antd';
import {IconCut, IconDownload, IconEdit, IconTrash} from '@tabler/icons';
import {extensionsIcons} from 'Utils/Constants/DocumentContants';
import {Cancel} from '@material-ui/icons';
import {downloadFile, updateCutItemsStore} from 'services/documents/DocumentDetailsServices';
import dataStore from 'stores/dataStore';
import 'firebase/storage';
import firebase from 'firebase/app';
import ReactPlayer from "react-player";
import 'wicg-inert';


type Props = {
    key: number,
    prefix: Prefixe | Item,
    currentPath: string,
    setCurrentPath: React.Dispatch<React.SetStateAction<string>>,
    setParent: React.Dispatch<React.SetStateAction<string>>,
    params: any,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    type: 'folder' | 'file',
    setLoadingFileVersion: React.Dispatch<React.SetStateAction<boolean>>
    setOpenCutFile: React.Dispatch<React.SetStateAction<boolean>>,
    openCutFile: boolean,
    loadFileVersion(item: Item, setLoadingFileVersion: React.Dispatch<React.SetStateAction<boolean>>): Promise<void>
    confirmDeleteFile: (e: any, file: Item | Prefixe) => void
    setOpenUpdateFileVersion: (value: React.SetStateAction<boolean>) => void
    permission: string
    currentUser: any
    setOpenEditFolderName: React.Dispatch<React.SetStateAction<boolean>>,
    setEditingFolder: React.Dispatch<React.SetStateAction<Prefixe | Item | null>>,
}
const confirmDeleteFolder = (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    prefix: Prefixe | Item,
    currentPath: string,
    setCurrentPath: React.Dispatch<React.SetStateAction<string>>,
    setParent: React.Dispatch<React.SetStateAction<string>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    params: any,
) => {
    deleteFolder(e, prefix, currentPath, setCurrentPath, setParent, setLoading, params).then()
};

const editFolderName = (
    prefix: Prefixe | Item,
    currentPath: string,
    setCurrentPath: React.Dispatch<React.SetStateAction<string>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    params: any,
    setOpenEditFolderName: React.Dispatch<React.SetStateAction<boolean>>,
    setEditingFolder: React.Dispatch<React.SetStateAction<Prefixe | Item | null>>,
) => {
    setOpenEditFolderName(true);
    setEditingFolder(prefix);
};


const menuFolder = (
    prefix: Prefixe | Item,
    currentPath: string,
    setCurrentPath: React.Dispatch<React.SetStateAction<string>>,
    setParent: React.Dispatch<React.SetStateAction<string>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    params: any,
    permission: string,
    currentUser: any,
    setOpenEditFolderName: React.Dispatch<React.SetStateAction<boolean>>,
    setEditingFolder: React.Dispatch<React.SetStateAction<Prefixe | Item | null>>,
) => (
    <Menu className="menu-container">
        <Menu.Item
            onClick={(e) => {
                e.domEvent.stopPropagation();
                editFolderName(prefix, currentPath, setCurrentPath, setLoading, params, setOpenEditFolderName, setEditingFolder);
            }}
            key="1"
            className="menu-item edit"
        >
            <IconEdit width={15} height={15} style={{marginRight: "5px"}}/>
            <span>Edit</span>
        </Menu.Item>

        {currentUser === "Global administrator" && (
            <Menu.Item
                onClick={(e) => {
                    e.domEvent.stopPropagation();
                }}
                key="2"
                className="menu-item delete"
            >
                <Popconfirm
                    overlayStyle={{zIndex: 10003}}
                    title="Are you sure to delete this folder?"
                    onConfirm={(e) =>
                        confirmDeleteFolder(
                            e,
                            prefix,
                            currentPath,
                            setCurrentPath,
                            setParent,
                            setLoading,
                            params
                        )
                    }
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <IconTrash width={15} height={15} style={{marginRight: "5px"}}/>
                        <span>Delete</span>
                    </div>
                </Popconfirm>
            </Menu.Item>
        )}
    </Menu>
);
type MenuProps = {
    item: Item | Prefixe,
    setLoadingFileVersion: React.Dispatch<React.SetStateAction<boolean>>,
    loadFileVersion(item: Item | Prefixe, setLoadingFileVersion: React.Dispatch<React.SetStateAction<boolean>>): Promise<void>,
    handleOpenUpdateFileVersion: () => void,
    setOpenCutFile: React.Dispatch<React.SetStateAction<boolean>>,
    openCutFile: boolean,
    confirmDeleteFile: (e: any, file: Item | Prefixe) => void,
    permission: string,
    downloadPermissions: string,
    currentUser: any
};

function DocumentMenuFile({
                              item,
                              loadFileVersion,
                              handleOpenUpdateFileVersion,
                              setOpenCutFile,
                              openCutFile,
                              confirmDeleteFile,
                              setLoadingFileVersion,
                              permission,
                              downloadPermissions,
                              currentUser
                          }: MenuProps) {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
        <Menu className="menu-container">

            {downloadPermissions === "true" || currentUser === 'Global administrator' ?
                <Menu.Item key="1" className="menu-item edit">
                    <div onClick={(e) => {
                        e.stopPropagation();
                        downloadFile(item).then();
                    }}>
                        <IconDownload width={15} height={15} style={{marginRight: '5px'}}/>
                        <span>Download</span>
                    </div>
                </Menu.Item>
            : null }
            {permission === "Full access" || currentUser === 'Global administrator' ?
                <Menu.Item key="2" className="menu-item edit">
                    <div onClick={(e) => {
                        e.stopPropagation();
                        loadFileVersion(item, setLoadingFileVersion).then();
                        handleOpenUpdateFileVersion()
                    }}>
                        <IconEdit width={15} height={15} style={{marginRight: '5px'}}/>
                        <span>Update version</span>
                    </div>
                </Menu.Item>
            : null}
            {permission === "Full access" || currentUser === 'Global administrator' ? (openCutFile ? (
                <Menu.Item key="3" className="menu-item Cut">
                    <div onClick={(e) => {
                        e.stopPropagation();
                        setOpenCutFile(false);
                        updateCutItemsStore(item as any).then();
                    }}>
                        <IconCut width={15} height={15} style={{marginRight: '5px'}}/>
                        <span>Cut</span>
                    </div>
                </Menu.Item>) : (
                <Menu.Item key="4" className="menu-item cancel">
                    <div onClick={(e) => {
                        e.stopPropagation();
                        setOpenCutFile(true);
                        dataStore.resetItemsCutSelected()
                    }}>
                        <Cancel width={15} height={15} style={{marginRight: '5px'}}/>
                        <span>Cancel Cut</span>
                    </div>
                </Menu.Item>)) : null}
            {currentUser === 'Global administrator' ? <Menu.Item onClick={(e) => {
                e.domEvent.stopPropagation()
            }} key="5" className="menu-item delete">
                <Popconfirm
                    overlayStyle={{zIndex: 10003}}
                    title="Are you sure to delete this file?"
                    onConfirm={(e) => confirmDeleteFile(e, item)}
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <IconTrash width={15} height={15} style={{marginRight: '5px'}}/>
                        <span>Delete</span>
                    </div>
                </Popconfirm>
            </Menu.Item> : null}
        </Menu>
    )
}
// DocumentsItem Component
export default function DocumentsItem({
                                          prefix,
                                          setCurrentPath,
                                          setParent,
                                          params,
                                          setLoading,
                                          currentPath,
                                          type,
                                          setLoadingFileVersion,
                                          loadFileVersion,
                                          setOpenUpdateFileVersion,
                                          setOpenCutFile,
                                          openCutFile,
                                          confirmDeleteFile,
                                          permission,
                                          currentUser,
                                          setOpenEditFolderName,
                                          setEditingFolder,
                                      }: Props){
    const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
    const [fileUrl, setFileUrl] = useState<string | null>(null); // File URL state
    const [loadingFile, setLoadingFile] = useState(false); // State to indicate file is loading
    const [retryCount, setRetryCount] = useState(0); // Count for retries
    const modalRef = useRef<HTMLDivElement>(null); // Ref for modal focus management
    const contentRef = useRef<HTMLDivElement>(null); // Ref for content inside modal
    const [downloadPermissions, setDownloadPermissions] = useState<string>('');

    useEffect(() => {
        // Handle focus when modal opens and file loading
        if (isModalVisible && modalRef.current) {
            if (loadingFile) {
                modalRef.current.focus();
            } else if (contentRef.current) {
                contentRef.current.focus();
            }
        }

        // If it's a folder, no file needs to be loaded
        if (type === 'folder') return;

        const storageRef = firebase.storage().ref(prefix.fileUrl);
        storageRef.getMetadata().then((metadata) => {
            setDownloadPermissions(metadata.customMetadata?.downloadPermissions);// This should log "true" or "false" based on the metadata
        }).catch((error) => {
            console.error("Error retrieving metadata:", error);
        });
        setLoadingFile(true); // Show the spinner when the modal is opened
        const fetchFile = () => {
            storageRef
                .getDownloadURL()
                .then((url: string) => {
                    setFileUrl(url);
                    setLoadingFile(false); // Stop the spinner after successful loading
                    setRetryCount(0); // Reset the retry count after success
                })
                .catch((error) => {
                    console.error('Error getting file URL', error);
                    setLoadingFile(false); // Stop the spinner on error
                    if (retryCount < 3) {
                        setRetryCount(retryCount + 1); // Retry up to 3 times
                        setTimeout(fetchFile, 3000); // Retry after 3 seconds
                    } else {
                        message.info('There was an issue fetching the file. Please try again.'); // Set error message
                    }
                });
        };

        fetchFile();

        // Timeout for detecting if the file is taking too long to load
        const timeoutId = setTimeout(() => {
            if (loadingFile) {
                message.info('The file is taking too long to load.');
            }
        }, 10000); // Timeout after 10 seconds

        return () => clearTimeout(timeoutId); // Clean up the timeout when the component unmounts or modal closes
        // eslint-disable-next-line
    }, [isModalVisible, prefix.fileUrl, prefix.name, type, retryCount]);

    // Handle `inert` attribute dynamically based on file loading state
    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.inert = loadingFile || !fileUrl; // Apply `inert` dynamically
        }
    }, [loadingFile, fileUrl]);

    // Get file extension for checking types (like images)
    const getExtension = (fileName: string) => {
        return fileName.split('.').pop()?.toLowerCase() || ''; // Return the extension or an empty string
    };

    const extension = getExtension(prefix.name);
    const imageExtensions = ["png", "jpeg", "jpg", "gif", "bmp", "tiff", "webp", "svg"]; // Supported image types

    // Handle modal cancellation
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // Handle file preview (open modal and load the file)
    const handlePreview = () => {
        const storageRef = firebase.storage().ref(prefix.fileUrl);
        storageRef.getDownloadURL().then((url: string) => {
            setFileUrl(url);
            setIsModalVisible(true);
        });
    };

    // Updated part: Handle non-image file loading via Google Viewer or direct preview
    const renderFilePreview = () => {
        const validFileUrl = fileUrl ?? ''; // If fileUrl is null, set it to an empty string

        if (getExtension(prefix.name) === 'mp4' && fileUrl) {
            return <ReactPlayer style={{ paddingTop: '20px' }} url={validFileUrl} controls width="100%" height="500px" />;
        }

        const isFirefoxBrowser = () => {
            return /Firefox/.test(navigator.userAgent); // Detects Firefox
        };

        if (getExtension(prefix.name) === 'pdf' && fileUrl && isFirefoxBrowser()) {

            return (
                <>
                    {currentUser !== "Global administrator" ? (
                        <div style={{
                            position: 'absolute',
                            background: 'white',
                            width: 'calc(100% - 88px)',
                            height: '32px',
                        }}></div>
                    ) : (
                        <div style={{
                            paddingTop: '30px'
                        }}></div>
                    )}

                    <iframe
                        src={validFileUrl}
                        width="100%"
                        loading="lazy"
                        height="500px"
                        title="PDF Preview"
                        style={{ border: 'none' }}
                        sandbox="allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
                        onLoad={() => setLoadingFile(false)}
                        onError={() => {
                            setLoadingFile(false);
                        }}
                    />
                </>
            );
        }

        return (
            <>
                <div
                    style={{
                        display: 'grid',
                        justifyContent: 'center',
                        top: '45%',
                        position: 'absolute',
                        zIndex: 1,
                        width: 'calc(100% - 88px)',
                    }}
                >
                    <Spin tip="Loading file, please wait..." size="large" />
                </div>
                <iframe
                    style={{
                        position: 'relative',
                        zIndex: 2,
                    }}
                    src={`https://docs.google.com/gview?url=${encodeURIComponent(validFileUrl)}&embedded=true`}
                    width="100%"
                    loading="lazy"
                    height="500px"
                    title="File Preview"
                    onLoad={() => setLoadingFile(false)}
                    onError={() => {
                        setLoadingFile(false);
                    }}
                />
            </>
        );
    };

    return (
        <>
            <div
                onClick={
                    type === 'folder'
                        ? (e) => {
                            e.stopPropagation();
                            loadDocumentStorage(prefix.fileUrl, setCurrentPath, setParent, params, setLoading).then();
                        }
                        : () => {
                            // Disable modal for images and allow preview for other files
                            if (!imageExtensions.includes(extension)) handlePreview();
                        }
                }
                className="item-folder folder"
            >
                <div className="header-container">
                    {type === "folder" && <FolderSvg className="folder-icon" />}
                    {type === "file" && (
                        <>
                            {extension !== undefined && imageExtensions.includes(extension) && fileUrl ? (
                                <img
                                    src={fileUrl}
                                    alt={prefix.name}
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        objectFit: 'cover',
                                        margin: '0 auto',
                                        display: 'block',
                                        borderRadius: '5px',
                                    }}
                                />
                            ) : (
                                extensionsIcons[extension as keyof typeof extensionsIcons] // Display icon for non-image files
                            )}
                        </>
                    )}
                </div>

                {/* File name and menu icon in the same line */}
                <div
                    className="name-menu-container"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '5px',
                    }}
                >
                    <span
                        className="item-title"
                        style={{
                            textAlign: 'left',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginBottom: '10px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        title={prefix.name}
                    >
                        {prefix.name}
                    </span>
                    <Dropdown
                        overlay={
                            type === "folder"
                                ? menuFolder(
                                    prefix,
                                    currentPath,
                                    setCurrentPath,
                                    setParent,
                                    setLoading,
                                    params,
                                    permission,
                                    currentUser,
                                    setOpenEditFolderName,
                                    setEditingFolder
                                )
                                : DocumentMenuFile({
                                    item: prefix,
                                    loadFileVersion,
                                    handleOpenUpdateFileVersion: () => setOpenUpdateFileVersion(true),
                                    setOpenCutFile,
                                    openCutFile,
                                    confirmDeleteFile,
                                    setLoadingFileVersion,
                                    permission,
                                    downloadPermissions,
                                    currentUser,
                                })
                        }
                        trigger={["hover"]}
                    >
                        {(type === "folder" && currentUser !== "Global administrator") || (downloadPermissions !== "true" && currentUser !== "Global administrator" && dataStore.folderParentAccess.access !== "Full access") ? (
                            <></>
                        ) : (
                            <MenuIcon
                                className="menu-icon"
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                    width: '16px',
                                    height: '16px',
                                    marginLeft: '10px',
                                }}
                            />
                        )}
                    </Dropdown>
                </div>
            </div>

            {/* Modal for file preview (PDFs, documents, and other files) */}
            <Modal
                title={prefix.name}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width="80vw"
                bodyStyle={{ maxHeight: '80vh', overflowY: 'auto', paddingTop: '0px' }}
                maskClosable={false}
            >
                <div ref={modalRef}>
                    {/* Show spinner if the file is still loading */}
                    {loadingFile || !fileUrl ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Spin tip="Loading file, please wait..." size="large" />
                        </div>
                    ) : (
                        <div ref={contentRef} style={{ padding: '0 20px 10px' }}>
                            {renderFilePreview()}
                        </div>
                    )}
                </div>
            </Modal>
        </>
    );
}




