
import { observable, action, decorate } from 'mobx';

export interface Message {
    message: string;
    type: "success" | "error" | "warning" | "info";
}

export interface FullMessage extends Message {
    created: number;
}


export class MessageStore {

    snacks: FullMessage[] = [];

    snackbar(snack: Message) {
        this.snacks.push({...snack, created: Date.now() });
    }

    removeonesnack() {
        this.snacks.shift();
    }

}

decorate(MessageStore, {
    snacks: observable,
    snackbar: action,
    removeonesnack: action,
});


export default new MessageStore();
