import { RoutesTypes } from "../../Types/Sidebar/routesType";
import Users from "../../routes/core/users/Users";
import { ROUTES } from "../../parameters/routes";
import SupervisedUserCircleSharpIcon from "@material-ui/icons/SupervisedUserCircleSharp";
import Events from "../../routes/core/events/Events";
import Approvals from "../../routes/core/approval/Approvals";
import Dashboard from "../../routes/core/dashboard/Dashboard";
import Management from "../../routes/core/Management/Management";
import FirmsProfessionals from "routes/core/firmsProfessionals/FirmsProfessionals";
import Firms from "../../routes/core/firms/Firms";
import Profile from "routes/core/profile/Profile";
import Documents from "../../routes/core/documents/documents";
import News from "../../routes/core/news/News";
import { ReactComponent as FoldersIcon } from "assets/folders.svg";
import { ReactComponent as DashboardIcon } from "assets/dashboard.svg";
import { ReactComponent as UserIcon } from "assets/user.svg";
import { ReactComponent as PeopleIcon } from "assets/PeopleIcon.svg";
import { ReactComponent as FirmsIcon } from "assets/building.svg";
import { ReactComponent as NewsIcon } from "assets/newspaper.svg";
import { ReactComponent as CalenderIcon } from "assets/calendar-range.svg";
import { ReactComponent as ApprovalsIcon } from "assets/user-check.svg";
import { ReactComponent as ManagementIcon } from "assets/settings.svg";
import dataStore from "../../stores/dataStore";


export const AppRoutes : RoutesTypes[] = [
    {
        Title: "Dashboard",
        Path: ROUTES.dashboard,
        Icon: DashboardIcon,
        Component: Dashboard,
        userRight: ["Global administrator"]
    },
    {
        Title: "My Profile",
        Path: ROUTES.home,
        Icon: UserIcon,
        Component: Profile,
        userRight: ["*"]
    },
    {
        Title: "Taxanders",
        Path: ROUTES.users,
        Icon: PeopleIcon,
        Component: Users,
        userRight: ["*"]
    },
    {
        Title: "Firms",
        Path: ROUTES.firms,
        Icon: FirmsIcon,
        Component: Firms,
        userRight: ["Global administrator"]
    },
    {
      Title: "News",
      Path: ROUTES.news,
      Icon: NewsIcon,
      Component: News,
      userRight: ["*"]
    },
    {
        Title: "Events",
        Path: ROUTES.events,
        Icon: CalenderIcon,
        Component: Events,
        userRight: ["*"]
    },
    {
        Title: "Documents",
        Path: ROUTES.documents,
        Icon: FoldersIcon ,
        Component: Documents,
        userRight: ["*"]
    },
    {
        Title: "Approvals",
        Path: ROUTES.approvals,
        Icon: ApprovalsIcon,
        Component: Approvals,
        userRight: ["Global administrator"],
        count: dataStore.approvals.length
    },
    {
        Title: "My firm’s professionals",
        Path: ROUTES.professionalsFirm,
        Icon: SupervisedUserCircleSharpIcon,
        Component: FirmsProfessionals,
        userRight: ["Firm administrator", "Assistant"]
    },
    {
        Title: "Management",
        Path: ROUTES.management,
        Icon: ManagementIcon,
        Component: Management,
        userRight: ["Global administrator"]
    }];
