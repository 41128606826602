import React, {FormEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {CalenderEvent} from '../../../../models/calenderEvent';
import {Category} from "../../../../models/category";
import moment, {Moment} from 'moment';
import {Button, DatePicker, Input, Modal, notification, Select, Spin, Tooltip} from 'antd';
import {Checkbox, Radio, Tooltip as AccessTooltip} from '@mantine/core';
import {TextField} from "@material-ui/core";
import {API} from "../../../../api";
import '../Events.scss';
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {UserData} from "../../../../models/general";
import ContentEditable from "react-contenteditable";
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports

const {Option} = Select;

const loadingIcon = <LoadingOutlined style={{fontSize: 24, color: "white", marginLeft: '5px'}}/>

interface AddEventProps {
    isUpdateMode: boolean;
    currentUser: UserData;
    categoriesFilter: Category[];
    newEvent: CalenderEvent;
    setNewEvent: React.Dispatch<React.SetStateAction<CalenderEvent>>;
}

// Component to add events
function AddEvent({isUpdateMode, currentUser, categoriesFilter, newEvent, setNewEvent}: AddEventProps) {
    const {t} = useTranslation();
    const {title, description, start, end, access, category, isInCountdown} = newEvent;

    // State initialization
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [categoryInputValue, setCategoryInputValue] = useState("");
    const [newCategoryLoading, setNewCategoryLoading] = useState(true); // Loading for creating a new category
    const [defaultCategory, setDefaultCategory] = useState(category);
    const [categories, setCategories] = useState<Category[]>([]);
    const [dateRangeError, setDateRangeError] = useState(false); // Start date and end date verification
    const [token, setToken] = useState('');
    // Check for any change in category input value
    const canSubmitCategory = useMemo(() => categoryInputValue !== "", [categoryInputValue]);

    // Fetch categories from the API
    const getAllCategories = useCallback(async () => {
        try {
            const allCategories = await API.getAllCategories();
            setCategories([...allCategories]);
        } catch (error) {
            setCategories([]);
        }
    }, []);

    // Convert URLs in the text to hyperlinks
    const transformUrlsToLinks = (text: string) => {
        const urlPattern = /https?:\/\/[^\s]+/g;
        return text.replace(urlPattern, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer" style="font-weight:bold">${url}</a>`);
    };

    // Link redirection if the ContentEditable is not disabled
    const handleContentEditableClick = (event: React.SyntheticEvent) => {
        const targetElement = event.target as HTMLDivElement;
        // Check if the clicked element is a hyperlink
        if (targetElement.tagName === 'A') {
            event.preventDefault();
            // Redirection to the link's href
            const linkHref = targetElement.getAttribute('href');
            window.open(linkHref!, '_blank');
        }
    };

    const [descriptionHtml, setDescriptionHtml] = useState<string>(transformUrlsToLinks(description));

    // Event handlers for different actions
    const handleTitleChange = (value: string) => setNewEvent(prev => ({...prev, title: value}));
    const handleDescriptionHtmlChange = (event: React.SyntheticEvent) => {
        const divElement = event.currentTarget as HTMLDivElement;
        const textWithoutTags = divElement.innerText;
        setDescriptionHtml(transformUrlsToLinks(textWithoutTags));
        setNewEvent(prev => ({...prev, description: textWithoutTags}));
    };

    const handleStartDateChange = (date: Moment | null) => {
        if (date && newEvent.end && date.isSameOrAfter(newEvent.end)) {
            setDateRangeError(true);
        } else {
            setDateRangeError(false);
        }
        setNewEvent(prev => ({ ...prev, start: date ? date.toDate() : null }));
    };
    const handleEndDateChange = (date: Moment | null) => {
        if (date && newEvent.start && date.isSameOrBefore(newEvent.start)) {
            setDateRangeError(true);
        } else {
            setDateRangeError(false);
        }
        setNewEvent(prev => ({ ...prev, end: date ? date.toDate() : null }));
    };

    const handleAccessChange = (value: string) => setNewEvent(prev => ({...prev, access: value}));
    const handleCategoryChange = (value: string) => {
        const selectedCategory = categories!.find(category => category.name === value);
        if (selectedCategory) {
            setDefaultCategory(selectedCategory.name);
            setNewEvent(prev => ({...prev, category: selectedCategory.name}));
        }
    };

    const handleOpenCategoryModal = () => {
        setIsModalOpen(true);
        setNewCategoryLoading(false);
    };
    const handleCloseCategoryModal = () => setIsModalOpen(false);

    const handleClick = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        setCategoryInputValue(event.target.value as string);
    }, []);
    const handleSubmitCategory = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }, []);

    async function addNewCategory(inputValue: string) {
        setNewCategoryLoading(true);
        if (inputValue !== "") {
            const newCategoryId = await API.getCategoryId();
            try {
                const response =await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/createCategory`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token} `,
                    },
                    body: JSON.stringify({
                        id: newCategoryId,
                        name: inputValue,
                        callType: ""
                    })
                });
                const responseData = await response.json();
                if(response.status !== 200) {
                    throw new Error(responseData.error);
                }
                const newCategory: Category = { id: responseData.category_res, name: inputValue };
                setCategories(prev => [...prev, newCategory]);
                // Add the new category to the list of categories in the filter
                categoriesFilter.push(newCategory);
                notification['success']({ message: 'Success', description: t('Category successfully added') });
                setNewCategoryLoading(false);
                handleCloseCategoryModal();
            } catch (error) {
                notification['error']({ message: 'Error', description: t('Failed to create a new category') });
            } finally {
                setNewCategoryLoading(false);
                handleCloseCategoryModal();
            }
        }
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async function (user) {
                if (user) {
                    const token = await user.getIdToken();
                    setToken(token);
                } else {
                    setToken('');
                }
            }
        );
    }, []);
    useEffect(() => {
        getAllCategories();
    }, [getAllCategories]);

    return (
        <div>
            <div className="createEventContainer">
                <div className="createEventInput">
                    <label className="InputLabel"> Event Title <span className="asterisk-span">*</span> </label>
                    <br />
                    <Input
                        required
                        name="eventTitle"
                        size="middle"
                        type="text"
                        style={{ borderRadius: '5px' }}
                        onChange={(e) => handleTitleChange(e.target.value)}
                        value={title}
                        disabled={isUpdateMode && currentUser.right !== "Global administrator" && newEvent?.access === "Limited"}
                    />
                </div>
                <br />
                <div className="createEventInput">
                    <label className="InputLabel">Description</label>
                    <br />
                    <ContentEditable
                        html={descriptionHtml}
                        dir="ltr"
                        onChange={event => handleDescriptionHtmlChange(event)}
                        onClick={(event) => handleContentEditableClick(event)}
                        style={{
                            textAlign:"left",
                            border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: '5px',
                            minHeight: "100px",
                            whiteSpace: "pre-wrap",
                            cursor: isUpdateMode && currentUser.right !== "Global administrator" && newEvent?.access === "Limited" ? "not-allowed" : "auto",
                            color: isUpdateMode && currentUser.right !== "Global administrator" && newEvent?.access === "Limited" ? "rgba(0, 0, 0, 0.25)" : "inherit",
                            backgroundColor: isUpdateMode && currentUser.right !== "Global administrator" && newEvent?.access === "Limited" ? "#f5f5f5" : "inherit",
                            opacity: isUpdateMode && currentUser.right !== "Global administrator" && newEvent?.access === "Limited" ? "1" : "inherit",
                        }}
                        disabled={isUpdateMode && currentUser.right !== "Global administrator" && newEvent?.access === "Limited"}
                    />
                </div>
                <br />
                <div className="createEventInput">
                    <label className="InputLabel"> Start Date <span className="asterisk-span">*</span> </label>
                    <br />
                    <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        value={start ? moment(start) : null}
                        onChange={handleStartDateChange}
                        disabled={isUpdateMode && currentUser.right !== "Global administrator" && newEvent?.access === "Limited"}
                    />
                </div>
                <br />
                <div className="createEventInput">
                    <label className="InputLabel"> End Date <span className="asterisk-span">*</span> </label>
                    <br />
                    <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        value={end ? moment(end) : null}
                        onChange={handleEndDateChange}
                        disabled={isUpdateMode && currentUser.right !== "Global administrator" && newEvent?.access === "Limited"}
                    />
                    {dateRangeError && (
                        <div style={{ color: 'red', marginTop: '5px' }}>
                            End date and time must be after the start date and time.
                        </div>
                    )}
                </div>
                <br />
                <div className="createEventInput">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <label className="InputLabel"> Category <span className="asterisk-span">*</span> </label>
                        <Tooltip title="Add a new category">
                            <Button
                                type="primary"
                                icon={<PlusOutlined style={{ fontSize: "14px" }} />}
                                shape="circle"
                                size="small"
                                onClick={handleOpenCategoryModal}
                                disabled={isUpdateMode && currentUser.right !== "Global administrator" && newEvent?.access === "Limited"}
                            />
                        </Tooltip>
                    </div>
                    <Modal
                        visible={isModalOpen}
                        title="Add new category"
                        centered
                        onCancel={handleCloseCategoryModal}
                        destroyOnClose
                        maskClosable={false}
                        footer={[
                            <Button
                                key="back"
                                onClick={handleCloseCategoryModal}
                            >
                                Cancel
                            </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                disabled={!canSubmitCategory}
                                onClick={() => addNewCategory(categoryInputValue)}
                            >
                                Add
                                <Spin spinning={newCategoryLoading} indicator={loadingIcon}/>
                            </Button>,
                        ]}
                    >
                        <form onSubmit={handleSubmitCategory}>
                            <div style={{ padding: "20px" }}>
                                <TextField
                                    required
                                    style={{ width: "100%" }}
                                    label="Category name"
                                    onChange={handleClick}
                                />
                            </div>
                            <br />
                        </form>
                    </Modal>
                    <Select
                        style={{ width: '100%', marginTop: "5px" }}
                        placeholder={
                            categories.length !== 0 && !isUpdateMode
                                ? "Select a category"
                                : "No category found, please add one"
                        }
                        value={isUpdateMode ? defaultCategory: undefined}
                        onChange={handleCategoryChange}
                        disabled={isUpdateMode && currentUser.right !== "Global administrator" && newEvent?.access === "Limited"}
                    >
                        {categories.map(({ id, name }) => (
                            <Option key={`${id}-${name}`} value={name}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className="input-container">
                    <Radio.Group
                        name="Access"
                        label="Select event access"
                        description="Choose whether the event has full or limited access"
                        onChange={handleAccessChange}
                        value={access}
                        hidden={isUpdateMode && currentUser.right !== "Global administrator"}
                        withAsterisk
                    >
                        <AccessTooltip
                            transition="slide-right"
                            transitionDuration={300}
                            label="The event can be viewed, modified and deleted"
                        >
                            <Radio
                                value="Full access"
                                label="Full access"
                            />
                        </AccessTooltip>
                        <AccessTooltip
                            transition="slide-left"
                            transitionDuration={300}
                            label="The event can be viewed with limited access"
                        >
                            <Radio
                                value="Limited"
                                label="Limited"
                            />
                        </AccessTooltip>
                    </Radio.Group>
                </div>
                <div className="input-container">
                    <label className="InputLabel">Event countdown</label>
                    <br />
                    <Tooltip
                        title="Remember that after checking this, the event will be displayed in the countdown swiper"
                        placement="left"
                    >
                        <Checkbox
                            label="Display the event in the countdown swiper"
                            onChange={(e) => {
                                const value = e.target.checked;
                                setNewEvent({
                                    ...newEvent,
                                    isInCountdown: value,
                                });
                            }}
                            checked={isInCountdown}
                            disabled={(isUpdateMode && currentUser.right !== "Global administrator" && newEvent?.access === "Limited")}
                        />
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}

export default AddEvent;
