import React from 'react';
import './LandingModel.scss';

interface ChildComponentProps {
    onButtonClick: () => void; // Specify the function type for onButtonClick
}

const LandingModel: React.FC<ChildComponentProps> = ({ onButtonClick  }) => {


    return (
        <div className="card_Landing fade-in">
            <div className="background_Landing"></div>
            <div className="br_popup_header">Welcome to <strong>The Hub</strong>! Our new platform designed exclusively for Taxanders.</div>
            <p>
                Here, we unite as a <strong>network of professionals</strong> committed to excellence, <strong>knowledge-sharing</strong> and <strong>collaboration</strong>. A space which enables experts to come together.
            </p>
            <p>
                Here you can actively participate in shaping the future of Taxand, contribute your <strong>insights</strong>, be inspired by those shared by others and find new connections to expand your <strong>international network</strong>.
            </p>
            <p>
                Introducing the Hub - make it your go-to source for Taxand globally.
            </p>
            <div className={"btns-landing"}>
                <button className="btn" onClick={onButtonClick}>Not Show Again</button>
            </div>
        </div>
    );
}

export default LandingModel;