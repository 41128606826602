import { Input } from 'antd';
import React from 'react'
import {Select,MenuItem} from "@material-ui/core";



type dataType = {
    email: string,
    password: string,
    againPassword: string,
    firstName: string,
    lastName: string,
    generatedPass: string,
    phone: string,
    mobile: string,
    personnalBiography: string,
    profesionnalBiography: string,
    deleted: boolean,
    linkedInProfileLink: string,
    twitterProfileLink: string,
    assistantName: string,
    assistantEmail: string,
    assistantTelephone: string,
    assistantMobile: string
    assistantId ?: string
}
type Props = {
    data : any,
    handleChangeAssistant?: (value: any) => void,
    handleDataChange?: ({ currentTarget }: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void,
    assistants ?: any,
    assistant : any ,
    action ?: 'Edit' | 'Info' | 'Add',
    dataRow ?: any
}

export default function AssistantInfo({handleChangeAssistant,assistant,assistants,handleDataChange,data,action,
  dataRow}: Props) {
  return (
    <div className="ContactContainer">
      <div className="InputFullSize">
        <label className="InputLabel">Assistant</label>
        <br />
        {action === 'Edit' || action === 'Add' || action === undefined ?(
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-Firm"
                    value={assistant}
                    onChange={handleChangeAssistant}
                >
                    {assistants.sort((a:any, b:any) => a.firstName.toLocaleLowerCase() > b.firstName.toLocaleLowerCase() ? 1 : -1).map((c: any) => <MenuItem key={c.id}
                                                                                                                                                             value={c}>{c.firstName+" "+c.lastName + " - " + c.firm.firm+ " - " + c.right}</MenuItem>)}
                </Select>
        ) : (
          <span style={{ fontFamily: "Arial", color: "#696868" }}>
            {assistants.find((c: any) => c.id === data.assistantId) !== undefined ? assistants.find((c: any) => c.id === data.assistantId)?.firstName + " " + assistants.find((c: any) => c.id === data.assistantId)?.lastName : "- -"}
          </span>
        )}
      </div>
      <div className="InputSameRow">
        <div className="InputHalfSize">
          <label className="InputLabel">Executive assistant name </label><br />
          {action === 'Edit' || action === 'Add' || action === undefined ? (
            <Input
            autoComplete="off"
            required
            name="assistantName"
            size="large"
            placeholder="Enter executive assistant's name"
            type="text"
            onChange={handleDataChange ? (e) => handleDataChange(e) : undefined}
            value={data.assistantName}
            style={dataRow?.assistantName !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #5dc149",marginTop: "10px" } : {marginTop: "10px" }}
            />
          ) : ( 
            <span style={{ fontFamily: "Arial", color: "#696868" }}>{(data.assistantName !== undefined && data.assistantName !== "") ? data.assistantName : "- -"}</span>
          )}
        </div>
        <div className="InputHalfSize">
          <label className="InputLabel">Executive assistant email</label><br />
          {action === 'Edit' || action === 'Add' || action === undefined ? (
            <Input
            autoComplete="off"
            required
            name="assistantEmail"
            size="large"
            placeholder="Enter executive assistant's email"
            type="text"
            value={data.assistantEmail}
            onChange={handleDataChange ? (e) => handleDataChange(e) : undefined}
            style={dataRow?.assistantEmail !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #5dc149",marginTop: "10px" } : {marginTop: "10px" }}

          />
          
          ) : ( 
            <span style={{ fontFamily: "Arial", color: "#696868" }}>{(data.assistantEmail !== undefined && data.assistantEmail !== "") ? data.assistantEmail : "- -"}</span>
          )}

        </div>
      </div>
      <div className="InputSameRow">
        <div className="InputHalfSize">
          <label className="InputLabel">Mobile number </label><br />
          {action === 'Edit' || action === 'Add' || action === undefined ? (
            <Input
            autoComplete="off"
            required
            name="assistantMobile"
            size="large"
            placeholder="Enter executive Assistant mobile number"
            type="text"
            onChange={handleDataChange ? (e) => handleDataChange(e) : undefined}
            value={data.assistantMobile}
            style={dataRow?.assistantMobile !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #5dc149",marginTop: "10px"} : {marginTop: "10px"}}
            />
          ) : (
            <span style={{ fontFamily: "Arial", color: "#696868" }}>{(data.assistantMobile !== undefined && data.assistantMobile !== "") ? data.assistantMobile : "- -"}</span>
            )}
        </div>
        <div className="InputHalfSize">
          <label className="InputLabel">Direct Line</label><br />
          {action === 'Edit' || action === 'Add' || action === undefined ? (
            <Input
            autoComplete="off"
            required
            name="assistantTelephone"
            size="large"
            placeholder="Enter executive's direct Line"
            type="text"
            value={data.assistantTelephone}
            onChange={handleDataChange ? (e) => handleDataChange(e) : undefined}
            style={dataRow?.assistantTelephone !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #5dc149",marginTop: "10px"} : {marginTop: "10px"}}
            />
          ) : (
            <span style={{ fontFamily: "Arial", color: "#696868" }}>{(data.assistantTelephone !== undefined && data.assistantTelephone !== "") ? data.assistantTelephone : "- -"}</span>
            )}
        </div>
      </div>
    </div>
  );
}
