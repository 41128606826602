import authStore from './authStore';
import dataStore from './dataStore';
import messageStore from './messageStore';


export default {
  authStore,
  dataStore,
  messageStore,
};
