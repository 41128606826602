import {CalenderEvent} from "../../models/calenderEvent";
import {AppAction} from "../../Types/EventCalender/EventTypes";

export function eventReducer(state: CalenderEvent[], action: AppAction) {
    switch (action.type) {
        case 'ADD_EVENT':
            return [...state, action.payload];
        case 'UPDATE_EVENT':
            const indexToUpdate = state.findIndex(event => event.id === action.payload.id);
            if (indexToUpdate === -1) return state;
            return [
                ...state.slice(0, indexToUpdate),
                action.payload,
                ...state.slice(indexToUpdate + 1),
            ];
        case 'DELETE_EVENT':
            return state.filter(event => event.id !== action.payload);
        case 'SET_EVENTS':
            return action.payload;
        default:
            return state;
    }
}
