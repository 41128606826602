
import { observable, computed, action, decorate } from 'mobx';
import {
    UserData,
    GroupChatExtended,
    UserRequestData,
    FirmData,
    UserRequestUpdate,
    Folder,
    File, FolderItems, Prefixe, Item, Permission
} from '../models/general';
import {ApprovalStatus} from "../models/ApprovalStatusEnum";

export class DataStore {
    users: UserData[] = [];
    deletedUsers: UserData[] = [];
    firms: FirmData[]=[];
    selectedUser: UserData | undefined;
    groupChats: GroupChatExtended[] = [];
    userRequests: UserRequestData[] = [];
    approvals:UserRequestUpdate[]=[];
    countries:any[]=[];
    countrySelected:number=0;
    imageWordPressSelected:number=0;
    wordpressUserId:number=0;
    deletedUserWordpressId:number=0;
    userInfo : UserData | undefined = {
        email: '',
        id: '',
        firstName: '',
        lastName: '',
        phone: '',
        mobile: '',
        personnalBiography: '',
        profesionnalBiography: '',
        linkedInProfileLink: '',
        twitterProfileLink: '',
        assistantName: '',
        assistantEmail: '',
        assistantTelephone: '',
        assistantMobile: '',
        avatarUrl: '',
        approvalStatus: ApprovalStatus.Default
    };
    files:File[]=[];
    folders:Folder[]=[];
    folderItems:FolderItems[]=[];
    fileItems:FolderItems[]=[];
    prefixes:Prefixe[]=[];
    items:Item[]=[];
    itemsFileVersionSelected:Item[]=[]
    currentRoute: string | undefined = '';
    permission:Permission={
        country:[],
        taxSpecialism:[],
        firm:[],
        user:[],
        access:"",
        status:"",
        description:"",
        name:""
    };
    fileVersionSelected:Item={
        id:"",
        name:"",
        version:'',
        fileUrl:'',
        parent:'',
        isDirectory:false,
        lastUpdate:new Date(),
        size:0,
        isLastVersion:'',
    }
    itemsCutSelected:Item[]=[]
    folderParentAccess:any={
        name:'',
        access:'',
    }
    fileVersionUploaded:boolean=false;
    profileFormChanged:boolean=false;

    updateCurrentRoute(newRoute: string | undefined) {
        this.currentRoute = newRoute;
    }

    updateUserInfo(newUserInfo: UserData) {
        this.userInfo = newUserInfo;
    }

    updateCountries(value: String[]) {
        this.countries = value;
    }
    updateCountrySelected(value: number) {
        this.countrySelected = value;
    }
    updateWordpressUserId(value: number) {
        this.wordpressUserId = value;
    }
    updateDeletedUserWordpressId(value: number) {
        this.deletedUserWordpressId = value;
    }
    updateImageWordPressSelected(value: number) {
        this.imageWordPressSelected = value;
    }

    updateUser(value: UserData | undefined) {
        this.selectedUser = value;
    }

    updateUsers(newUsers: UserData[]) {
        this.users = newUsers;
    }
    updateDeletedUsers(newUsers: UserData[]) {
        this.deletedUsers = newUsers;
    }

    updateApprovals(newRequestApproval: UserRequestUpdate[]) {
        this.approvals = newRequestApproval;
    }
    updateFirms(newFirm: FirmData[]) {
        this.firms = newFirm;
    }

    updateGroupChat(newGroupChat: GroupChatExtended[]) {
        this.groupChats = newGroupChat;
    }


    updateUserRequest(newUserRequests: UserRequestData[]) {
        this.userRequests = newUserRequests;
    }

    updateFiles(value: File[]) {
        this.files = value;
    }

    updateFolder(value: Folder[]) {
        this.folders = value;
    }

    get getUsers(): UserData[] {
        return this.users;
    }
    updateFolderItems(value: FolderItems[]) {
        this.folderItems = value;
    }
    updateFileItems(value: FolderItems[]) {
        this.fileItems = value;
    }

    updatePrefixes(value: Prefixe[]) {
        this.prefixes = value;
    }
    updateItems(value: Item[]) {
        this.items = value;
    }
    updateItemsFileVersionSelected(value: Item[]) {
        this.itemsFileVersionSelected = value;
    }
    updateFolderParentAccess(value: any) {
        this.folderParentAccess = value;
    }

    updateItemsCutSelected(value: Item[]) {
        this.itemsCutSelected = value;
    }

    updateFileVersionUploaded(value: boolean) {
        this.fileVersionUploaded = value;
    }
    updateProfileFormChanged(value: boolean) {
        this.profileFormChanged = value;
    }

    updatePermission(value:any, param:string) {
        if(param==='taxSpecialism')
        this.permission.taxSpecialism = value;
        if(param==='country')
        this.permission.country = value;
        if(param==='firm')
        this.permission.firm = value;
        if(param==='user')
        this.permission.user = value;
        if(param==='access')
            this.permission.access = value;
        if(param==='status')
            this.permission.status = value;
        if(param==='description')
            this.permission.description = value;
        if(param==='name')
            this.permission.name = value;
    }

    updateFileVersionSelected(value: Item) {
        this.fileVersionSelected = value;
    }

    resetPrefixes(){
        this.prefixes=[]
    }
    resetItems(){
        this.items=[]
    }
    resetItemsVersions(){
        this.itemsFileVersionSelected=[]
    }
    resetItemsCutSelected(){
        this.itemsCutSelected=[]
    }
    resetPermission(){
            this.permission.taxSpecialism =[];
            this.permission.country = [];
            this.permission.firm = [];
            this.permission.user = [];
            this.permission.access = "";
            this.permission.status = "";
            this.permission.description = "";
            this.permission.name = "";
    }

    userExists(userId:string) {
        return this.users.some(user => user.id === userId );
    }
}


decorate(DataStore, {
    users: observable,
    deletedUsers: observable,
    firms: observable,
    selectedUser: observable,
    groupChats: observable,
    userRequests: observable,
    approvals: observable,
    countries: observable,
    countrySelected: observable,
    imageWordPressSelected: observable,
    wordpressUserId: observable,
    deletedUserWordpressId: observable,
    userInfo: observable,
    currentRoute: observable,
    files: observable,
    folders: observable,
    folderItems: observable,
    fileItems: observable,
    prefixes: observable,
    items: observable,
    itemsFileVersionSelected: observable,
    fileVersionSelected: observable,
    permission: observable,
    itemsCutSelected: observable,
    folderParentAccess:observable,
    fileVersionUploaded:observable,
    profileFormChanged:observable,

    updateCurrentRoute: action,
    updateUserInfo: action,
    updateUser: action,
    updateUsers: action,
    updateDeletedUsers: action,
    updateFirms: action,
    updateGroupChat: action,
    updateUserRequest: action,
    updateApprovals: action,
    updateCountries: action,
    updateCountrySelected: action,
    updateImageWordPressSelected: action,
    updateWordpressUserId: action,
    updateDeletedUserWordpressId: action,
    updateFolder: action,
    updateFiles: action,
    getUsers: computed,
    updateFolderItems: action,
    updateFileItems: action,
    updatePrefixes: action,
    updateItems: action,
    resetPrefixes: action,
    resetItems: action,
    resetItemsVersions: action,
    updateItemsFileVersionSelected: action,
    updateFileVersionSelected: action,
    updatePermission: action,
    updateItemsCutSelected:action,
    resetItemsCutSelected:action,
    updateFolderParentAccess:action,
    updateFileVersionUploaded:action,
    updateProfileFormChanged:action
});

export default new DataStore();
