import {inject, observer} from "mobx-react";
import React, {useCallback, useEffect, useState} from "react";
import {ReactComponent as Arrow} from "assets/documents/svg/arrow.svg";
import {Breadcrumb, Button, Empty, Input, Modal, notification, Spin} from "antd";
import {Link, useParams} from "react-router-dom";
import dataStore from "../../../../stores/dataStore";
import {Item, Prefixe, UserData} from "../../../../models/general";
import {deleteFile, deleteMultipleFileService, loadDocumentStorage,} from "helpers/DocumentHandlers";
import {
    loadFileMetadata,
    loadFileVersion,
    pasteItemsSelected,
    searchFile,
    uploadFileService,
    uploadFileVersionService,
} from "services/documents/DocumentDetailsServices";
import {Skeleton} from "@material-ui/lab";
import {IconArrowLeft, IconFilePlus, IconFolderPlus,} from "@tabler/icons";
import {FileDoneOutlined, LoadingOutlined, ReloadOutlined} from "@ant-design/icons";
import UploadFile from "../components/uploadFile/UploadFile";
import UpdateFileVersion from "../components/updateFileVersion/UpdateFileVersion";
import DocumentsItem from "./DocumentsItem";
import NewFolderItem from "./NewFolderItem";
import {Dropzone, FileWithPath} from "@mantine/dropzone";
import Search from "antd/es/input/Search";
import {Cancel} from "@material-ui/icons";
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import {API} from "../../../../api";
import {countryRef, taxSpecialismRef} from "../../../../services/firebase/firebase";
import {createBrowserHistory} from "history";
import {ROUTES} from "../../../../parameters/routes";

const antIcon = (
    <LoadingOutlined
        style={{fontSize: 24, color: "white", marginLeft: "5px"}}
        spin
    />
);

const DocumentDetails = () => {
    // Data file and folder from storage
    let prefixes: Prefixe[] = dataStore.prefixes;
    let items: Item[] = dataStore.items;
    const userData: UserData | undefined = dataStore.userInfo;
    const history = createBrowserHistory();
    const params: any = useParams();
    const [currentPath, setCurrentPath] = useState('/files/' + params['documentName']);
    const [parent, setParent] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [loadingFileVersion, setLoadingFileVersion] = useState(true);
    const [crumb, setCrumb] = useState<string[]>([]);
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [openUpdateFileVersion, setOpenUpdateFileVersion] = useState(false);
    const [folderName, setFolderName] = useState<any>('');
    const [checkFolderExistParam, setCheckFolderExistParam] = useState(false);
    const [fileVersion, setFileVersion] = useState();
    const [fileDownloadPermissions, setFileDownloadPermissions] = useState<boolean[]>([]);
    const [isNewFolder, setIsNewFolder] = useState<boolean>(false);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [filesWithPath, setFilesWithPath] = useState<FileWithPath[]>();
    const [openCutFile, setOpenCutFile] = useState(true);
    const [loadingPaste, setLoadingPaste] = useState(false);
    const permissionParentFolder = dataStore.folderParentAccess.access;
    const [currentUser, setCurrentUser] = useState<any>("");
    const [searchParam, setSearchParam] = useState('');
    const [dragOn, setDragOn] = useState<any>("");
    const [openEditFolderName, setOpenEditFolderName] = useState(false);
    const [editingFolder, setEditingFolder] = useState<Prefixe | Item | null>(null);
    const [loadingRename, setLoadingRename] = useState(false);

    const onSearch = async () => {
        if (searchParam) {
            setLoadingSearch(true);
            await searchFile(searchParam, currentPath);
            setLoadingSearch(false);
        } else {
            loadDocumentStorage(currentPath, setCurrentPath, setParent, params, setLoading);
        }
    };

    // Check if a folder with the same name already exists
    const checkFolderExistFunction = () => {
        setCheckFolderExistParam(false);
        const folder = prefixes.filter(p => p.name === folderName);
        if (folder.length > 0) {
            setCheckFolderExistParam(true);
        }
    };

    const getCurrentUser = useCallback(async () => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const uid = user.uid;
                const userInfo = await API.getUserInfo(uid as string);
                setCurrentUser(userInfo.right);
            }
        });
    }, []);

    const confirmDeleteFile = (e: any, file: Item | Prefixe) => {
        if (file.isDirectory) {
            deleteFile(e, file, currentPath, setCurrentPath, setParent, setLoading, params);
        } else {
            deleteMultipleFileService(file as any, currentPath, setCurrentPath, setParent, setLoading, params);
        }
    };

    const uploadFile = async (file: FileWithPath[] | undefined, path: string, version: string, downloadPermissions: boolean[], currentUser: string) => {
        try {
            setLoadingUpload(true);
            if (file !== undefined) {
                let i = 0; // Initialize the index counter

                for await (const f of file) {
                    // Convert the specific permission for each file to string format
                    const stringPermissions = [downloadPermissions[i].toString()];

                    await uploadFileService(f, path, version, stringPermissions);
                    i++;
                }
                loadDocumentStorage(currentPath, setCurrentPath, setParent, params, setLoading).then();
                setLoadingUpload(false);
                setOpenUploadFile(false);
                setFilesWithPath(undefined);
                setFileDownloadPermissions([...downloadPermissions]);
                console.log([...downloadPermissions]);
                notification["success"]({
                    message: "Success",
                    description: `Files uploaded successfully`,
                    style: {fontFamily: "ArialBD"},
                });
            }
        } catch (error) {
            notification["error"]({
                message: "Error",
                description: "Error when uploading file",
                style: {fontFamily: "ArialBD"},
            });
        }
    };

    const updateFileVersion = async (file: any, path: string) => {
        try {
            setLoadingUpload(true);
            await uploadFileVersionService(file, path).then(async (res) => {
                dataStore.updateFileVersionUploaded(true);
                await loadFileVersion(dataStore.fileVersionSelected, setLoadingFileVersion);
                if (!searchParam) {
                    await loadDocumentStorage(currentPath, setCurrentPath, setParent, params, setLoading);
                }
                dataStore.updateItems(dataStore.items.filter(ite => ite.fileUrl !== file.fileUrl));
                setLoadingUpload(false);
                setOpenUpdateFileVersion(false);
                setFileVersion(undefined);
                if (res) {
                    notification["success"]({
                        message: "Success",
                        description: `File uploaded successfully`,
                        style: {fontFamily: "ArialBD"},
                    });
                }
            });
        } catch (error) {
            notification["error"]({
                message: "Error",
                description: "Error when uploading file",
                style: {fontFamily: "ArialBD"},
            });
        }
    };

    const pastItems = () => {
        setOpenCutFile(true);
        try {
            pasteItemsSelected(currentPath).then(res => {
                loadDocumentStorage(currentPath, setCurrentPath, setParent, params, setLoading);
            });
            setLoadingPaste(false);
            notification["success"]({
                message: "Success",
                description: `File pasted successfully`,
                style: {fontFamily: "ArialBD"},
            });
        } catch (error) {
            notification["error"]({
                message: "Error",
                description: "Error when Pasting file",
                style: {fontFamily: "ArialBD"},
            });
        }
    };

    const getCountryById = (idCountry: string) => {
        let countryName;
        countryRef.on('value', async (snap) => {
            let result = snap.val();
            Object.keys(result).forEach(key => {
                if (key === idCountry) {
                    countryName = result[key];
                }
            });
        });
        return countryName;
    };

    const getTaxSpecialismById = (idTaxSpecialismName: string) => {
        let taxSpecialismName;
        taxSpecialismRef.on('value', async (snap) => {
            let result = snap.val();
            Object.keys(result).forEach(key => {
                if (key === idTaxSpecialismName) {
                    taxSpecialismName = result[key];
                }
            });
        });
        return taxSpecialismName;
    };

    // Function definition with passing two arrays
    function findCommonElement(array1: any[], array2: any[]) {
        // Loop for array1
        for (let i = 0; i < array1.length; i++) {
            // Loop for array2
            for (let j = 0; j < array2.length; j++) {
                // Compare the element of each and
                // every element from both of the
                // arrays
                if (array1[i] === array2[j]) {
                    // Return if common element found
                    return true;
                }
            }
        }
    }

    const handleNewFolder = () => {
        setIsNewFolder(true);
    };

    const handleChangeCountry = useCallback((value) => {
        let listCountry: any[] = [];
        if (!value.includes('')) {
            for (let country in value) {
                listCountry.push(getCountryById(value[country]));
            }
        }
        return listCountry;
    }, []);

    const handleChangeTaxSpecialism = useCallback((value) => {
        let listTaxSpecialism: any[] = [];
        if (!value.includes('')) {
            for (let taxSpecialism in value) {
                listTaxSpecialism.push(getTaxSpecialismById(value[taxSpecialism]));
            }
        }
        return listTaxSpecialism;
    }, []);

    async function handleAccess(folder: Prefixe) {
        let users: any[] = [];
        let firms: any[] = [];
        let taxSpecialisms: any[] = [];
        let countries: any[] = [];
        let status: any[] = [];
        let result: any[] = [];
        const metadataFolder = await loadFileMetadata("files/" + folder + "/fakeFile");
        if (userData?.right === "Global administrator") {
            result.push(true);
        }
        // if the current user is not a global administrator
        else {
            if (metadataFolder?.customMetadata?.status && typeof metadataFolder?.customMetadata?.status === 'string') {
                status = (metadataFolder?.customMetadata?.status as string).split(',');
                // if status is public
                if (status.includes('public'))
                    result.push(true);
                // if status is private
                else {
                    if (metadataFolder?.customMetadata?.users && typeof metadataFolder?.customMetadata?.users === 'string') {
                        users = (metadataFolder?.customMetadata?.users as string).split(',');
                        result.push(users.includes(userData?.id));
                    }
                    if (metadataFolder?.customMetadata?.country && typeof metadataFolder?.customMetadata?.country === 'string') {
                        countries = handleChangeCountry((metadataFolder?.customMetadata?.country as string).split(','));
                        result.push(countries.includes(userData?.country));
                    }
                    if (metadataFolder?.customMetadata?.taxSpecialism && typeof metadataFolder?.customMetadata?.taxSpecialism === 'string') {
                        taxSpecialisms = handleChangeTaxSpecialism((metadataFolder?.customMetadata?.taxSpecialism as string).split(','));
                        if (taxSpecialisms.length > 0 && userData?.taxSpecialisme !== undefined)
                            result.push(findCommonElement(taxSpecialisms, userData?.taxSpecialisme));
                    }
                    if (metadataFolder?.customMetadata?.firm && typeof metadataFolder?.customMetadata?.firm === 'string') {
                        firms = (metadataFolder?.customMetadata?.firm).split(',');
                        result.push(firms.includes((userData?.firm?.id)?.toString()));
                    } else
                        result.push(false);
                }
            }
        }
        return result.includes(true);
    }

    async function checkAccess() {
        try {
            const checkAccess = await handleAccess(params["documentName"]);
            if (checkAccess) {
                return;
            } else {
                notification["error"]({
                    message: "Error",
                    description: "Sorry, you don't have access to this page",
                    style: {fontFamily: 'ArialBD'},
                });
                history.push(ROUTES.documents);
            }
        } catch (e) {
            notification["error"]({
                message: "Error",
                description: "Error when creating folder",
                style: {fontFamily: 'ArialBD'},
            });
        }
    }

    useEffect(() => {
        loadDocumentStorage("", setCurrentPath, setParent, params, setLoading);
        getCurrentUser().then();
        checkAccess().then();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCrumb(currentPath.replace("/files", "").split("/"));
    window.scrollTo({top: 0, left: 0,});
  }, [currentPath]);

    const handleDragEnter = (e: any) => {
        setDragOn(e);
    };

    const handleDragLeave = (e: any) => {
        setDragOn("");
    };

    // Function to rename and edit a folder
    // This function renames a folder by copying its contents to a new folder with the new name and then deleting the old folder.
    const renameAndEditFolder = async (oldFolder: Prefixe, newName: string, oldFileUrl: string) => {
        try {
            // Ensure the user is authenticated
            const user = firebase.auth().currentUser;
            if (!user) {
                throw new Error("User is not authenticated");
            }

            const storageRef = firebase.storage().ref();
            const oldFolderPath = oldFileUrl;

            // Extract the parent folder path from the old folder path
            const parentFolderPath = oldFolderPath.substring(0, oldFolderPath.lastIndexOf('/'));
            const newFolderPath = `${parentFolderPath}/${newName}`;

            const oldFolderRef = storageRef.child(oldFolderPath);
            const newFolderRef = storageRef.child(newFolderPath);

            // Ensure the old folder path and new folder path are not the same
            if (oldFolderPath === newFolderPath) {
                throw new Error("Old folder path and new folder path are the same");
            }

            // Copy the contents from the old folder to the new folder
            await copyFolderContents(oldFolderRef, newFolderRef);
            // Delete the old folder
            await deleteFolderService(oldFolderPath);

            // Update the metadata of the new folder
            const fakeFileRef = newFolderRef.child('fakeFile');
            await fakeFileRef.updateMetadata({
                customMetadata: {
                    name: newName,
                    parent: oldFolder.parent || ""
                }
            });
            notification["success"]({
                message: "Success",
                description: `Folder renamed successfully from ${oldFolder.name} to ${newName}`,
                style: { fontFamily: "ArialBD" },
            });
        } catch (error) {
            notification["error"]({
                message: "Error",
                description: "Error when renaming folder",
                style: { fontFamily: "ArialBD" },
            });
            throw error;
        }
    };

    // Function to delete a folder and its contents
    // This function recursively deletes all files and subfolders within the specified folder.
    const deleteFolderService = async (folderPath: string) => {
        const folderRef = firebase.storage().ref().child(folderPath);
        const items = await folderRef.listAll();
        const deletePromises = [];

        // Delete all files in the folder
        for (const item of items.items) {
            deletePromises.push(item.delete());
        }

        // Recursively delete all subfolders
        for (const prefix of items.prefixes) {
            deletePromises.push(deleteFolderService(prefix.fullPath));
        }

        await Promise.all(deletePromises);
    };

    // Function to copy the contents of a folder from source to destination
    // This function copies all files and subfolders from the source folder to the destination folder.
    const copyFolderContents = async (sourceRef: firebase.storage.Reference, destRef: firebase.storage.Reference) => {
        const sourceItems = await sourceRef.listAll();
        const copyPromises = [];

        // Copy all files in the folder
        for (const item of sourceItems.items) {
            const copyPromise = copyFile(item, destRef.child(item.name));
            copyPromises.push(copyPromise);
        }

        // Recursively copy all subfolders
        for (const subFolder of sourceItems.prefixes) {
            const newSubFolderRef = destRef.child(subFolder.name);
            const copyPromise = copyFolderContents(subFolder, newSubFolderRef);
            copyPromises.push(copyPromise);
        }

        await Promise.all(copyPromises);
    };

    // Function to copy a file from source to destination
    // This function fetches the file as a blob and uploads it to the destination with the same metadata.
    const copyFile = async (sourceRef: firebase.storage.Reference, destRef: firebase.storage.Reference) => {
        const sourceUrl = await sourceRef.getDownloadURL();
        const blob = await fetchBlobFromUrl(sourceUrl);
        const metadata = await sourceRef.getMetadata();
        const customMetadata = metadata.customMetadata || {};
        await destRef.put(blob, {...metadata, customMetadata});
    };

    // Function to fetch the blob of a file from its URL
    // This function fetches the file data as a blob from the provided URL.
    const fetchBlobFromUrl = async (url: string) => {
        const response = await fetch(url);
        return response.blob();
    };

    // Function to delete the contents of a folder
    // This function deletes all files and subfolders within the specified folder.
    // eslint-disable-next-line
    const deleteFolderContents = async (folderRef: firebase.storage.Reference) => {
        const items = await folderRef.listAll();
        const deletePromises = [];

        // Delete all files in the folder
        for (const item of items.items) {
            deletePromises.push(item.delete());
        }

        // Recursively delete all subfolders
        for (const prefix of items.prefixes) {
            deletePromises.push(deleteFolderContents(prefix));
        }

        await Promise.all(deletePromises);
    };

    // Function to handle editing a folder name
    // This function renames a folder and updates the UI to reflect the new name.
    const handleEditFolderName = () => {
        if (editingFolder && editingFolder.name !== folderName) {
            // Extract the original folder name from `fileUrl`.
            const oldFolderName = editingFolder.fileUrl.split('/').pop();

            if (editingFolder.isDirectory) {
                setLoadingRename(true);
                renameAndEditFolder({
                    ...editingFolder,
                    name: oldFolderName
                } as Prefixe, editingFolder.name, editingFolder.fileUrl)
                    .then(() => {
                        setFolderName(folderName);
                        setOpenEditFolderName(false);
                        loadDocumentStorage(currentPath, setCurrentPath, setParent, params, setLoading);
                    })
                    .catch(error => {
                        console.error("Error renaming folder:", error);
                    })
                    .finally(() => {
                        setLoadingRename(false);
                    });
            } else {
                console.error("The folder being edited is not of type Prefixe");
            }
        }
    };

    return (
        <div>
            <div className="documents" onDragEnter={e => handleDragEnter(e)} onDragLeave={(e) => handleDragLeave(e)}
                 style={dragOn !== "" ? {zIndex: -1} : {zIndex: 1}}>
                <div className="actions-header-container">
                    {!searchParam ? (
                        <div className="actions-header">
                            <Link to="/documents">
                                <IconArrowLeft className="back-arrow"/>
                            </Link>
                            <Breadcrumb>
                                {crumb.map((value, index) => {
                                    return (
                                        <Breadcrumb.Item key={index}>
                                            {index === 0 ? (
                                                <Link to="/documents">
                                                    {value === "" ? "Documents" : value}
                                                </Link>
                                            ) : (
                                                `${value}`
                                            )}
                                        </Breadcrumb.Item>
                                    );
                                })}
                            </Breadcrumb>
                        </div>
                    ) : <div className="actions-header"></div>}
                    <div className="documents-actions">
                        {!openCutFile && (permissionParentFolder === "Full access" || currentUser === "Global administrator") ? (
                            <Button
                                style={{marginRight: "5px"}}
                                type="primary"
                                onClick={() => pastItems()}
                                className="action-button"
                            >
                                <Spin spinning={loadingPaste} indicator={antIcon}/>
                                <FileDoneOutlined/> Paste
                            </Button>
                        ) : null}

                        {!openCutFile && (permissionParentFolder === "Full access" || currentUser === "Global administrator") ? (
                            <Button
                                style={{marginRight: "5px"}}
                                type="primary"
                                onClick={() => {
                                    setOpenCutFile(true);
                                    dataStore.resetItemsCutSelected();
                                }}
                                className="action-button"
                            >
                                <Cancel/>
                                Cancel cut
                            </Button>
                        ) : null}

                        <Button
                            style={{marginRight: "5px"}}
                            onClick={() => {
                                setSearchParam("");
                                loadDocumentStorage(currentPath, setCurrentPath, setParent, params, setLoading);
                            }}
                        >
                            Refresh
                            <ReloadOutlined/>
                        </Button>
                        <Search
                            placeholder="search"
                            onSearch={() => onSearch()}
                            onChange={(e) => setSearchParam(e.target.value)}
                            enterButton
                            value={searchParam}
                            loading={loadingSearch}
                        />
                        {permissionParentFolder === "Full access" || currentUser === "Global administrator" ? (
                            <>
                                <Button
                                    type="primary"
                                    onClick={handleNewFolder}
                                    className="action-button"
                                >
                                    <IconFolderPlus/> New folder
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => setOpenUploadFile(true)}
                                    className="action-button"
                                >
                                    <IconFilePlus/> New file
                                </Button>
                            </>
                        ) : null}
                    </div>
                </div>
                {!openUploadFile && !openUpdateFileVersion && (
                    <Dropzone
                        style={dragOn !== "" ? {zIndex: 1} : {zIndex: -1}}
                        className={'dropzone'}
                        onClickCapture={(e) => e.stopPropagation()}
                        dragEventsBubbling={false}
                        onDragEnter={e => handleDragEnter(e)} onDragLeave={(e) => handleDragLeave(e)}
                        onDrop={(files) => {
                            setDragOn("");
                            setFilesWithPath([...(filesWithPath || []), ...files]);
                            uploadFile(files, currentPath, "1", fileDownloadPermissions, currentUser).then();
                        }}
                        onReject={(files) => console.log("rejected files", files)}
                    >
                    </Dropzone>
                )}
                {loading && (
                    <div className="documents-container">
                        <Skeleton
                            style={{marginRight: "20px"}}
                            width={"20%"}
                            height={150}
                        />
                        <Skeleton
                            style={{marginRight: "20px"}}
                            width={"20%"}
                            height={150}
                        />
                        <Skeleton
                            style={{marginRight: "20px"}}
                            width={"20%"}
                            height={150}
                        />
                    </div>
                )}
                {!loading && (
                    <div className="documents-container">
                        {currentPath !== "/files/" + params["documentName"] || currentPath === "" ? (
                            <div
                                onClick={() => loadDocumentStorage(parent, setCurrentPath, setParent, params, setLoading)}
                                className="item-folder folder"
                                style={{width: "10%"}}
                            >
                                <Arrow/>
                                <span className="item-title">Back</span>
                            </div>
                        ) : null}
                        {prefixes.length === 0 && items.length === 0 && !isNewFolder ? (
                            <div style={{width: "100%", height: "100%", marginTop: "100px"}}>
                                <Empty/>
                            </div>
                        ) : null}
                        {isNewFolder && (
                            <NewFolderItem
                                checkFolderExistFunction={checkFolderExistFunction}
                                setCheckFolderExistParam={setCheckFolderExistParam}
                                setFolderName={setFolderName}
                                folderName={folderName}
                                checkFolderExistParam={checkFolderExistParam}
                                setIsNewFolder={setIsNewFolder}
                                loadingSave={loadingSave}
                                setLoadingSave={setLoadingSave}
                                currentPath={currentPath}
                                setCurrentPath={setCurrentPath}
                                setParent={setParent}
                                params={params}
                            />
                        )}
                        {prefixes.map(function (prefix, i) {
                            return (
                                <DocumentsItem
                                    type="folder"
                                    key={Math.random()}
                                    prefix={prefix}
                                    currentPath={currentPath}
                                    setCurrentPath={setCurrentPath}
                                    setParent={setParent}
                                    params={params}
                                    setLoading={setLoading}
                                    setLoadingFileVersion={setLoadingFileVersion}
                                    loadFileVersion={loadFileVersion}
                                    setOpenCutFile={setOpenCutFile}
                                    openCutFile={openCutFile}
                                    setOpenUpdateFileVersion={setOpenUpdateFileVersion}
                                    confirmDeleteFile={confirmDeleteFile}
                                    permission={permissionParentFolder}
                                    currentUser={currentUser}
                                    setOpenEditFolderName={setOpenEditFolderName}
                                    setEditingFolder={setEditingFolder}
                                />
                            );
                        })}
                        {items.map(function (item, i) {
                            return (
                                <DocumentsItem
                                    type="file"
                                    key={Math.random()}
                                    prefix={item}
                                    currentPath={currentPath}
                                    setCurrentPath={setCurrentPath}
                                    setParent={setParent}
                                    params={params}
                                    setLoading={setLoading}
                                    setLoadingFileVersion={setLoadingFileVersion}
                                    loadFileVersion={loadFileVersion}
                                    setOpenCutFile={setOpenCutFile}
                                    openCutFile={openCutFile}
                                    setOpenUpdateFileVersion={setOpenUpdateFileVersion}
                                    confirmDeleteFile={confirmDeleteFile}
                                    permission={permissionParentFolder}
                                    currentUser={currentUser}
                                    setOpenEditFolderName={setOpenEditFolderName}
                                    setEditingFolder={setEditingFolder}
                                />
                            )
                        })}
                    </div>
                )}
                <Modal
                    visible={openUploadFile}
                    title="Upload File"
                    onCancel={() => {
                        setOpenUploadFile(false);
                        setFilesWithPath([]);
                    }}
                    destroyOnClose
                    maskClosable={false}
                    footer={[
                        <Button
                            key="back"
                            onClick={() => {
                                setOpenUploadFile(false);
                                setFilesWithPath([]);
                            }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            disabled={!filesWithPath}
                            onClick={() => uploadFile(filesWithPath, currentPath, "1", fileDownloadPermissions, currentUser)}
                            key="submit"
                            type="primary"
                        >
                            Confirm
                            <Spin spinning={loadingUpload} indicator={antIcon}/>
                        </Button>,
                    ]}
                >
                    <UploadFile
                        filesWithPath={filesWithPath}
                        setFilesWithPath={setFilesWithPath}
                        setDownloadPermissions={setFileDownloadPermissions}
                        setCurrentUser={currentUser}
                    />
                </Modal>
                <Modal
                    visible={openUpdateFileVersion}
                    title="File versions"
                    onCancel={() => {
                        setOpenUpdateFileVersion(false);
                    }}
                    destroyOnClose
                    maskClosable={false}
                    footer={[
                        <Button
                            key="back"
                            onClick={() => {
                                setOpenUpdateFileVersion(false);
                            }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            onClick={() => updateFileVersion(fileVersion, dataStore.fileVersionSelected.parent)}
                            key="submit"
                            type="primary"
                        >
                            Update version
                            <Spin spinning={loadingUpload} indicator={antIcon}/>
                        </Button>,
                    ]}
                >
                    <UpdateFileVersion
                        loadingFileVersion={loadingFileVersion}
                        fileVersion={fileVersion}
                        setFileVersion={setFileVersion}
                    />
                </Modal>

                <Modal
                    visible={openEditFolderName}
                    title="Edit Folder Name"
                    onCancel={() => setOpenEditFolderName(false)}
                    footer={[
                        <Button key="cancel" onClick={() => setOpenEditFolderName(false)}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            onClick={handleEditFolderName}
                            disabled={loadingRename}
                        >
                            OK
                            <Spin spinning={loadingRename} indicator={antIcon}/>
                        </Button>,
                    ]}
                >
                    <Input
                        value={editingFolder?.name}
                        onChange={(e) => {
                            if (editingFolder) {
                                setEditingFolder({...editingFolder, name: e.target.value});
                            }
                        }}
                    />
                </Modal>

            </div>
        </div>
    );
};

export default inject("authStore", "dataStore", "messageStore")(observer(DocumentDetails));
