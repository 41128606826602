import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Calendar, momentLocalizer, SlotInfo} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import {Button, DatePicker, Divider, Input, Modal, notification, Popconfirm, Select, Spin, Table, Tooltip} from 'antd';
import AddEvent from './AddEvent/AddEvent';
import {CalenderEvent} from '../../../models/calenderEvent';
import {ReactComponent as EventIcon} from 'assets/event-calender/svg/event.svg';
import './Events.scss';
import {Category} from '../../../models/category';
//import firebase from '../../../services/firebase/firebase';
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import {API} from '../../../api';
import {useTranslation} from 'react-i18next';
import {useEventContext} from "../EventContext";
import {LoadingOutlined, QuestionCircleOutlined} from "@ant-design/icons";

const loadingIcon = <LoadingOutlined style={{fontSize: 24, color: "white", marginLeft: '5px'}}/>

// Set up the momentLocalizer to format dates for the calendar
const localizer = momentLocalizer(moment);

const Events = () => {
    // Using React Hooks to manage component state and context
    const {dispatch} = useEventContext();
    const {Search} = Input;
    const {t} = useTranslation();
    const {RangePicker} = DatePicker;

    // State variables to manage various aspects of the component
    const [calenderEvents, setCalenderEvents] = useState<CalenderEvent[]>([]);
    const [loading, setLoading] = React.useState(true); // Loading for creating and updating events
    const [loadingDelete, setLoadingDelete] = React.useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [newEvent, setNewEvent] = useState<CalenderEvent>({
        id: '',
        title: '',
        description: '',
        start: null,
        end: null,
        access: 'Full access',
        category: '',
        allDay: false,
        createdAt: null,
        modifiedAt: null,
        isInCountdown: false
    });
    const [updateEvent, setUpdateEvent] = useState<CalenderEvent | null>(null);
    const [searchEvent, setSearchEvent] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All categories');
    const [currentUser, setCurrentUser] = useState<any>("");
    const [categories, setCategories] = useState<Category[]>([
        {
            id: '',
            name: 'All categories'
        }
    ]);
    const [token, setToken] = useState('');
    const popup = calenderEvents.filter(e => {
        if (e.start) {
            let startDate = new Date(e.start);
            return startDate.getDay() === new Date().getDay();
        }
        return false;
    }).length > 2;
    // State to toggle all events view
    const [showAllEvents, setShowAllEvents] = useState(false);
    // Customize the selected date range format
    const dateFormat = 'YYYY-MM-DD';
    const [calendarDate, setCalendarDate] = useState<Date | undefined>(new Date());
    // Define a new state for holding the selected event ID for filtering.
    const [selectedEventIdForFilter, setSelectedEventIdForFilter] = useState<string | null>(null);
    const [selectedDates, setSelectedDates] = useState<{ startDate: string | null, endDate: string | null }>({
        startDate: null,
        endDate: null
    });
    const [rangePickerValue, setRangePickerValue] = useState<any>(null);


    // Get the current user information
    const getCurrentUser = useCallback(async () => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const token = await user.getIdToken();
                setToken(token);
                var uid = user.uid;
                const userInfo = await API.getUserInfo(uid as string);
                setCurrentUser(userInfo);
            }
        });
    }, []);

    // Handle slot selection on the calendar
    const handleSlotSelect = (slotInfo: SlotInfo) => {
        // Check if the user has the Global administrator right to create events
        if (currentUser.right === "Global administrator") {
            const {start} = slotInfo;
            // Open the event creation modal
            setIsModalOpen(true);
            setLoading(false);
            setLoadingDelete(false);
            // Disable update mode
            setIsUpdateMode(false);
            // Clear any previous update event
            setUpdateEvent(null);

            // Set createdAt and modifiedAt when initializing newEvent
            const createdAt = new Date();
            setNewEvent({
                id: '', // Event ID (empty for now)
                title: '', // Event title
                description: '', // Event description
                start: start, // Start date and time
                end: start, // End date and time (initially set to the same as start for one-off events)
                access: 'Full access', // Event access level
                category: '', // Event category
                allDay: true, // Mark the event as an all-day event
                createdAt: createdAt, // Set createdAt to the current date
                modifiedAt: createdAt, // Set modifiedAt to the current date
                isInCountdown: false // Set the event as not included in the countdown
            });
        } else {
            notification['warning']({
                message: 'Warning',
                description: t('You don\'t have the permission to create an event.'),
            });
        }
    };

    // Handle event selection
    const handleEventSelect = (event: CalenderEvent) => {
        setIsModalOpen(true);
        setLoading(false);
        setLoadingDelete(false);
        setIsUpdateMode(true);
        setUpdateEvent(event);
        setNewEvent(event);
        if (currentUser.right !== "Global administrator" && event.access === "Limited") {
            notification['warning']({
                message: 'Warning',
                description: t('This is an event with limited access. It cannot be updated or deleted.'),
            });
        }
    };

    // Fetch all categories using the API
    const getAllCategories = useCallback(async () => {
        try {
            const allCategories = await API.getAllCategories();
            setCategories([
                {
                    id: '',
                    name: 'All categories'
                },
                ...allCategories
            ]);
        } catch (error) {
            setCategories([]);
        }
    }, []);

    // Fetch all events using the API
    const getAllEvents = useCallback(async () => {
        try {
            const allEvents = await API.getAllEvents();
            setCalenderEvents(allEvents);
        } catch (error) {
            setCalenderEvents([]);
        }
    }, []);

    // Function to check if the Create event is enabled
    const isCreateEventEnabled = useCallback(() => {
        return newEvent.title && newEvent.start && newEvent.end && newEvent.category;
    }, [newEvent.title, newEvent.start, newEvent.end, newEvent.category]);

    // Function to check if the event properties are updated
    const areEventsEqual = (oldEvent: CalenderEvent, updatedEvent: CalenderEvent) => {
        return (
            oldEvent.title === updatedEvent.title &&
            oldEvent.description === updatedEvent.description &&
            oldEvent.start === updatedEvent.start &&
            oldEvent.end === updatedEvent.end &&
            oldEvent.category === updatedEvent.category &&
            oldEvent.access === updatedEvent.access &&
            oldEvent.isInCountdown === updatedEvent.isInCountdown
        );
    };

    const handleCloseCreateCard = useCallback(async () => {
        setIsModalOpen(false);

        // Refresh the calendar by fetching all events
        await getAllEvents();

        // Set createdAt and modifiedAt when resetting newEvent
        const createdAt = new Date();
        setNewEvent({
            id: '',
            title: '',
            description: '',
            start: null,
            end: null,
            access: 'Full access',
            category: '',
            allDay: false,
            createdAt: createdAt,
            modifiedAt: createdAt,
            isInCountdown: false
        });

        // Reset the update event state
        setUpdateEvent(null);
    }, [setIsModalOpen, getAllEvents, setNewEvent, setUpdateEvent]);

    // Adjust your filteredEvents to take the selected event ID into account.
    const filteredTableEvents = useMemo(() => {
        return calenderEvents.filter(event => {
            const categoryMatch = selectedCategory === 'All categories' || event.category === selectedCategory;
            const eventMatch = !selectedEventIdForFilter || event.id === selectedEventIdForFilter;
            const dateMatch = !selectedDates.startDate
                || (moment(event.start).isBetween(selectedDates.startDate, selectedDates.endDate, null, '[]')
                    && moment(event.end).isBetween(selectedDates.startDate, selectedDates.endDate, null, '[]'));
            return categoryMatch && dateMatch && eventMatch;
        });
    }, [calenderEvents, selectedCategory, selectedDates, selectedEventIdForFilter]);

    // Handle event creation or update
    const handleEventCreate = useCallback(async () => {
        setLoading(true);

        try {
            if (isCreateEventEnabled()) {
                const startDate = moment(newEvent.start).format('YYYY-MM-DD HH:mm:ss').toString();
                const endDate = moment(newEvent.end).format('YYYY-MM-DD HH:mm:ss').toString();

                if (isUpdateMode && updateEvent) {
                    const modifiedAt = moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString();
                    const updatedEvent = {
                        ...updateEvent,
                        ...newEvent,
                        isInCountdown: newEvent.isInCountdown,
                        start: startDate,
                        end: endDate,
                        modifiedAt: modifiedAt,
                    };

                    // Optimistic update: Update UI immediately
                    const updatedEvents = calenderEvents.map((event) =>
                        event.id === updateEvent.id ? updatedEvent : event
                    );
                    setCalenderEvents(updatedEvents);

                    const isUpdateSuccessful = await API.updateEvent(updatedEvent, updateEvent.id);
                    if (isUpdateSuccessful) {
                        dispatch({ type: 'UPDATE_EVENT', payload: updatedEvent });
                        notification['success']({
                            message: 'Success',
                            description: t('Event successfully updated'),
                        });
                    } else {
                        // Rollback the UI changes on failure
                        setCalenderEvents(calenderEvents);
                        notification['error']({
                            message: 'Error',
                            description: t('Failed to update event'),
                        });
                    }
                } else {
                    const newEventId = await API.getEventId();
                    const createdAt = moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString();
                    const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/createEventCalendar`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            id: newEventId,
                            title: newEvent.title,
                            description: newEvent.description,
                            start: startDate,
                            end: endDate,
                            access: newEvent.access,
                            category: newEvent.category,
                            allDay: newEvent.allDay,
                            createdAt: createdAt,
                            modifiedAt: createdAt,
                            callType: '',
                            isInCountdown: newEvent.isInCountdown
                        })
                    });

                    if (response.ok) {
                        const responseData = await response.json();
                        const completeEvent = {
                            ...newEvent,
                            id: responseData.event_res,
                            createdAt: createdAt,
                            modifiedAt: createdAt,
                        };
                        setCalenderEvents([...calenderEvents, completeEvent]);
                        dispatch({ type: 'ADD_EVENT', payload: completeEvent });
                        notification['success']({
                            message: 'Success',
                            description: t('Event successfully added'),
                        });
                    } else {
                        notification['error']({
                            message: 'Error',
                            description: t('Failed to add an event'),
                        });
                    }
                }
            } else {
                // If event creation is not enabled
                notification['warning']({
                    message: 'Warning',
                    description: t('Event creation is not enabled'),
                });
            }
            // After creating or updating an event, fetch all events to refresh the data
            await getAllEvents();
        } catch (error) {
            console.error('Error creating/updating event:', error);
        } finally {
            setLoading(false);
            handleCloseCreateCard();
        }
    }, [calenderEvents, isUpdateMode, newEvent, updateEvent, isCreateEventEnabled, t, dispatch, handleCloseCreateCard,token,getAllEvents]);

    // Handle event deletion
    const handleDeleteEvent = useCallback(async (eventId: string) => {
        setLoadingDelete(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteEventCalender`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    uid: eventId
                })
            });

            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.error);
            }

            // Optimistic update: Remove the deleted event from the UI
            const updatedEvents = calenderEvents.filter((event) => event.id !== eventId);
            setCalenderEvents(updatedEvents);

            dispatch({type: 'DELETE_EVENT', payload: eventId});
            notification['success']({
                message: 'Success',
                description: t('Event successfully deleted')
            });
            // After deleting an event, fetch all events to refresh the data
            await getAllEvents();
        } catch (error) {
            notification['error']({
                message: 'Error',
                description: t('Failed to delete event')
            });
        } finally {
            setLoadingDelete(false);
            handleCloseCreateCard();
        }
    }, [calenderEvents, t, handleCloseCreateCard, dispatch, token,getAllEvents]);

    // Customize the displayed events in the calendar
    const EventComponent: React.FC<{ event: CalenderEvent }> = ({event}) => (
        <div key={event.id}>
            <p className="event-title">{event.title}</p>
            <p className="event-date">
                {moment(event.start).format('LT')} - {moment(event.end).format('LT')}
            </p>
        </div>
    );

    // Function to toggle all events view
    const toggleAllEventsView = () => {
        setShowAllEvents(!showAllEvents);
        if (!showAllEvents) { // When going back to the all events view, clear the selected event filter
            setSelectedEventIdForFilter(null);
        }
    };

    // When you select an event to view in the calendar, set the selected event ID for filtering.
    const selectEvent = (eventId: string) => {
        // Find the event to focus on
        const eventToFocus = calenderEvents.find(event => event.id === eventId);
        if (eventToFocus && eventToFocus.start) {
            setCalendarDate(new Date(eventToFocus.start)); // Set the calendar date to the event's date
            setSelectedEventIdForFilter(eventId); // Set the selected event ID for filtering
            setShowAllEvents(false); // Switch to the calendar view
            // Optional: Scroll to the calendar view if it's on the same page
            setTimeout(() => {
                const calendarElement = document.getElementById('calendar');
                if (calendarElement) {
                    calendarElement.scrollIntoView({behavior: 'smooth'});
                }
            }, 0);
        }
    };
    // Render a list of all events
    const renderAllEvents = () => {
        // Define the columns for the table
        const columns = [
            {
                title: 'Event',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Category',
                dataIndex: 'category',
                key: 'category',
            },
            {
                title: 'Start Date',
                dataIndex: 'start',
                key: 'start',
                render: (text: string) => moment(text).format('LL'),
            },
            {
                title: 'End Date',
                dataIndex: 'end',
                key: 'end',
                render: (text: string) => moment(text).format('LL'),
            },
            {
                title: 'Time',
                key: 'time',
                render: (_: any, record: CalenderEvent) => {
                    // If the start and end times are the same, it's an all-day event
                    // Otherwise, show the specific start and end times
                    const startTime = moment(record.start);
                    const endTime = moment(record.end);
                    return startTime.isSame(endTime, 'day') ? 'All day' : `${startTime.format('LT')} - ${endTime.format('LT')}`;
                }
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                render: (text: string) => (
                    <Tooltip title={text}>
                        {text.length > 30 ? `${text.substring(0, 30)}...` : text}
                    </Tooltip>
                ),
            },
            {
                title: 'Status',
                key: 'status',
                render: (_: any, record: CalenderEvent) => {
                    const isPast = moment().isAfter(record.end);
                    const statusText = isPast ? 'Past' : 'Upcoming';
                    const statusColor = isPast ? '#CE6A6B' : '#4A919E';
                    const style: React.CSSProperties = {
                        backgroundColor: statusColor,
                        color: 'white',
                        padding: '4px 12px',
                        borderRadius: '12px',
                        fontWeight: '500',
                        boxShadow: '4px 1px 17px -3px rgba(72, 72, 72, 0.27)',
                        textAlign: 'center',
                    };
                    return <span style={style}>{statusText}</span>;
                },
            },
            {
                title: 'Action',
                key: 'action',
                render: (_: any, record: CalenderEvent) => {
                    return (
                        <span className="button_go_calender" onClick={() => selectEvent(record.id)}>
                            <Tooltip title="View in Calendar">
                                <svg width="25px" height="25px" viewBox="0 0 16 16" version="1.1"
                                     className="si-glyph si-glyph-forward-page">
                                    <defs></defs>
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g fill="#434343">
                                            <path
                                                d="M15.798,3.516 L11.892,0.266 C11.615,-0.022 11.299,-0.069 11.021,0.218 C11.021,0.218 11.058,2.408 11.027,2.408 C10.347,2.408 9.944,2.521 9.327,2.722 C8.505,2.993 7.755,3.428 7.119,3.99 C5.843,5.113 5.026,6.392 5.026,8.203 C5.026,9.385 5.549,8.955 5.768,8.542 C6.803,6.597 8.691,5.614 11.049,5.614 C11.079,5.614 11.048,7.76 11.048,7.76 C11.326,8.047 11.676,8.013 11.917,7.807 L15.798,4.557 C16.076,4.268 16.076,3.804 15.798,3.516 L15.798,3.516 Z"
                                                className="si-glyph-fill">

                                            </path>
                                            <path
                                                d="M14,8.079 L14,13.062 L0.938,13.062 L0.938,3.937 L6.153,3.937 C6.429,3.625 6.774,3.22 7.376,3.018 L0.00800000003,3.018 L0.00800000003,13.982 L14.97,13.982 L14.97,7.258 L14,8.079 L14,8.079 Z"
                                                className="si-glyph-fill">

                                            </path>
                                        </g>
                                    </g>
                                </svg>
                            </Tooltip>
                        </span>
                    );
                },
            }
        ];
        return (
            <Table columns={columns} dataSource={filteredTableEvents}/>
        );
    };

    // This function is responsible for resetting various pieces of state within the Events component to their initial values.
    const resetToInitialState = () => {
        setSelectedEventIdForFilter(null);
        setSearchEvent('');
        setCalendarDate(new Date());
        setSelectedCategory('All categories');
        setSelectedDates({startDate: null, endDate: null});
        setRangePickerValue(null);
        getAllEvents();
    };


    useEffect(() => {
        // Fetch all categories and events when the component mounts
        getAllCategories();
        getAllEvents();
        getCurrentUser().then();
    }, [getAllCategories, getAllEvents, getCurrentUser]);

    return (
        <div>
            <h3 style={{fontWeight: 'bold'}}>Upcoming events</h3>
            <div className="filters-bar">
                <Search
                    placeholder="Search..."
                    size="large"
                    value={searchEvent}
                    enterButton
                    onChange={(e) => {
                        setSearchEvent(e.target.value);
                        setCalenderEvents(filteredTableEvents.filter((event) =>
                            event.title?.toLowerCase().includes(e.target.value?.toLowerCase())));
                        if (e.target.value === "") {
                            getAllEvents();
                        }
                    }}
                    style={{width: '280px', marginBottom: '20px'}}
                />
                <Divider type="vertical"/>
                <RangePicker
                    size="large"
                    style={{ marginBottom: '20px' }}
                    format={dateFormat}
                    value={rangePickerValue}
                    onChange={(value) => {
                        setRangePickerValue(value);
                        if (value !== null) {
                            const startDate = moment(value[0]).format(dateFormat);
                            const endDate = moment(value[1]).format(dateFormat);
                            setSelectedDates({ startDate: startDate, endDate: endDate });
                            const filteredEvents = calenderEvents.filter((event) => {
                                const eventStartDate = moment(event.start).format(dateFormat);
                                const eventEndDate = moment(event.end).format(dateFormat);
                                return (
                                    (eventStartDate >= startDate && eventStartDate <= endDate) ||
                                    (eventEndDate >= startDate && eventEndDate <= endDate)
                                );
                            });
                            setCalenderEvents(filteredEvents);
                        } else {
                            setSelectedDates({ startDate: null, endDate: null });
                            setCalenderEvents([]);
                        }
                    }}
                />
                <Divider type="vertical"/>
                <Select
                    size="large"
                    value={selectedCategory}
                    onChange={(value) => setSelectedCategory(value)}
                    style={{width: '200px', marginBottom: '20px'}}
                >
                    {categories.map(({id, name}) => (
                        <Select.Option key={`${id}-${name}`} value={name}>
                            {name}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <span className={"d-flex"}>
                <button className="checkbox_event" onClick={toggleAllEventsView} type="button">
                    <span
                        className="button__text_event">{showAllEvents ? 'Show Calendar' : 'Show all events as a list'}</span>
                </button>
                <button className="checkbox_event" onClick={resetToInitialState} type="button">
                    <span className="button__text">Reset Views</span>
                    <span className="button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="48"
                                                        viewBox="0 0 48 48" height="48"
                                                        className="svg"><path
                        d="M35.3 12.7c-2.89-2.9-6.88-4.7-11.3-4.7-8.84 0-15.98 7.16-15.98 16s7.14 16 15.98 16c7.45 0 13.69-5.1 15.46-12h-4.16c-1.65 4.66-6.07 8-11.3 8-6.63 0-12-5.37-12-12s5.37-12 12-12c3.31 0 6.28 1.38 8.45 3.55l-6.45 6.45h14v-14l-4.7 4.7z"></path><path
                        fill="none" d="M0 0h48v48h-48z"></path></svg></span>
                </button>
            </span>
            <br/>
            <div style={{height: '500px'}}>
                <div style={{height: '500px'}} id="calendar">
                    {showAllEvents ? (
                        renderAllEvents()
                    ) : (
                        <Calendar
                            localizer={localizer}
                            events={filteredTableEvents}
                            startAccessor="start"
                            endAccessor="end"
                            style={{marginBottom: '20px'}}
                            onSelectEvent={handleEventSelect}
                            selectable={true}
                            onSelectSlot={handleSlotSelect}
                            popup={popup}
                            components={{
                                event: EventComponent,
                            }}
                            date={calendarDate} // Control the displayed date
                            onNavigate={(newDate) => setCalendarDate(newDate)} // Update state when navigating
                        />
                    )}
                </div>

            </div>
            <Modal
                visible={isModalOpen}
                title={
                    <div className="create-folder-modal-header">
                        <EventIcon/>
                        {isUpdateMode ? 'Update Event' : 'Create Event'}
                    </div>
                }
                onCancel={handleCloseCreateCard}
                destroyOnClose
                maskClosable={false}
                footer={[
                    <Popconfirm
                        key={newEvent.id}
                        title="Are you sure you want to delete this event?"
                        icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                        onConfirm={() => handleDeleteEvent(newEvent.id)}
                        disabled={currentUser.right !== "Global administrator" && updateEvent?.access === "Limited"}
                    >
                        <Button
                            key="delete"
                            type="danger"
                            hidden={!isUpdateMode}
                            disabled={currentUser.right !== "Global administrator" && updateEvent?.access === "Limited"}
                            style={{position: 'absolute', left: '20px'}}
                        >
                            Delete
                            <Spin spinning={loadingDelete} indicator={loadingIcon}/>
                        </Button>
                    </Popconfirm>,
                    <Button
                        key="back"
                        onClick={handleCloseCreateCard}
                    >
                        Return
                    </Button>,
                    <Button
                        onClick={handleEventCreate}
                        key="submit"
                        type="primary"
                        disabled={!isCreateEventEnabled() ||
                        (isUpdateMode && currentUser.right !== "Global administrator" && updateEvent?.access === "Limited") ||
                        (isUpdateMode && areEventsEqual(newEvent, updateEvent!))
                        }
                    >
                        {isUpdateMode ? 'Update' : 'Create'}
                        <Spin spinning={loading} indicator={loadingIcon}/>
                    </Button>,
                ]}
            >
                <AddEvent isUpdateMode={isUpdateMode}
                          currentUser={currentUser}
                          categoriesFilter={categories}
                          newEvent={newEvent}
                          setNewEvent={setNewEvent}
                />
            </Modal>
        </div>
    );
};

export default Events;
