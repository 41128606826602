import React, {FormEvent, useEffect, useState} from "react";
// import svg
import {ReactComponent as OpenSvg} from "assets/documents/svg/open.svg";
import {ReactComponent as EditSvg} from "assets/documents/svg/settings.svg";
import {ReactComponent as FolderSvg} from "assets/documents/svg/folder.svg";
import {Button, Modal, notification, Popconfirm, Spin, Tooltip, Progress} from "antd";
import {Prefixe, PrefixePermission} from "../../../../models/general";
import {LoadingOutlined} from "@ant-design/icons";
import {deleteCard, loadCardStorage, loadFolderParentAccess} from "../../../../helpers/DocumentHandlers";
import {DeleteOutline} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {ReactComponent as FolderIcon} from "../../../../assets/documents/svg/folderIcon.svg";
import EditFolderWithPermission from "../components/editFolderWithPermission/EditFolder";
import dataStore from "../../../../stores/dataStore";
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import {inject, observer} from "mobx-react";
import {deleteFolderService} from "../../../../services/documents/DocumentDetailsServices";

function DocumentItem({currentUser, prefixeData, firmIds, userIds}: PrefixePermission) {
    const [, setFirmPermission] = React.useState("");
    const [, setUserPermission] = React.useState("");
    const [openEditCard, setOpenEditCard] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [currentPrefixe, setCurrentPrefixe] = React.useState({});
    const antIcon = <LoadingOutlined style={{fontSize: 24, color: "#1f2553 ", marginLeft: '5px'}} spin/>;
    const [path] = useState('/files/');
    const [, setDeleteLoading] = React.useState(false)
    const [showMore, setShowMore] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [initialName, setInitialName] = useState<string>(prefixeData.permission?.name);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setInitialName(prefixeData.permission?.name);
        setCurrentPrefixe(prefixeData);
        prefixeData.permission?.firm.then((res: any) => {
            setFirmPermission(res.join(", "));
        })
        prefixeData.permission?.user?.then((res: string[]) => {
            setUserPermission(res.join(", "));
        })// eslint-disable-next-line
        setIsMounted(true); // Track if component is mounted

        return () => {
            setIsMounted(false); // Set to false on unmount
        };
    }, [prefixeData]);

    // Handles deletion of a folder
    const confirmDeleteCard = async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent> | undefined, prefix: Prefixe) => {
        setDeleteLoading(true)
        await deleteCard(e, prefix, prefix.parent, () => {
            if (isMounted) setDeleteLoading(false);
        }, setProgress);
    }
    // Handles opening the edit folder modal
    const handleOpenEditCard = () => {
        setOpenEditCard(true);
    };
    // Handles closing the edit folder modal
    const handleCloseEditCard = () => {
        setOpenEditCard(false);
        setLoading(false);
        setProgress(0);
    };

    // Asynchronously fetches a blob from a given URL.
    // The 'url' parameter is a string that represents the URL from which to fetch the blob.
    async function fetchBlobFromUrl(url: string) {
        // Await the fetch call to resolve, retrieving the response from the URL.
        const response = await fetch(url);
        // Return the blob from the response once it is available.
        return response.blob();
    }

    // Asynchronously copies a file from a source reference to a destination reference within Firebase Storage.
    // 'sourceRef' is the reference to the source file in Firebase Storage.
    // 'destRef' is the reference to the destination where the file will be copied to in Firebase Storage.
    async function copyFile(sourceRef: firebase.storage.Reference, destRef: firebase.storage.Reference) {
        // Retrieves the download URL for the source file.
        const sourceUrl = await sourceRef.getDownloadURL();
        // Fetches the blob of the source file using the download URL.
        const blob = await fetchBlobFromUrl(sourceUrl);
        // Retrieves the metadata of the source file.
        const metadata = await sourceRef.getMetadata();

        // Ensures that customMetadata is a valid object. If 'customMetadata' does not exist, default to an empty object.
        const customMetadata = metadata.customMetadata || {};

        // Uses the put() method to upload the blob to the destination reference in Firebase Storage.
        // The upload includes both the metadata and customMetadata.
        await destRef.put(blob, {...metadata, customMetadata});
    }

    /* Asynchronously copies the contents of a folder from a source reference to a destination reference within Firebase Storage.
     'sourceRef' is the reference to the source folder in Firebase Storage.
    'destRef' is the reference to the destination folder where the contents will be copied to in Firebase Storage.*/
    async function copyFolderContents(sourceRef: firebase.storage.Reference, destRef: firebase.storage.Reference) {
        // List all items (files) and prefixes (subfolders) in the source reference.
        const sourceItems = await sourceRef.listAll();
        const copyPromises = []; // This array will hold promises for each copy operation.

        // Copy each file from the source to the destination.
        for (const item of sourceItems.items) {
            const copyPromise = copyFile(item, destRef.child(item.name));
            copyPromises.push(copyPromise);
        }
        // Recursively copy subfolders.
        for (const subFolder of sourceItems.prefixes) {
            const newSubFolderRef = destRef.child(subFolder.name);
            // Check if the subfolder is "empty" (contains only a 'fakeFile').
            const subFolderItems = await subFolder.listAll();
            if (subFolderItems.items.length === 1 && subFolderItems.items[0].name === 'fakeFile' && subFolderItems.prefixes.length === 0) {
                // If the subfolder is "empty", copy the 'fakeFile' to maintain structure.
                const fakeFileBlob = new Blob([''], { type: 'application/octet-stream' });
                const fakeFilePromise = newSubFolderRef.child('fakeFile').put(fakeFileBlob);
                copyPromises.push(fakeFilePromise);
            } else {
                // If the subfolder contains other files or subfolders, copy its contents recursively.
                const copyPromise = copyFolderContents(subFolder, newSubFolderRef);
                copyPromises.push(copyPromise);
                // Add a 'fakeFile' to maintain the folder structure in Firebase Storage.
                const fakeFileBlob = new Blob([''], { type: 'application/octet-stream' });
                copyPromises.push(newSubFolderRef.child('fakeFile').put(fakeFileBlob));
            }
        }

        // If the current folder is "empty" (contains only a 'fakeFile'), create a 'fakeFile' in the destination folder.
        if (sourceItems.items.length === 1 && sourceItems.items[0].name === 'fakeFile' && sourceItems.prefixes.length === 0) {
            const fakeFileBlob = new Blob([''], { type: 'application/octet-stream' });
            const fakeFilePromise = destRef.child('fakeFile').put(fakeFileBlob);
            copyPromises.push(fakeFilePromise);
        }

        // Wait for all copy operations to complete.
        await Promise.all(copyPromises);
    }

    // Asynchronously renames a folder within Firebase Storage by copying its contents to a new folder
    // with the desired name and then deleting the old folder.
    // 'oldFolder' is the current folder to be renamed.
    // 'oldName' is the new name that the folder should have after the operation.
    async function renameAndEditFolder(oldFolder:Prefixe, newName:string) {
        try {
            // Reference to the root of the Firebase Storage bucket
            const storageRef = firebase.storage().ref();
            // Constructing the full path for the old and new folder locations
            const oldFolderPath = `files/${oldFolder.name}`;
            const newFolderPath = `files/${newName}`;
            // References to the old and new folders
            const oldFolderRef = storageRef.child(oldFolderPath);
            const newFolderRef = storageRef.child(newFolderPath);
            // Copy the contents of the old folder to the new folder, including any subfolders
            await copyFolderContents(oldFolderRef, newFolderRef);
            // Delete the old folder and all of its contents after successful copy
            await deleteFolderService(oldFolder.fileUrl);
        } catch (error) {
            // Log any errors that occur during the rename operation
            // Rethrow the error for further handling if necessary
            throw error;
        }
    }

    /*Edit folder for example (Folders Documents,Images......) function:begin  */

    const updateProgress = (completed: number, total: number) => {
        const percentage = Math.round((completed / total) * 100);
        setProgress(percentage);
    };

    const editCard = async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        setLoading(true);
        setProgress(0);

        const firmDataStore = dataStore.permission.firm.length;
        const userDataStore = dataStore.permission.user.length;
        const statusDataStore = dataStore.permission.status;
        const accessDataStore = dataStore.permission.access;
        const descriptionDataStore = dataStore.permission.description;
        const nameDataStore = dataStore.permission.name;

        const tasks = [
            async () => {
                if (nameDataStore && nameDataStore !== initialName) {
                    await renameAndEditFolder(prefixeData, dataStore.permission.name);
                    prefixeData.name = dataStore.permission.name;
                    await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                        .updateMetadata({ customMetadata: { name: nameDataStore.toString() } });
                }
            },
            async () => descriptionDataStore &&
                await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                    .updateMetadata({ customMetadata: { description: descriptionDataStore.toString() } }),
            async () => statusDataStore &&
                await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                    .updateMetadata({ customMetadata: { status: statusDataStore.toString() } }),
            async () => accessDataStore &&
                await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                    .updateMetadata({ customMetadata: { access: accessDataStore.toString() } }),
        ];

        try {
            for (let i = 0; i < tasks.length; i++) {
                await tasks[i]();
                if (isMounted) updateProgress(i + 1, tasks.length); // Update progress after each task
            }

            if (statusDataStore === 'public') {
                await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                    .updateMetadata({customMetadata: {firm: ''}})
                await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                    .updateMetadata({customMetadata: {taxSpecialism: ''}})
                await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                    .updateMetadata({customMetadata: {country: ''}})
                await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                    .updateMetadata({customMetadata: {users: ''}})
            }

            if (statusDataStore === 'private') {
                if (firmDataStore > 0)
                    await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                        .updateMetadata({customMetadata: {firm: (dataStore.permission.firm).toString()}})
                else
                    await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                        .updateMetadata({customMetadata: {firm: ''}})

                if (userDataStore > 0)
                    await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                        .updateMetadata({customMetadata: {users: (dataStore.permission.user).toString()}})
                else
                    await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                        .updateMetadata({customMetadata: {users: ''}})
                if ((dataStore.permission.taxSpecialism).length > 0) {
                    await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                        .updateMetadata({customMetadata: {taxSpecialism: (dataStore.permission.taxSpecialism).toString()}})
                } else {
                    await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                        .updateMetadata({customMetadata: {taxSpecialism: ''}})
                }

                if ((dataStore.permission.country).length > 0) {
                    await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                        .updateMetadata({customMetadata: {country: (dataStore.permission.country).toString()}})
                } else {
                    await firebase.storage().ref(`/files/` + prefixeData.name + `/fakeFile`)
                        .updateMetadata({customMetadata: {country: ''}})
                }
            }

            await loadCardStorage(path, setLoading, setProgress);
            setLoading(false);
            handleCloseEditCard();
            setProgress(100); // Complete progress
            notification["success"]({
                message: "Success",
                description: `Folder data has been changed successfully`,
                style: {fontFamily: 'ArialBD'},
            });
        } catch (e:any) {
            notification["error"]({
                message: "Error",
                description: `Error when creating folder: ${e.message}`,
                style: {fontFamily: 'ArialBD'},
            });
        } finally {
            if (isMounted) setLoading(false); // Ensure setLoading is called only if component is mounted
        }
    }

    return (
        <div>
            <div className="document-item-header-container">
                <div className="document-item-header">
                    <Link to={'/document/' + prefixeData.name} onClick={() => {
                        loadFolderParentAccess(prefixeData.name)
                    }}>
                        <div className="document-item-header-title">
                            <FolderSvg/>
                            <span className="title">{prefixeData.name} </span>
                        </div>
                    </Link>
                    <div className="document-item-actions">
                        {currentUser === "Global administrator" ? (
                            <div className="action-icon">
                                <Popconfirm
                                    overlayStyle={{zIndex: 10003}}
                                    title="Are you sure to delete this Folder?"
                                    onConfirm={(e) => confirmDeleteCard(e, prefixeData)}
                                >
                                    <div>
                                        <Tooltip title="Delete">
                                            <DeleteOutline/>
                                        </Tooltip>
                                    </div>
                                </Popconfirm>
                            </div>
                        ) : (
                            ""
                        )}
                        {currentUser === "Global administrator" ? (
                            <div className="action-icon">
                                <div
                                    onClick={handleOpenEditCard}
                                ><Tooltip title="Update">
                                    <EditSvg/>
                                </Tooltip>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="action-icon">
                            <Link to={'/document/' + prefixeData.name} onClick={() => {
                                loadFolderParentAccess(prefixeData.name).then();
                            }}>
                                <Tooltip title="Open details">
                                    <OpenSvg/>
                                </Tooltip>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="document-item-body-container">
                <br/>
                {
                    prefixeData.permission?.description ? (
                            <div>

                                <div
                                    className={"description"}>
                                    <span className={"titleDescription"}>Description</span>
                                    <br/>
                                    {showMore ? prefixeData.permission?.description : `${prefixeData.permission?.description.substring(0, 200)}`}
                                    {prefixeData.permission?.description.length > 200 &&
                                    <span onClick={() => setShowMore(!showMore)}>{showMore ?
                                        <p className={"showText"}>Show less</p> :
                                        <p className={"showText"}>... Show more</p>}</span>}
                                </div>
                            </div>)
                        :
                        null
                }
                {/* {currentUser === "Global administrator" ? (
              <div className="document-item-body-permissions">
                <div className="document-item-body-permissions-title">
                  <div>
                    <IconShieldLock />
                    <span>Permissions</span>
                  </div>
                  <div className={"document-item-body-permissions-title-access"}>
                    {prefixeData.permission?.status==='public'? <div style={{display:"flex",alignItems:'center'}}><UnlockOutlined /><span>Public</span></div>: null}
                    {prefixeData.permission?.status==='private'? <div style={{display:"flex",alignItems:'center'}}><LockOutlined /> <span>Private</span></div>: null}
                  </div>

                </div>
                <div className="document-item-body-permissions-list" style={{marginTop : "10px"}}>
                  <Accordion
                      mx="auto"
                      variant="contained"
                  >
                    {prefixeData.permission?.status==='private'? <Accordion.Item value="Firm">
                      <Accordion.Control>Firm</Accordion.Control>
                      <Accordion.Panel>
                        {firmPermission}
                      </Accordion.Panel>
                    </Accordion.Item>:<></>}
                    {prefixeData.permission?.status==='private'?<Accordion.Item value="TaxSpecialism">
                      <Accordion.Control>Tax Specialism</Accordion.Control>
                      <Accordion.Panel>
                        {prefixeData.permission?.taxSpecialism.join(", ")}
                      </Accordion.Panel>
                    </Accordion.Item>:<></>}
                    {prefixeData.permission?.status==='private'?<Accordion.Item value="Country">
                      <Accordion.Control>Country</Accordion.Control>
                      <Accordion.Panel>
                        {prefixeData.permission?.country.join(", ")}
                      </Accordion.Panel>
                    </Accordion.Item>:<></>}
                    {prefixeData.permission?.status==='private'?<Accordion.Item value="Users">
                      <Accordion.Control>Users</Accordion.Control>
                      <Accordion.Panel>
                        {userPermission}
                      </Accordion.Panel>
                    </Accordion.Item>:<></>}
                    <Accordion.Item value="Access">
                      <Accordion.Control>Access</Accordion.Control>
                      <Accordion.Panel>
                        {prefixeData.permission?.access}
                      </Accordion.Panel>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
          ) : (
              ""
          )}*/}
            </div>
            {/*</Link>*/}

            {/* Create Folder Modal:begin */}
            <Modal
                visible={openEditCard}
                title={
                    <div className="create-folder-modal-header">
                        <FolderIcon/>
                        Edit Folder
                    </div>
                }
                onCancel={handleCloseEditCard}
                destroyOnClose
                footer={[
                    <Button key="back" onClick={() => {
                        handleCloseEditCard()
                    }}>
                        Return
                    </Button>,
                    <>
                        <Button
                            //disabled={!openEmptyDatastorePermission}
                            disabled={loading}
                            onClick={(e) => editCard(e)}
                            key="submit"
                            type="primary"
                        >
                            Edit
                            <Spin spinning={loading} indicator={antIcon}/>
                        </Button>
                        {loading && (
                            <Progress
                                percent={progress}  // Adjust the percentage dynamically if needed
                                status="active"
                                showInfo={false}
                                style={{ marginTop: '12px' }}
                            />
                        )}
                    </>,

                ]}
            >
                <div
                    style={{
                        opacity: loading ? 0.5 : 1,
                        cursor: loading ? 'wait' : 'default',
                        pointerEvents: loading ? 'none' : 'auto',
                    }}
                >
                    <EditFolderWithPermission
                        prefixe={currentPrefixe}
                        listFirmIds={firmIds}
                        listUserIds={userIds}
                    />
                </div>
            </Modal>
            {/* Create Folder Modal:end */}
        </div>
    );
}

export default inject("authStore", "dataStore", "messageStore")(observer(DocumentItem));
