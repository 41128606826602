import React, {useEffect, useState} from 'react';
import './NotFound.scss';
import { ReactComponent as NotFoundImage } from "../../../assets/404.svg";
import { useHistory } from 'react-router-dom';
import firebase from "firebase/app";


const NotFound = () => {
    const history = useHistory();
    const [passwordUpdated, setPasswordUpdated] = useState(false);

    useEffect(() => {
        const checkPasswordUpdate = async () => {
            const user = firebase.auth().currentUser;
            if (user) {
                const userRef = firebase.database().ref('users/' + user.uid);
                userRef.on('value', (snapshot) => {
                    if (snapshot.val().passwordUpdated) {
                        setPasswordUpdated(true);
                    }
                });
            }
        };

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                checkPasswordUpdate();
            }
        });
    }, []);

    const handleGoBack = () => {
        if (passwordUpdated) {
            history.push('/login');
        } else {
            history.goBack();
        }
    };

    return (
        <>
            <section className="notfound">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <NotFoundImage />
                        </div>
                        <div className="col-md-6 align-self-center">
                            <h1>404</h1>
                            <h2>You're lost.</h2>
                            <p>The page you are looking for does not exist.
                                How you got here is a mystery. But you can click the button below
                                to go back.
                            </p>
                            <button onClick={handleGoBack} className="btn blue">Go Back</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default NotFound;