import React, {FormEvent, useCallback, useEffect, useMemo, useState} from "react";
import {Paper, TextField} from "@material-ui/core";
import styles from "./AddUserTaxand.module.scss"
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {areaOfIndustryExpertiseRef, countryRef, firmRef, gradeRef, rightsRef, taxSpecialismRef, usersRef} from "../../../../services/firebase/firebase";
import dataStore from "../../../../stores/dataStore";
import {API} from "../../../../api";
import { UserOutlined , CloudUploadOutlined } from '@ant-design/icons';
import {Avatar, Tooltip} from 'antd';
import ContactInfo from './ContactInfo'
import AssistantInfo from "./AssistantInfo"
import AdministrationInfo from "./AdministrationInfo"

import PeopleInfo from "./PeopleInfo"
import {Modal , Button , notification  } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import messageStore from "../../../../stores/messageStore";
import {DeleteOutlined} from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


interface AddUserTaxandProps {
    data : any ,
    setUsernameVerified :React.Dispatch<React.SetStateAction<boolean>>,
    setEmailVerified : React.Dispatch<React.SetStateAction<boolean>>, 
    setDeletedUsernameVerified : React.Dispatch<React.SetStateAction<boolean>> , 
    setData : React.Dispatch<React.SetStateAction<any>>,
    usernameVerified : boolean , 
    emailVerified : boolean ,
    emailValid:boolean,
    setEmailValid:React.Dispatch<React.SetStateAction<boolean>>,
  canSubmit : any,
    isVisible : boolean ,
    setIsVisible : React.Dispatch<React.SetStateAction<boolean>>,
    grade : any ,
    setGrade : React.Dispatch<React.SetStateAction<any>>,
    country : any ,
    setCountry : React.Dispatch<React.SetStateAction<any>>,
    areaOfIndustryExpertise : any ,
    setAreaOfIndustryExpertise : React.Dispatch<React.SetStateAction<any>>,
    taxSpecialism : any,
    setTaxSpecialism : React.Dispatch<React.SetStateAction<any>>,
    fileChanged :boolean ,
    setFileChanged : React.Dispatch<React.SetStateAction<boolean>>,
    right : string ,
    setRight : React.Dispatch<React.SetStateAction<string>>,
    firm : any ,
    setFirm : React.Dispatch<React.SetStateAction<string>>,
    assistant : any,
    setAssistant : React.Dispatch<React.SetStateAction<any>>,
    assistants : any,
    setAssistants : React.Dispatch<React.SetStateAction<any>>,
    getCountryId : (value:string) => void ,
    setfile1 : React.Dispatch<React.SetStateAction<any>>
    action : "Add" | "Edit",
    setAction : React.Dispatch<React.SetStateAction<any>>,
    file1 ?: any
    openNewUser?:boolean,
    pictureDeleted:boolean,
    setPictureDeleted:React.Dispatch<React.SetStateAction<boolean>>}

const AddUserTaxand = ({
  data,
  setUsernameVerified,
  setEmailVerified,
  setDeletedUsernameVerified,
  setData,
  usernameVerified,
  emailVerified,
  canSubmit,
  isVisible,
  setIsVisible,
  grade,
  setGrade,
  country,
  setCountry,
  areaOfIndustryExpertise,
  setAreaOfIndustryExpertise,
  taxSpecialism,
  setTaxSpecialism,
  fileChanged,
  setFileChanged,
  right,
  setRight,
  firm,
  setFirm,
  assistant,
  setAssistant,
  getCountryId,
  setfile1,
  action,
  assistants,
  setAssistants, pictureDeleted,setPictureDeleted,
  setAction,file1,emailValid,setEmailValid,openNewUser
}: AddUserTaxandProps) => {
  //Props

  //Style
  const [ContentLoader, setContentLoader] = useState(true);

  const { t } = useTranslation();

  //Submit Working State

  //Avatar State
  const [preview, setPreview] = useState<string | undefined>();

  // Data Load Field State
  const [firms, setFirms] = React.useState<any>([]);
  const [grades, setGrades] = React.useState<any>([]);
  const [listAreaOfIndustryExpertise, setListAreaOfIndustryExpertise] =
    React.useState<string[]>([]);
  const [listTaxSpecialism, setListTaxSpecialism] = React.useState<string[]>(
    []
  );
  const [rights, setRights] = useState([]);

  //   User Date Field
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [passwordVal, setPasswordValidation] = useState({
    displayVal: "none",
    uppercase: false,
    lowercase: false,
    specialChar: false,
    numeral: false,
    minchar: false,
    maxchar: true,
    valid: false
  });
  const [isValidPasswordAgain, setIsValidPasswordAgain] = useState(true);

  // Add New Area State
  const [openNewArea, setOpenNewArea] = React.useState(false);
  const [inputValue, setInputValue] = useState("");

  // List Countries Store
  const listCountries = dataStore.countries;
  const updatedUserData = {
    email: data.email,
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    country: data.country,
    firm: data.firm,
    areaOfIndustryExpertise: data.areaOfIndustryExpertise,
    grade: data.grade,
    taxSpecialisme: data.taxSpecialisme,
    phone: data.phone,
    mobile: data.mobile,
    deleted: data.deleted,
    avatarUrl: data.avatarUrl,
    personnalBiography: data.personnalBiography,
    profesionnalBiography: data.profesionnalBiography,
    linkedInProfileLink: data.linkedInProfileLink,
    twitterProfileLink: data.twitterProfileLink,
    assistantName: data.assistantName,
    assistantEmail: data.assistantEmail,
    assistantTelephone: data.assistantTelephone,
    assistantMobile: data.assistantMobile,
    wordpress_id: data.wordpress_id,
    right: data.right,
    isVisible: data.isVisible,
  }



  // Submit Condition canSubmit,shortPassword

  const canSubmitArea = useMemo(() => inputValue !== "", [inputValue]);

  const checkEmail = () => {
    setEmailVerified(true);
    setEmailValid(true);

    let users = dataStore.users;
    for (let user in users) {
      if (users[user].deleted === false) {
        let userEmail = users[user].email;
        if (userEmail && userEmail.toLowerCase() === data.email.toLowerCase()) {
          setEmailVerified(false);
        }
      }
    }

    if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)){
      setEmailValid(false);
    }
  };

  // Generate Random Password
  const generatePassword = () => {
    const length = Math.floor(Math.random() * (20 - 8 + 1)) + 8; // Random length between 8 and 20,
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+={[}]|<?/";
    let retVal = "";

    // At least one of each character type
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const specialChars = '!@#$%^&*()_-+={[}]|<?/';

    retVal += lowercase.charAt(Math.floor(Math.random() * lowercase.length));
    retVal += uppercase.charAt(Math.floor(Math.random() * uppercase.length));
    retVal += numbers.charAt(Math.floor(Math.random() * numbers.length));
    retVal += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

    for (let i = 4; i < length; i++) {
      retVal += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    // Shuffle the password characters
    retVal = retVal.split('').sort(() => Math.random() - 0.5).join('');

    setData((d:any) => ({...d, generatedPass: retVal}));
  };

  const [checkUserCountryWithFirmCountryActive,setCheckUserCountryWithFirmCountryActive]=useState(true);

  const checkUpdatedUsername = async () => {
    const usernameDataRow = updatedUserData.firstName + " " + updatedUserData.lastName;
    const users = dataStore.users.filter(u=>u.id.toString() !== updatedUserData.id.toString())
    const userCheck = users.filter(user=>((user.firstName+" "+user.lastName).toLocaleLowerCase().trim() === usernameDataRow.toLocaleLowerCase().trim()))
    if(userCheck.length > 0){
      setUsernameVerified(false);
    }
    else {
      setUsernameVerified(true)
    }
  };

  const checkUserCountryWithFirmCountry= async ()=> {
    let firmCountries = firm?.country?.split(',').map((c:string)=>c.trim())
    if(firmCountries?.includes(country) || !country || !firm){
      setCheckUserCountryWithFirmCountryActive(true)
    }
    else {
      setCheckUserCountryWithFirmCountryActive(false)
    }
  }
  useEffect(()=>{
    checkUserCountryWithFirmCountry()
  },[country,firm]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkUsername = async () => {
    const usernameData = data.firstName + " " + data.lastName;
    const users = dataStore.users
    const userCheck = users.filter(user=>((user.firstName+" "+user.lastName).toLocaleLowerCase() === usernameData.toLocaleLowerCase()))
    if(userCheck.length > 0){
      setUsernameVerified(false);
    }
    else {
      setUsernameVerified(true)
    }
  };


  const checkDeletedUsername = () => {
    setDeletedUsernameVerified(true);
    usersRef.on("value", async (snapshot: any) => {
      let users = snapshot.val();
      for (let user in users) {
        const detailedUser = await API.getUserInfo(user);
        if (detailedUser.deleted) {
          var usernameDetailedUser =
            detailedUser.firstName + " " + detailedUser.lastName;
          var usernameData = data.firstName + " " + data.lastName;
          if (
            usernameDetailedUser.toLocaleLowerCase() ===
            usernameData.toLocaleLowerCase()
          ) {
            setDeletedUsernameVerified(false);
            dataStore.updateDeletedUserWordpressId(detailedUser.wordpress_id);
          }
        }
      }
    });
  };
  // Load Data User
  const loadDataUsers = useCallback(async () => {
    try {
      API.getAllUserInfo().then(res => {
        dataStore.updateUsers(res);
      })
    } catch (error) {
      messageStore.snackbar({
        message: t("Failed fetching users"),
        type: "error",
      });
    }
  }, [t]);// eslint-disable-line react-hooks/exhaustive-deps




  // Handle Change Field in WebForm Model
  const handleDataChange =
    ({
      currentTarget,
    }:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>) => {


      setData((d: any) => ({ ...d, [currentTarget.name]: currentTarget.value }));

      if (currentTarget.name === "password") {

        const password = currentTarget.value;
        const isValidLength = password.length >= 8 && password.length <= 20;
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);

        setIsValidPasswordAgain(currentTarget.value === data.againPassword);
        setIsValidPassword(isValidLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar);
        setPasswordValidation({
          displayVal: "block",
          uppercase: hasUppercase,
          lowercase: hasLowercase,
          specialChar: hasSpecialChar,
          numeral: hasNumber,
          minchar: isValidLength,
          maxchar: password.length <= 20 && password.length !== 0,
          valid: isValidLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar
        });
      }
      if (currentTarget.name === "againPassword") {
        setIsValidPasswordAgain(currentTarget.value === data.password);
      }
    }



  const toggleVisible = useCallback(() => {
    setIsVisible((v) => !v);

  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeGrade = useCallback((value) => {
    // setData((d: any) => ({ ...d, grade: value }));
    setGrade(value as any);
  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeCountry = useCallback((value) => {
    setCountry(value as any);
    getCountryId(value as any);
  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeAreaOfIndustryExpertise = useCallback((value) => {
    setAreaOfIndustryExpertise(value);

  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeTaxSpecialism = useCallback((value) => {
    setTaxSpecialism(value);

  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeFile = useCallback((event) => {
    // Clear any existing file
    setPreview('');
    setFileChanged(false);
    // Check if file is selected
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileType = file.type;
      // Check if the file is a PNG or JPG
      if (fileType === 'image/png' || fileType === 'image/jpeg') {
        const objectUrl = URL.createObjectURL(event.target.files[0]);
        setPreview(objectUrl);
        //eslint-disable-line react-hooks/exhaustive-deps
        setfile1(event.target.files[0]);
        // Update avatarUrl in data with the objectUrl
        setData((d: any) => ({ ...d, avatarUrl: objectUrl }));
        setFileChanged(true);
        setPictureDeleted(false);
      } else {
        // Display error message if file is not PNG or JPG
        notification["error"]({
          message: "Unsupported File Format",
          description: "Please choose an image file with a .png or .jpg extension. Other file formats are not supported for upload.",
        });
        event.target.value = '';
      }
    }
  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = useCallback((value) => {
    setRight(value as string);
  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  const handleChangefirm = async (value : any) => {
    const searchedFirm = await firms.find((e : any) => e.id === value) ; 
    setFirm(searchedFirm);
  }
  const handleChangeAssistant = useCallback((event: React.ChangeEvent<{ value: any }>) => {
    setAssistant(event.target.value as any)
    data.assistantEmail=event.target.value.email
    data.assistantName=event.target.value.firstName+ " " +event.target.value.lastName
    data.assistantMobile=event.target.value.mobile
    data.assistantTelephone=event.target.value.phone
  }, [data,setAssistant]);


  const handleClick = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setInputValue(event.target.value as string);
    },
    []//eslint-disable-line react-hooks/exhaustive-deps
  );
  const handleSubmitArea = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
    },
    []//eslint-disable-line react-hooks/exhaustive-deps
  );

  // New Area Of Expertise

  async function addNewAreas(inputValue: string) {
    if (inputValue !== "") {
      await API.addAreaOfExpertise(inputValue);
      notification["success"]({
        message: "Success",
        description: t("New Value successfully added"),
      });
      handleClose();
    }
  }

  const handleOpen = () => {
    setOpenNewArea(true);
  };

  const handleClose = () => {
    setOpenNewArea(false);
  };

  // Load Data Field

  const loadDataRights = () => {
    rightsRef.on("value", (snap) => {
      let rightsList: any[] = [];
      snap.forEach((s) => {
        rightsList.push(s.val());
      });
      setRights(rightsList as any);
      setContentLoader(false);
    });
  };
  const loadDataFirms = () => {
    firmRef.on("value", async (snap) => {
      let firmsList: any[] = [];
      let firms = snap.val();
      for (let f in firms) {
        const detailedFirm = await API.getFirmInfo(f);

        if (!detailedFirm.deleted) {
          firmsList.push(detailedFirm);
        }
      }
      setFirms(firmsList);
      dataStore.updateFirms(firmsList);
    });
  };

  const loadDataAssistant = async () => {
    usersRef.on("value", async (snap) => {
      let AssistantList: any[] = [];
      let users = snap.val();
      for (let user in users) {
        const detailedUser = await API.getUserInfo(user);
        if (!detailedUser.deleted) {
          if (
            (detailedUser.right === "Firm administrator" ||
              detailedUser.right === "Assistant") &&
            detailedUser.country === country
          ) {
            AssistantList.push(detailedUser);
          }
        }
      }
      setAssistants(AssistantList);
    });
  };

  const loadDataGrade = () => {
    gradeRef.on("value", (snap) => {
      let result = snap.val();
      result.sort((a:string, b:string) => a.toLowerCase().localeCompare(b.toLowerCase()))
      setGrades(result);
    });
  };

  const loadDataCountry = () => {
    countryRef.on("value", async (snap) => {
      let newListCountry: any[] = [];
      let result = snap.val();
      for (let country in result) {
        const detailedCountry = await API.getCountryInfo(country);
        newListCountry.push(detailedCountry);
      }
      dataStore.updateCountries(newListCountry);
    });
  };

  const loadDataAreaOfIndustryExpertise = async () => {
    areaOfIndustryExpertiseRef.on("value", async (snap) => {
      let areaOfIndustryExpertiseList: string[] = [];
      let areaOfIndustryExpertise = snap.val();
      for (let f in areaOfIndustryExpertise) {
        const detailedAreaOfExpertise = await API.getAreaOfExpertiseInfo(f);
        areaOfIndustryExpertiseList.push(detailedAreaOfExpertise);
      }
      areaOfIndustryExpertiseList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      setListAreaOfIndustryExpertise(areaOfIndustryExpertiseList);
    });
  };

  const loadDataTaxSpecialism = () => {
    taxSpecialismRef.on("value", (snap) => {
      let result = snap.val();
      result.sort((a:string, b:string) => a.toLowerCase().localeCompare(b.toLowerCase()))
      setListTaxSpecialism(result);
    });
  };
  const checkGlobalUserName=()=>{
    action === "Add" ? checkUsername() : checkUpdatedUsername();
    checkDeletedUsername();
    if(action==="Add" && openNewUser){
      setCountry("")
      setFirm('')
    }
  }

  useEffect(()=>{
    checkGlobalUserName()
  },[])// eslint-disable-line react-hooks/exhaustive-deps

  // First Loading Components
  useEffect(() => {
    setUsernameVerified(true)
    loadDataUsers()
    loadDataFirms();
    loadDataGrade();
    loadDataCountry();
    loadDataAreaOfIndustryExpertise();
    loadDataTaxSpecialism();
    loadDataRights();
    setAction(action);
    getCountryId(updatedUserData.country)
  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadDataAssistant()
  }, [country]);// eslint-disable-line react-hooks/exhaustive-deps

  const handleRemove = () => {
    setPreview(undefined);
    setPictureDeleted(true);
  };

  useEffect(() => {
    if(action === 'Edit' && data.avatarUrl !== undefined && data.avatarUrl !==""){
      setPreview(data.avatarUrl);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (ContentLoader) {
    return (
      <div
        className="UserModal"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    );
  } else {
    return (
      <div className="UserModal">
        <div className="ProfileHeader flexHeader">
          <label  htmlFor="upload">
          <Avatar
            size={80}
            icon={preview === undefined || preview === '' ? <UserOutlined /> : undefined}
            style={{ boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)" , cursor: 'pointer'}}
            src={preview}
          />
          </label>
          <label className="UploadFileContainer"  htmlFor="upload">
            <div className="UploadFile">
              <CloudUploadOutlined />
              <span>Upload your image</span>
            </div>
            <span className={"d-flex"}>
            <Tooltip title="For the photo fits perfectly in the circle, the width and height of the photo must be as close as possible.">
              <InfoIcon/>
            </Tooltip>
           Only PNG or JPG files are valid .</span>
          </label>
          <DeleteOutlined className={"UploadContainerAdd"} onClick={handleRemove}/>
          <input
              type="file"
              onChange={(e) => handleChangeFile(e)}
              id="upload"
              accept="image/png, image/jpeg"
            />
        </div>
        <div className="Body">
          <h2 className="ContactTitle">Contact</h2>
          <ContactInfo
            checkUserName={checkGlobalUserName}
            listCountries={listCountries}
            handleChangeCountry={handleChangeCountry}
            data={data}
            handleDataChange={handleDataChange}
            usernameVerified={usernameVerified}
            emailVerified={emailVerified}
            canSubmit={canSubmit}
            isValidPassword={isValidPassword}
            passwordVal={passwordVal}
            isValidPasswordAgain={isValidPasswordAgain}
            action={action}
            country={country}
            checkEmail={checkEmail}
            emailValid={emailValid}
            checkUserCountryWithFirmCountryActive={checkUserCountryWithFirmCountryActive}
            generatePassword={generatePassword}
          />
          <h2 className="ContactTitle">Executive Assistant Contact Details</h2>
          <AssistantInfo
            assistant={assistant}
            assistants={assistants}
            handleChangeAssistant={
              handleChangeAssistant
            }
            data={data}
            handleDataChange={
              handleDataChange
            }
            action="Edit"
          />
          <h2 className="ContactTitle">People information</h2>
          <PeopleInfo
            handleChangefirm={handleChangefirm}
            handleChangeGrade={handleChangeGrade}
            handleDataChange={
              handleDataChange
            }
            firms={firms}
            firm={firm}
            grades={grades}
            grade={grade}
            handleChangeAreaOfIndustryExpertise={
              handleChangeAreaOfIndustryExpertise
            }
            handleChangeTaxSpecialism={handleChangeTaxSpecialism}
            listAreaOfIndustryExpertise={listAreaOfIndustryExpertise}
            areaOfIndustryExpertise={areaOfIndustryExpertise}
            listTaxSpecialism={listTaxSpecialism}
            taxSpecialism={taxSpecialism}
            handleOpen={handleOpen}
            data={data}
            action={action}
            checkUserCountryWithFirmCountryActive={checkUserCountryWithFirmCountryActive}
          />
          <h2 className="ContactTitle">Administration information</h2>
          <AdministrationInfo
            handleChange={handleChange}
            right={right}
            rights={rights}
            isVisible={isVisible}
            toggleVisible={toggleVisible}
            data={data}
            action={action}
          />
        </div>
        {/*Modal add new area */}
        <Modal
          visible={openNewArea}
          title="Add new area"
          onOk={() => {
            addNewAreas(inputValue);
          }}
          onCancel={handleClose}
          destroyOnClose
          footer={[
            <Button key="back" onClick={handleClose}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={!canSubmitArea}
              onClick={() => {
                addNewAreas(inputValue);
              }}
            >
              add
            </Button>,
          ]}
        >
          <Paper className={styles.paperArea}>
            <form onSubmit={handleSubmitArea}>
              <div style={{ padding: "20px" }}>
                <TextField
                  required
                  style={{ width: "100%" }}
                  id="value-input"
                  onChange={handleClick}
                  label="Areas of industry Expertise"
                />
              </div>
              <br />
            </form>
          </Paper>
        </Modal>
      </div>
    );
  }
};

export default inject("dataStore", "messageStore")(observer(AddUserTaxand));
