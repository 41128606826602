import {RouteComponentProps} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {Header} from "../../../models/tables";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import styles from "../users/Users.module.scss";
import MaterialTable from "material-table";
import dataStore from "../../../stores/dataStore";
import Modal from "@material-ui/core/Modal";
import AddFirm from "./addFirm/AddFirm";
import { firmRef} from "../../../services/firebase/firebase";
import {API} from "../../../api";
import messageStore from "../../../stores/messageStore";
import {FirmData} from "../../../models/general";
import company from "../../../assets/company-logo.jpg"
import UpdateFirm from "./updateFirm/updateFirm";
import {Button, notification} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {Search} from "@material-ui/icons";
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import './Firms.scss';



interface FirmsProps extends RouteComponentProps {}

const Firms = (props: FirmsProps) => {
    const { t } = useTranslation();
    const [preview, ] = useState(company);
    const [firmDeleted,setFirmDeleted]=useState(false)
    const [openUpdateFirm, setOpenUpdateFirm] = React.useState(false);
    const [openNewFirm, setOpenNewFirm] = React.useState(false);
    const rows = dataStore.firms;
    const [token, setToken] = useState('');

    const [dataRow, setDataRow] = useState({
        id:"",
        firm:"",
        webSite:"",
        country:"",
        logo_id:"",
        deleted:false,
        firmLogoUrl:""
    });


    const headCells: Header[] = [
        {
            field: "firmLogoUrl",
            title: t(""),
            sortable: false,
            width:"60%",
            filtering:false,
            render: (rowData:FirmData) => (
                <img src={!!rowData.firmLogoUrl?rowData.firmLogoUrl:preview} alt=""  height="50" width="60" style={{opacity:!!rowData.firmLogoUrl?1:0.5,objectFit:"contain"}} />
            ),
        },
        {
            field: "firm",
            title: t("Firm"),
            sortable: true,
            validate: (rowData:FirmData) =>  rowData.firm === '' ? { isValid: false, helperText: 'Firm cannot be empty' } : true,
        },
        {
            field: "webSite",
            title: t("WebSite"),
            sortable: true,
            render: (rowData:FirmData) => (
                <a href={rowData.webSite} target="noopener"  >{rowData.webSite}</a>
            ),
        },
        {
            field: "country",
            title: t("Country"),
            sortable: true,
        },
    ];

    const loadData = useCallback(async () => {
        try {
            firmRef.on("value", async (snapshot: any) => {
                let firms = snapshot.val();
                let newFirms: any[] = [];
                for (let firm in firms) {
                    const detailedFirm: any  = await API.getFirmInfo(firm);
                    if (!detailedFirm.deleted) {
                        newFirms.push(detailedFirm);
                    }
                }
                newFirms.sort((firm1, firm2) => firm1.firm.localeCompare(firm2.firm));
                dataStore.updateFirms(newFirms);
            });

        } catch (error) {
            messageStore.snackbar({
                message: t("Failed fetching firms"),
                type: "error",
            });
        }
    }, [t]);

    const updateFirmUser = async (firmId: string) => {

        const users=dataStore.users
        for(let user in users){
            if(users[user].firm?.id===firmId) {
                const updateFirmUser:any={}
                updateFirmUser["firm"]=""
                await API.updateUser(updateFirmUser,users[user].id)
            }
        }
    }

    const deleteFirm = useCallback(async (firmId: string) => {
        setFirmDeleted(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteFirm`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    uid: firmId
                })
            });

            const responseData = await response.json();

            if(response.status !== 200) {
                throw new Error(responseData.error);
            }

            await updateFirmUser(firmId);

            setFirmDeleted(false);
            notification["success"]({
                message: "Success",
                description: t("Firm successfully deleted"),
            });
        } catch (error) {
            setFirmDeleted(false);
            notification["error"]({
                message: "Error",
                description: t("Failed to delete Firm"),
            });
        }
    }, [t,token]);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async function (user) {
                if (user) {
                    const token = await user.getIdToken();
                    setToken(token);
                } else {
                    setToken('');
                }
            }
        );
    }, []);

    useEffect(() => {
        loadData();
    }, [firmDeleted,loadData]);


    return(<div className={styles.root}>
                    <Button type="primary"
                    size='large'
                    style={{marginRight : "10px",fontFamily:'Arial',fontSize:"14px",margin:"10px 0", width : 'fit-content'}}
                    icon={<PlusOutlined />}
                    onClick={() =>  setOpenNewFirm(true)}>
                        Add new firm
                    </Button>
            <br />
            <MaterialTable
                icons={{ Filter: () => <Search /> }as any}
                columns={headCells}
                data={rows}
                options={{
                    padding: true ? "dense" : "default",
                    actionsColumnIndex: -1,
                    pageSize:10,
                    showTitle: false

                }}
                actions={[
                    rowData => (
                        {
                            icon: "edit",
                            tooltip: "Edit",
                            onClick: (event) => {
                                    setOpenUpdateFirm(true);
                                    setDataRow({
                                        id:rowData.id,
                                        firm:rowData.firm,
                                        webSite:!!rowData.webSite ? rowData.webSite:"",
                                        country:!!rowData.country?rowData.country:"",
                                        logo_id:!!rowData.logo_id?rowData.logo_id:"",
                                        deleted:!!rowData.deleted?rowData.deleted:false,
                                        firmLogoUrl:!!rowData.firmLogoUrl ? rowData.firmLogoUrl:"",
                                    })
                            },
                        }
                    ),
                ]}
                editable={{
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve('resolved');
                                deleteFirm(oldData.id);
                            }, 2000);
                        }),
                }}
            />
        <Modal
            open={openNewFirm}
            onClose={() => setOpenNewFirm(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <>
                  <AddFirm
                    onCloseCallback={() => setOpenNewFirm(false)}
                />
            </>
        </Modal>
        <Modal
            open={openUpdateFirm}
            onClose={() => setOpenUpdateFirm(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <>
                <UpdateFirm
                    onCloseCallback={() => setOpenUpdateFirm(false)}
                    dataRow={dataRow}
                />
            </>
        </Modal>
          </div>);

};

export default inject("dataStore", "messageStore")(observer(Firms));
