import React, { useCallback, useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ROUTES } from "../parameters/routes";
import { inject, observer } from "mobx-react";
import { AuthStore } from "../stores/authStore";

interface AuthorizationProps extends RouteComponentProps {
  authStore: AuthStore;
}

const Authorization: React.FC<AuthorizationProps> = ({ authStore, children, history }) => {
  const [guard, setGuard] = useState(false);

  // Extracting isAuthorized to a constant
  const isAuthorized = authStore.isAuthorized;

  const checkAdmin = useCallback(async () => {
    if (!isAuthorized) {
      history.push(ROUTES.login);
    } else {
      setGuard(true);
    }
  }, [isAuthorized, history]);

  useEffect(() => {
    checkAdmin().then();
  }, [checkAdmin]);

  return <>{guard ? children : null}</>;
};

export default withRouter(inject('authStore')(observer(Authorization)));
