import React, { useRef } from 'react'
import {Item} from "../../../../../models/general";
import {Dropdown, Menu} from "antd";
import {ReactComponent as MenuIcon} from "../../../../../assets/documents/svg/menu.svg";
import dataStore from "../../../../../stores/dataStore";
import {IconCloudUpload, IconDownload, IconX} from "@tabler/icons";
import {inject, observer} from "mobx-react";
import {Skeleton} from "@material-ui/lab";
import {downloadFile} from "../../../../../services/documents/DocumentDetailsServices";
import { extensionsIcons } from 'Utils/Constants/DocumentContants';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { Text, Group, Button, createStyles, Badge } from '@mantine/core';


const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}));

const  UpdateFileVersion=({loadingFileVersion,fileVersion,setFileVersion}:any)=>{

  const openRef = useRef<() => void>(null);
  const [newFileVersion , setNewFileVersion] = React.useState<FileWithPath>();
  const { classes, theme } = useStyles();

  const items=dataStore.itemsFileVersionSelected;
  const newFileVersionUploaded=dataStore.fileVersionUploaded;

    const getExtension=(fileName:string)=>{
    return fileName.split('.').pop();
    }


  /*Dropdown menu file:begin */
  const menuFile=(item:Item)=> (
      <Menu className="menu-container" >
        <Menu.Item key="1" className="menu-item edit" >
          <div onClick={(e)=> {e.stopPropagation();downloadFile(item)}}  >
            <IconDownload width={15} height={15} style={{marginRight:'5px'}} />
            <span>Download</span>
          </div>
        </Menu.Item>

      {/*  <Menu.Item  onClick={(e)=>{e.domEvent.stopPropagation()}} key="2" className="menu-item delete">
          <Popconfirm
              overlayStyle={{zIndex:10003}}
              title="Are you sure to delete this file?"
              onConfirm={(e)=>confirmDeleteFile(e,item)}
          >
            <div onClick={(e)=> e.stopPropagation()}  >
              <IconTrash  width={15} height={15} style={{marginRight:'5px'}}/>
              <span>Delete</span>
            </div>
          </Popconfirm>
        </Menu.Item>*/}
      </Menu>
  );
  /*Dropdown menu file:end */

  return (
    <div className="fileVersion">
      <div className={classes.wrapper}>
        <Dropzone
          openRef={openRef}
          onDrop={(files) => {
            setNewFileVersion(files[0]);
            setFileVersion(files[0])
          }}
          className={classes.dropzone}
          radius="md"
          maxSize={500 * 1024 ** 2}
          multiple={false}
        >
          <div style={{ pointerEvents: "none" }}>
            <Group position="center">
              <Dropzone.Accept>
                <IconDownload
                  size={50}
                  color={theme.colors[theme.primaryColor][6]}
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX size={50} color={theme.colors.red[6]} stroke={1.5} />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconCloudUpload
                  size={50}
                  color={
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[0]
                      : theme.black
                  }
                  stroke={1.5}
                />
              </Dropzone.Idle>
            </Group>

            <Text align="center" weight={700} size="lg" mt="xl">
              <Dropzone.Accept>Drop your file here</Dropzone.Accept>
              <Dropzone.Reject>Only one file supported for version update and less than 500mb</Dropzone.Reject>
              <Dropzone.Idle>Upload file</Dropzone.Idle>
            </Text>
            <Text align="center" size="sm" mt="xs" color="dimmed">
              Drag&apos;n&apos;drop files here to upload. We can accept only
              files that are less than 500mb in size.
            </Text>
          </div>
        </Dropzone>

        <Button
          className={classes.control}
          size="md"
          radius="xl"
          onClick={() => openRef.current?.()}
        >
          Select files
        </Button>
      </div>
      {loadingFileVersion && (
        <div className="fileVersion-container">
          <Skeleton style={{ marginRight: "20px" }} height={80} />
          <Skeleton style={{ marginRight: "20px" }} height={80} />
          <Skeleton style={{ marginRight: "20px" }} height={80} />
        </div>
      )}
      {!loadingFileVersion && (
        <div className="fileVersion-container">
          {(newFileVersion && !newFileVersionUploaded) && (
            <div className="item-file file">
            <div className="header-containerFileVersion">
              {getExtension(newFileVersion.name)?.toLocaleLowerCase() !== undefined
                ? extensionsIcons[getExtension(newFileVersion.name)?.toLocaleLowerCase() as keyof typeof extensionsIcons]
                : extensionsIcons["default"]}
              <Badge variant="gradient" gradient={{ from: 'yellow', to: 'red' }}>
                New version
              </Badge>
            </div>
            
            <span className="itemFile-title">
              {newFileVersion.name}
            </span>
          </div>
          )}
          {items.map(function (item: Item, i: number) {
            let extension = getExtension(item.name)?.toLocaleLowerCase();
            return (
              <div className="item-file file" key={Math.random()}>
                <div className="header-containerFileVersion">
                  {extension !== undefined
                    ? extensionsIcons[extension as keyof typeof extensionsIcons]
                    : extensionsIcons["default"]}
                  <div style={{display : "flex", alignItems : "center"}}>
                    <span key={i} style={{marginRight : "10px"}}>{"version " + item.version}</span>
                    <Dropdown overlay={menuFile(item)} trigger={["hover"]}>
                      <MenuIcon
                        className="menu-icon"
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Dropdown>
                  </div>
                  
                </div>
                <span className="itemFile-title" key={i}>
                  {item.name}
                </span>
              </div>
            );
          })}

          {/* Load File Modal:end */}
        </div>
      )}
    </div>
  );
}
export default inject("authStore", "dataStore", "messageStore")(observer(UpdateFileVersion));
