import React, {useMemo} from 'react';
import './ListNotification.scss';
import {ReactComponent as Notification2Icon} from 'assets/event-calender/notification/svg/notification-2.svg';
import {CalenderEvent} from "../../../../models/calenderEvent";
import moment from "moment";
import {useEventContext} from "../../EventContext";
import {ReactComponent as BellIcon} from "../../../../assets/event-calender/notification/svg/bell.svg";
import {Tooltip} from "@mantine/core";

function ListNotification() {
    const { events } = useEventContext();

    const isEventCreatedToday = (event: CalenderEvent) => {
        const today = moment().startOf('day');
        return moment(event.createdAt).isSameOrAfter(today);
    };

    const isEventUpcomingOrToday = (event: CalenderEvent) => {
        const today = moment().startOf('day');
        return moment(event.start).isSameOrAfter(today);
    };
    const isEventUpdatedToday = (event: CalenderEvent) => {
        // const today = moment().startOf('day');

        // Check if event is created today or later
        //const isCreatedTodayOrLater = moment(event.createdAt).isSameOrAfter(today);
        // Check if createdAt is different from modifiedAt
        //return isCreatedTodayOrLater && isModified;
        return event.createdAt !== event.modifiedAt;
    };

    const sortEvents = (events: CalenderEvent[]) => {
        return [...events].sort((eventA, eventB) => {
            const modifiedAtA = eventA.modifiedAt ? new Date(eventA.modifiedAt) : new Date(0);
            const modifiedAtB = eventB.modifiedAt ? new Date(eventB.modifiedAt) : new Date(0);
            return modifiedAtB.getTime() - modifiedAtA.getTime();
        });
    };

    const sortedEvents = useMemo(() => {
        const filteredEvents = events.filter(isEventUpcomingOrToday);
        return sortEvents(filteredEvents);
    }, [events]);

    return (
        <div className="rbt-list-inner">
            <div className="rbt-logo">
                <button className="">
                    <div className="logo-svg-holder">
                        <Notification2Icon />
                    </div>
                    <p className="logo-text-holder">Notification</p>
                </button>
            </div>
            {sortedEvents.map((event) => (
                <div key={event.id}>
                    {/* Display notification with "active" background if created today */}
                    <button className={isEventCreatedToday(event) ? "active" : ""}>
                        <div className="rbt">
                            <BellIcon />
                            <span className="rbt-category">{event.category}
                                <span className={isEventUpdatedToday(event) ? "edit active" : "edit"}>updated</span>
                            </span>
                            <span className="rbt-title">{event.title}</span>
                            <Tooltip
                                label={event.description}
                                position="top"
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: '5px',
                                    maxWidth: '600px',
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    padding: '10px'
                                }}
                            >
                                <span className="rbt-description">
                                    {event.description.slice(0, 50)}...
                                </span>
                            </Tooltip>
                            {/* Format event start and end times */}
                            <span className="rbt-date">
                                {moment(event.start).format('LT')} - {moment(event.end).format('LT')}
                            </span>
                        </div>
                    </button>
                    <div className="rbt-sep"></div>
                </div>
            ))}
        </div>
    );
}

export default ListNotification;
