import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import messageStore, { FullMessage } from '../../stores/messageStore';
import { Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import styles from './MessageService.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {}

const MessageService = (props: Props) => {
  const snacks = messageStore.snacks;
  const { t } = useTranslation();
  const isOpen = (thisSnack: FullMessage) => {
    const where = snacks.reverse().indexOf(thisSnack);
    return where >= 0 && where <= 2;
  };

  const deleteMsg = (snack: FullMessage) => {
    messageStore.snacks.splice(messageStore.snacks.indexOf(snack), 1);
  };

  const handleClose = (thisSnack: FullMessage) => {
    deleteMsg(thisSnack);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      messageStore.snacks.map((snack) => {
        if (Math.floor((Date.now() - snack.created) / 1000) > 2) {
          deleteMsg(snack);
        }
        return 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.outter}>
      {snacks.map((thisSnack: FullMessage, index: number) => (
        <Snackbar
          key={index}
          open={isOpen(thisSnack)}
          classes={{
            root: styles.root,
          }}
        >
          <div className={styles[thisSnack?.type]}>
            {thisSnack?.message}
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                handleClose(thisSnack);
              }}
            >
              {t('Close')}
            </Button>
          </div>
        </Snackbar>
      ))}
    </div>
  );
};

export default inject('messageStore')(observer(MessageService));
