import React from 'react'
import {Select, Switch} from 'antd';


const {Option} = Select;

type dataType = {
    email: string,
    password: string,
    againPassword: string,
    firstName: string,
    lastName: string,
    generatedPass: string,
    phone: string,
    mobile: string,
    personnalBiography: string,
    profesionnalBiography: string,
    deleted: boolean,
    linkedInProfileLink: string,
    twitterProfileLink: string,
    assistantName: string,
    assistantEmail: string,
    assistantTelephone: string,
    assistantMobile: string,
    right?: string,
    isVisible?: boolean | undefined,
}

type Props = {

    data: any,
    handleChange?: (value: any) => void,
    right?: string | undefined,
    rights?: any;
    isVisible?: any,
    toggleVisible?: () => void,
    action?: 'Info' | 'Add' | 'Edit' | undefined,
    dataRow?: any

}

export default function ({
                             handleChange, right, rights, isVisible, toggleVisible, data, action,
                             dataRow
                         }: Props) {
    return (

        <div className="ContactContainer">
            {
                window.location.pathname.includes('professionalsFirm') ?
                    (
                        <div className="InputSameRow">
                            <div className="InputHalfSize">
                                <label className="InputLabel">User right {action !== "Info" ?
                                    <span style={{color: 'red'}}>*</span> : null}</label>
                                <br/>
                                {action === 'Add' || action === 'Edit' ? (
                                    <Select
                                        showSearch
                                        size="large"
                                        placeholder="Select the user right"
                                        optionFilterProp="children"
                                        value={action === "Add" ? "Standard user" : right}
                                        disabled
                                        defaultValue={data.right !== undefined ? data.right : "Standard user"}
                                        onChange={handleChange}
                                        style={!!dataRow?.right && dataRow?.type !== "Create" ? {
                                            border: "2px solid #5dc149",
                                            marginTop: "10px",
                                            width: "93.5%"
                                        } : {marginTop: "10px", width: "93.5%"}}
                                        filterOption={(input, option) =>
                                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {rights.map((c: any) => (
                                            <Option key={c} value={c}>
                                                {c}
                                            </Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <span style={{fontFamily: "Arial", color: "#696868"}}>
                              {(data.right !== undefined && data.right !== "") ? data.right : 'Not defined'}
                            </span>
                                )}
                            </div>
                            <div className="InputHalfSize">
                                <label className="InputLabel">Visible on Taxand website</label>
                                <br/>
                                {action === 'Add' || action === 'Edit' ? (
                                    <Switch
                                        size="default"
                                        checked={isVisible}
                                        onChange={toggleVisible}
                                        style={{marginTop: "10px"}}
                                    />
                                ) : (<span
                                    style={typeof dataRow?.isVisible === "boolean" && dataRow?.type !== "Create" ? {
                                        border: "2px solid #5dc149",
                                        fontFamily: "Arial",
                                        color: "#696868"
                                    } : {fontFamily: "Arial", color: "#696868"}}>
                                {data.isVisible ? 'YES' : 'NO'}
                                </span>)
                                }
                            </div>
                        </div>
                    ) : (
                        <div className="InputSameRow">
                            <div className="InputHalfSize">
                                <label className="InputLabel">User right {action !== "Info" ?
                                    <span style={{color: 'red'}}>*</span> : null}</label>
                                <br/>
                                {action === 'Add' || action === 'Edit' ? (
                                    <Select
                                        showSearch
                                        size="large"
                                        placeholder="Select the user right"
                                        optionFilterProp="children"
                                        value={action === "Add" ? undefined : right}
                                        defaultValue={data.right !== undefined ? data.right : undefined}
                                        onChange={handleChange}
                                        style={!!dataRow?.right && dataRow?.type !== "Create" ? {
                                            border: "2px solid #5dc149",
                                            marginTop: "10px",
                                            width: "93.5%"
                                        } : {marginTop: "10px", width: "93.5%"}}
                                        filterOption={(input, option) =>
                                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {rights.map((c: any) => (
                                            <Option key={c} value={c}>
                                                {c}
                                            </Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <span style={{fontFamily: "Arial", color: "#696868"}}>
                      {(data.right !== undefined && data.right !== "") ? data.right : 'Not defined'}
                    </span>
                                )}
                            </div>
                            <div className="InputHalfSize">
                                <label className="InputLabel">Visible on Taxand website</label>
                                <br/>
                                {action === 'Add' || action === 'Edit' ? (
                                    <Switch
                                        size="default"
                                        checked={isVisible}
                                        onChange={toggleVisible}
                                        style={{marginTop: "10px"}}
                                    />
                                ) : (<span style={typeof dataRow?.isVisible === "boolean" && dataRow?.type !== "Create" ? {
                                    border: "2px solid #5dc149",
                                    fontFamily: "Arial",
                                    color: "#696868"
                                } : {fontFamily: "Arial", color: "#696868"}}>
                  {data.isVisible ? 'YES' : 'NO'}
                </span>)
                                }
                            </div>
                        </div>
                    )
            }
        </div>
    );
}
