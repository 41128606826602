import {Button, Input, Switch} from 'antd';
import React, {useState} from 'react'
import { Select } from 'antd';
import {SocialIcon} from "react-social-icons";
// import InfoIcon from "@material-ui/icons/Info";
import "./ContactInfo.scss"
import TooltipPassword from "../../Widgets/TooltipPassword";

const {Option} = Select;

type dataType = {
    email: string,
    password: string,
    againPassword: string,
    firstName: string,
    lastName: string,
    generatedPass: string,
    phone: string,
    mobile: string,
    personnalBiography: string,
    profesionnalBiography: string,
    deleted: boolean,
    linkedInProfileLink: string,
    twitterProfileLink: string,
    assistantName: string,
    assistantEmail: string,
    assistantTelephone: string,
    assistantMobile: string,
    country?: string,
    isConsentNotifications ?: boolean | undefined,
}
type Props = {

    data: any,
    action: "Add" | "Edit" | "Info",
    handleChangeCountry?: (value: any) => void,
    handleDataChange?: ({currentTarget}: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void,
    listCountries?: any,
    usernameVerified?: boolean,
    checkEmail?: any,
    checkUserName?: any,
    emailVerified?: boolean,
    country?: string,
    canSubmit?: any,
    isValidPassword?: boolean,
    passwordVal?: any,
    isValidPasswordAgain?: boolean,
    dataRow?: any
    emailValid?: boolean
    checkUserCountryWithFirmCountryActive?: boolean
    generatePassword?: any
    isConsentNotifications?:any
    toggleConsentNotifications ?: () => void ,
    onChange?: any,
}

const onFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {

    if(event.target.autocomplete)
    {
        event.target.autocomplete = "whatever";
    }

};

export default function ContactInfo({
                                        handleChangeCountry,
                                        checkUserCountryWithFirmCountryActive,
                                        listCountries,
                                        handleDataChange,
                                        checkEmail,
                                        generatePassword,
                                        emailValid,
                                        checkUserName,
                                        data,
                                        usernameVerified,
                                        emailVerified,
                                        canSubmit,
                                        isValidPassword,
                                        passwordVal,
                                        isValidPasswordAgain,
                                        action,
                                        country,
                                        dataRow,
                                        isConsentNotifications,
                                        toggleConsentNotifications,
                                        onChange
                                    }: Props) {

    const [isValidLinkedInUrl, setIsValidLinkedInUrl] = useState(true);
    const [isValidTwitterUrl, setIsValidTwitterUrl] = useState(true);
    const [copiedMessage, setCopiedMessage] = useState('');

    const handleChangeUrl = (event: any, inputType: string) => {
        const inputValue = event.target.value;

        // Regular expressions to match URL formats
        const linkedInUrlPattern = /^https:\/\/www\.linkedin\.com\/.*$/;
        const twitterUrlPattern = /^https:\/\/twitter\.com\/.*$/;

        // Check if the input value matches the URL pattern based on inputType
        if (inputType === "linkedin") {
            setIsValidLinkedInUrl(inputValue === '' || linkedInUrlPattern.test(inputValue));
        } else if (inputType === "twitter") {
            setIsValidTwitterUrl(inputValue === '' || twitterUrlPattern.test(inputValue));
        }

        // Only trigger handleDataChange if it's defined
        if (handleDataChange !== undefined) {
            handleDataChange(event);
        }
        // Call the parent onChange function if provided
        if (onChange) {
            onChange(inputValue, inputType === "linkedin" ? isValidLinkedInUrl : isValidTwitterUrl);
        }
    };

    return (
        <div className="ContactContainer nested-form">
            <div className="InputSameRow">
                <div className="InputHalfSize">
                    <label className="InputLabel">First name {action !== "Info" ?
                        <span style={{color: 'red'}}>*</span> : null} </label>
                    <br/>
                    {action === "Add" || action === "Edit" ? (
                        <>
                            <Input
                                onBlur={checkUserName}
                                required
                                autoComplete='off'
                                name="firstName"
                                size="large"
                                placeholder="Enter first name of the user"
                                type="text"
                                onChange={
                                    handleDataChange !== undefined ? handleDataChange : () => {}
                                }
                                value={data.firstName}
                                style={!!dataRow?.firstName && dataRow?.type !== "Create" ? {border: "2px solid #3FA037"} : {
                                    marginTop: "10px",
                                    borderColor: usernameVerified ? "" : "red",
                                }}
                            />
                            {!usernameVerified && (
                                <label className="ErrorLabel">
                                    This username already exists
                                </label>
                            )}
                        </>
                    ) : (
                        <span style={{fontFamily: "Arial", color: "#696868"}}>
              {data.firstName !== undefined && data.firstName !== ""
                  ? data.firstName
                  : "- -"}
            </span>
                    )}
                </div>
                <br/>
                <div className="InputHalfSize">
                    <label className="InputLabel">Last name {action !== "Info" ?
                        <span style={{color: 'red'}}>*</span> : null} </label>
                    <br/>
                    {action === "Add" || action === "Edit" ? (
                        <>
                            <Input
                                onBlur={checkUserName}
                                required
                                autoComplete='off'
                                name="lastName"
                                size="large"
                                placeholder="Enter last name of the user"
                                type="text"
                                value={data.lastName}
                                onChange={
                                    handleDataChange !== undefined ? handleDataChange : () => {
                                    }
                                }
                                style={!!dataRow?.lastName && dataRow?.type !== "Create" ? {border: "2px solid #3FA037"} : {
                                    marginTop: "10px",
                                    borderColor: usernameVerified ? "" : "red",
                                }}
                            />
                            {!usernameVerified && (
                                <label className="ErrorLabel">
                                    This username already exists
                                </label>
                            )}
                        </>
                    ) : (
                        <span style={{fontFamily: "Arial", color: "#696868"}}>
              {data.lastName !== undefined && data.lastName !== ""
                  ? data.lastName
                  : "- -"}
            </span>
                    )}
                </div>
            </div>
            <br/>
            <div>
                {action === "Add" && (
                    <div className="InputFullSize">
                        <label className="InputLabel">Email <span style={{color: 'red'}}>*</span> </label>
                        <br/>
                        <Input
                            onBlur={checkEmail}
                            autoComplete='off'
                            name="email"
                            size="large"
                            placeholder="Enter a valid email address"
                            type="email"
                            onFocus={(e) => onFocus(e)}
                            value={data.email}
                            onChange={
                                handleDataChange !== undefined ? handleDataChange : () => {}
                            }
                            style={{
                                marginTop: "10px",
                                borderColor: emailVerified || emailValid ? "" : "red",
                            }}
                        />
                        {!emailVerified && (
                            <label className="ErrorLabel">
                                This user e-mail already exists
                            </label>
                        )}
                        {!emailValid && (
                            <label className="ErrorLabel">
                                {data.email} is not a valid email address
                            </label>
                        )}
                    </div>
                )}
                {action === "Edit" && (
                    <div className="InputFullSize">
                        <label className="InputLabel">Email <span style={{color: 'red'}}>*</span> </label>
                        <Input
                            autoComplete='off'
                            name="email"
                            size="large"
                            type="email"
                            value={data.email}
                            style={{marginTop: "10px"}}
                            disabled
                        />
                    </div>
                )}

                {action === "Info" && (
                    <div className="InputFullSize">
                        <label className="InputLabel">Email</label>
                        <br/>
                        <span style={{fontFamily: "Arial", color: "#696868"}}>
              {data.email !== undefined && data.email !== ""
                  ? data.email
                  : "- -"}
            </span>
                    </div>
                )}
            </div>

            {/* --- */}
            <div className="InputSameRow">
                <div className="InputHalfSize">
                    <label className="InputLabel">Mobile </label>
                    <br/>
                    {action === "Add" || action === "Edit" ? (
                        <>
                            <Input
                                autoComplete='off'
                                name="mobile"
                                size="large"
                                placeholder="Enter a valid mobile number "
                                type="tel"
                                value={data.mobile}
                                onChange={
                                    handleDataChange !== undefined ? handleDataChange : () => {
                                    }
                                }
                                style={dataRow?.mobile !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #3FA037"} : {
                                    marginTop: "10px",
                                }}
                            />
                        </>
                    ) : (
                        <span style={{fontFamily: "Arial", color: "#696868"}}>
              {data.mobile !== undefined && data.mobile !== ""
                  ? data.mobile
                  : "- -"}
            </span>
                    )}
                </div>
                <div className="InputHalfSize">
                    <label className="InputLabel">Phone </label>
                    <br/>
                    {action === "Add" || action === "Edit" ? (
                        <>
                            <Input
                                autoComplete='off'
                                name="phone"
                                size="large"
                                placeholder="Enter a valid phone number "
                                type="tel"
                                value={data.phone}
                                style={dataRow?.phone !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #3FA037"} : {
                                    marginTop: "10px",
                                }}
                                onChange={
                                    handleDataChange !== undefined ? handleDataChange : () => {
                                    }
                                }
                            />
                        </>
                    ) : (
                        <span style={{fontFamily: "Arial", color: "#696868"}}>
              {data.phone !== undefined && data.phone !== ""
                  ? data.phone
                  : "- -"}
            </span>
                    )}
                </div>
            </div>
            {action === "Add" && (
                <>
                    <div className="InputSameRow">
                        <div className="InputHalfSize messageContainer">
                            <label className="InputLabel">Password <span style={{color: 'red'}}>*</span> </label>
                            <Input
                                autoComplete="new-password"
                                size="large"
                                placeholder="Enter password"
                                type="password"
                                value={data.password}
                                name="password"
                                onChange={
                                    handleDataChange !== undefined ? handleDataChange : () => {}
                                }
                                style={{
                                    marginTop: "10px",
                                    borderColor: isValidPassword ? "" : "red",
                                }}
                            />

                            {!isValidPassword && (
                                <div id="message">
                                    <TooltipPassword displayVal={passwordVal.displayVal} lowercase={passwordVal.lowercase} uppercase={passwordVal.uppercase} maxChar={passwordVal.maxChar} minChar={passwordVal.minChar} numeral={passwordVal.numeral} specialChar={passwordVal.specialChar} />
                                </div>
                            )}
                        </div>
                        <div className="InputHalfSize">
                            <label className="InputLabel">Confirm password <span style={{color: 'red'}}>*</span>
                            </label>
                            <Input
                                autoComplete="off"
                                size="large"
                                placeholder="Confirm the password"
                                type="password"
                                value={data.againPassword}
                                name="againPassword"
                                onChange={
                                    handleDataChange !== undefined ? handleDataChange : () => {
                                    }
                                }
                                style={{
                                    marginTop: "10px",
                                    borderColor: isValidPasswordAgain ? "" : "red",
                                }}
                            />
                            {!isValidPasswordAgain && (
                                <label className="ErrorLabel">
                                    Passwords don't match
                                </label>
                            )}
                        </div>
                    </div>
                    <div style={{display: "flex", marginTop: "10px"}}>
                        <Button
                            style={{marginRight: "5px"}}
                            onClick={generatePassword}
                        >
                            Generate password
                        </Button>
                        <br/>
                        {!!data.generatedPass ? (
                            <>
                                <Input
                                    className={"input"}
                                    id="generatedPass"
                                    value={data.generatedPass}
                                    readOnly
                                    onFocus={(e) => {
                                        e.target.select();
                                        document.execCommand('copy');
                                        setCopiedMessage('Value copied!');
                                        setTimeout(() => {
                                            setCopiedMessage('');
                                        }, 2000);
                                    }}
                                />
                                {copiedMessage && <span style={{display: "flex", marginLeft: "10px", alignItems: "center"}}>{copiedMessage}</span>}
                            </>
                        ) : null}
                    </div>
                </>
            )}
            <div className="InputSameRow">
                <div className="InputHalfSize" style={action === 'Info' ? {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                } : {}}>
                    <label className="InputLabel">Linkedin</label>
                    <br/>
                    {action === "Add" || action === "Edit" ? (
                        <>
                            <Input
                                autoComplete="off"
                                name="linkedInProfileLink"
                                size="large"
                                placeholder="Linkedin profile link"
                                type="url"
                                value={data.linkedInProfileLink}
                                onChange={(event) => handleChangeUrl(event, "linkedin")}
                                style={dataRow?.linkedInProfileLink !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #3FA037"} : {
                                    marginTop: "10px",
                                }}
                            />
                            {!isValidLinkedInUrl && <p style={{ color: 'red' }}>Please enter a valid LinkedIn profile link starting with "https://"</p>}
                        </>
                    ) : (
                        <span style={{fontFamily: "Arial", color: "#696868"}}>
              {data.linkedInProfileLink !== undefined &&
              data.linkedInProfileLink !== "" ? (
                  <SocialIcon
                      network="linkedin"
                      style={{height: "20px", width: "20px"}}
                      url={`https://${data.linkedInProfileLink}`}
                      target="_blank"
                  />
              ) : (
                  "- -"
              )}
            </span>
                    )}
                </div>
                <div className="InputHalfSize" style={action === 'Info' ? {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                } : {}}>
                    <label className="InputLabel">Twitter</label>
                    <br/>
                    {action === "Add" || action === "Edit" ? (
                        <>
                            <Input
                                autoComplete="off"
                                name="twitterProfileLink"
                                size="large"
                                placeholder="Twitter profile link"
                                type="link"
                                value={data.twitterProfileLink}
                                onChange={(event) => handleChangeUrl(event, "twitter")}
                                style={dataRow?.twitterProfileLink !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #3FA037"} : {
                                    marginTop: "10px",
                                }}
                            />
                            {!isValidTwitterUrl  && <p style={{ color: 'red' }}>Please enter a valid Twitter profile link starting with "https://"</p>}
                        </>
                    ) : (
                        <span style={{fontFamily: "Arial", color: "#696868"}}>
              {data.twitterProfileLink !== undefined &&
              data.twitterProfileLink !== "" ? (
                  <SocialIcon
                      network="twitter"
                      style={{height: "20px", width: "20px"}}
                      url={`https://${data.twitterProfileLink}`}
                      target="_blank"
                  />
              ) : (
                  "- -"
              )}
            </span>
                    )}
                </div>
            </div>
            <br/>
            {action === 'Edit' && window.location.pathname.includes('profiles') ? (
                <div className="InputSameRow">
                    <div className="InputHalfSize">
                        <label className="InputLabel">Country</label>
                        <br/>
                        {action === "Edit" ? (
                            <>
                                <Select
                                    showSearch
                                    size="large"
                                    placeholder="Select a country"
                                    optionFilterProp="children"
                                    onChange={
                                        handleChangeCountry !== undefined
                                            ? (e) => handleChangeCountry(e)
                                            : () => {
                                            }
                                    }
                                    value= {country}
                                    defaultValue={
                                        data.country !== undefined && data.country !== ""
                                            ? data.country
                                            : undefined
                                    }
                                    style={!!dataRow?.country && dataRow?.type !== "Create" ? {
                                        border: "2px solid #5dc149",
                                        marginTop: "10px",
                                        width: "100%"
                                    } : {marginTop: "10px", width: "100%"}}

                                    filterOption={(input, option) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {listCountries
                                        .slice()
                                        .sort((a: any, b: any) => (a > b ? 1 : -1))
                                        .map((c: any) => (
                                            <Option key={c} value={c}>
                                                {c}
                                            </Option>
                                        ))}
                                </Select>
                                {!checkUserCountryWithFirmCountryActive && (
                                    <label style={{alignItems: "center", display: "flex", marginTop: "5px"}}
                                           className="WarningLabel">
                                        {/*<InfoIcon/>*/}
                                        {/*<span>The user's country is not in the firm's list of countries</span>*/}
                                    </label>
                                )}
                            </>
                        ) : (
                            <span style={{fontFamily: "Arial", color: "#696868"}}>
            {data.country !== undefined && data.country !== ""
                ? data.country
                : "- -"}
          </span>
                        )}
                    </div>
                    <div className="InputHalfSize">
                        <label className="InputLabel">I am happy to receive notifications from the Hub {data.isConsentNotifications}</label>
                        <br/>
                        {action === 'Edit' ?
                            (
                                <Switch
                                    size="default"
                                    checked={isConsentNotifications}
                                    onChange={toggleConsentNotifications}
                                    style={{marginTop: "10px"}}/>)
                            :
                            (<span style={typeof dataRow?.isConsentNotifications === "boolean" && dataRow?.type !== "Create"
                                ? {
                                    border: "2px solid #5dc149",
                                    fontFamily: "Arial",
                                    color: "#696868"
                                } : {fontFamily: "Arial", color: "#696868"}}>
                               {data.isConsentNotifications ? 'YES' : 'NO'}
        </span>)
                        }
                    </div>
                </div>
            ) : action === 'Add' || action === 'Edit' ? (
                <div className="InputFullSize" >
                    <label className="InputLabel">Country <span style={{color: 'red'}}>*</span></label>
                    <br />
                    {action === "Add" || action === "Edit" ? (
                        <>
                            <Select
                                showSearch
                                size="large"
                                placeholder="Select a country"
                                optionFilterProp="children"
                                onChange={
                                    handleChangeCountry !== undefined
                                        ? (e) => handleChangeCountry(e)
                                        : () => {}
                                }
                                value={action==="Add"? undefined: country}
                                defaultValue={
                                    data.country !== undefined && data.country !== ""
                                        ? data.country
                                        : undefined
                                }
                                style={!!dataRow?.country && dataRow?.type !== "Create" ? {border: "2px solid #5dc149",marginTop: "10px", width: "100%"} : {marginTop: "10px", width: "100%"}}

                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {listCountries
                                    .slice()
                                    .sort((a: any, b: any) => (a > b ? 1 : -1))
                                    .map((c: any) => (
                                        <Option key={c} value={c}>
                                            {c}
                                        </Option>
                                    ))}
                            </Select>
                            {!checkUserCountryWithFirmCountryActive && (
                                <label style={{alignItems:"center",display:"flex",marginTop:"5px"}} className="WarningLabel">
                                    {/*<InfoIcon />*/}
                                    {/*<span>The user's country is not in the firm's list of countries</span>*/}
                                </label>
                            )}
                        </>
                    ) : (
                        <span style={{ fontFamily: "Arial", color: "#696868" }}>
            {data.country !== undefined && data.country !== ""
                ? data.country
                : "- -"}
          </span>
                    )}
                </div>
            ) : null}
        </div>
    );
}
