import { Dialog, DialogTitle } from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import styles from "./TaxandDialog.module.scss";

interface TaxandDialogProps {
  title: string;
  open: boolean;
  onClose: any;
  parentStyle?: string;
  childStyle?: string;
}

const TaxandDialog = (props: PropsWithChildren<TaxandDialogProps>) => {
  const { children, title, open, parentStyle, childStyle, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="bear-dialog-title"
      open={open}
      className={parentStyle}
    >
      <DialogTitle id="bear-dialog-title">{title}</DialogTitle>
      {!!children ? (
        <div className={`${styles.content} ${childStyle}`}>{children}</div>
      ) : null}
    </Dialog>
  );
};

export default TaxandDialog;
