import React from 'react'
import { ReactComponent as FolderSvg } from 'assets/documents/svg/folder.svg';
import { ActionIcon, Group, Tooltip ,Input} from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons';
import { createFolder } from 'helpers/DocumentHandlers';

type Props = {
    checkFolderExistFunction: () => void,
    setCheckFolderExistParam: (value: React.SetStateAction<boolean>) => void,
    setFolderName: (value: any) => void,
    folderName: string,
    checkFolderExistParam: boolean
    setIsNewFolder: (value: React.SetStateAction<boolean>) => void
    loadingSave : boolean
    setLoadingSave: React.Dispatch<React.SetStateAction<boolean>>
    currentPath: string
    setCurrentPath: React.Dispatch<React.SetStateAction<string>>
    setParent: React.Dispatch<React.SetStateAction<string>>
    params: any

}

export default function NewFolderItem({
    checkFolderExistFunction,
    setCheckFolderExistParam,
    setFolderName,
    folderName,
    checkFolderExistParam,
    setIsNewFolder,
    setLoadingSave,
    setCurrentPath,
    setParent,
    params,
    currentPath,
    loadingSave
}: Props) {
  return (
    <div className="item-folder folder create-active">
    <div className="header-container">
      <FolderSvg className="folder-icon" />
    </div>
    <div
      className="input-action-container"
      style={{ marginTop: "15px" }}
    >
      <div style={{ width: "78%" }}>
        <Input
          onBlur={checkFolderExistFunction}
          variant="filled"
          required
          name="folderName"
          size="xs"
          radius="md"
          placeholder="Folder name"
          className="input-action"
          onChange={(
            e: React.ChangeEvent<{ value: unknown }>
          ) => {
            setCheckFolderExistParam(false);
            setFolderName(e.target.value);
          }}
          value={folderName}
          invalid={checkFolderExistParam}
        />
        {checkFolderExistParam && (
          <label className="ErrorLabel">
            folder name already exists
          </label>
        )}
      </div>

      <Group
        spacing={0}
        position="right"
        className="group-action-container"
      >
        <Tooltip label="Cancel" color="dark" withArrow>
          <ActionIcon
            color="red"
            onClick={() => setIsNewFolder(false)}
          >
            <IconX size={10} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Save" color="dark" withArrow>
          <ActionIcon
            loading={loadingSave}
            color="green"
            onClick={(
              e:
                | React.FormEvent<HTMLFormElement>
                | React.MouseEvent<HTMLElement, MouseEvent>
            ) =>
              createFolder(
                e,
                setLoadingSave,
                checkFolderExistParam,
                currentPath,
                setCurrentPath,
                setParent,
                params,
                folderName,
                setFolderName,
                setIsNewFolder
              )
            }
          >
            <IconCheck size={10} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </Group>
    </div>
  </div>
  )
}
