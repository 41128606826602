import React from "react";
import { ReactComponent as SmallIcon } from 'assets/TaxandSmallIcon.svg' ;

const LoadingPage = () => {
  return (
    <div className="LoadingPage">
        <div className="Spin">
            <SmallIcon />
        </div>
    </div>
  )
};

export default LoadingPage;
