
import { observable, action, decorate, computed } from 'mobx';
import { AuthToken } from '../models/general';
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports


const TOKEN_STORAGE_KEY = 'authorization/token';

export class AuthStore {
    token?: AuthToken =
        window.localStorage.getItem(TOKEN_STORAGE_KEY) ?? window.sessionStorage.getItem(TOKEN_STORAGE_KEY) ?? undefined;

    setToken(token: AuthToken) {
        this.clearToken();
        window.localStorage.setItem(TOKEN_STORAGE_KEY, token);
        this.token = token;
    }
    clearToken() {
        window.localStorage.removeItem(TOKEN_STORAGE_KEY);
        window.sessionStorage.removeItem(TOKEN_STORAGE_KEY);
        this.token = undefined;
    }


    logout() {
        const authStore = this;
        firebase.auth().signOut().then(function () {
            authStore.clearToken();
            localStorage.removeItem('wordpressToken');
            localStorage.removeItem('wordpress/expirationToken');
            window.location.reload();
        }).catch(function (error) {
            // An error happened.
        });
    }

    get isAuthorized(): boolean {
        return !!this.token;
    }

}


decorate(AuthStore, {
    token: observable,
    setToken: action,
    clearToken: action,
    logout: action,
    isAuthorized: computed,
});


export default new AuthStore();
