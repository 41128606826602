import React, {useRef, useState} from 'react';
import { extensionsIcons } from 'Utils/Constants/DocumentContants';
import { Text, Group, Button, createStyles, ActionIcon, Checkbox } from '@mantine/core';
import { Dropzone , FileWithPath } from '@mantine/dropzone';
import { IconCloudUpload, IconX, IconDownload, IconCircleMinus } from '@tabler/icons';

type uploadProps = {
  filesWithPath: FileWithPath[] | undefined;
  setFilesWithPath: (value: React.SetStateAction<FileWithPath[] | undefined>) => void;
  setDownloadPermissions: (value: React.SetStateAction<boolean[]>) => void;
  setCurrentUser: string;
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}));

export default function UploadFile({filesWithPath,setFilesWithPath,setDownloadPermissions,setCurrentUser}:uploadProps){

    const { classes, theme } = useStyles();
    const openRef = useRef<() => void>(null);
    const [downloadPermissions, setLocalDownloadPermissions] = useState<boolean[]>([]);
    const handleDownloadPermissionChange = (index: number) => {
      setLocalDownloadPermissions((prevPermissions) => {
        const newPermissions = [...prevPermissions];
        newPermissions[index] = !newPermissions[index];
        setDownloadPermissions(newPermissions); // Sync with parent state
        return newPermissions;
      });
    };

    const removeFile = (index: number) => {
      const newFiles = filesWithPath?.filter((_, i) => i !== index);
      setFilesWithPath(newFiles);
      setDownloadPermissions((prevPermissions) => {
          const updatedPermissions = prevPermissions.filter((_, i) => i !== index);
          return updatedPermissions;
      });
    };
    return (
      <div className="upload-file-container">
        <div className={classes.wrapper}>
          <Dropzone
            openRef={openRef}
            onDrop={(files) => {
                setFilesWithPath([...(filesWithPath || []), ...files]);
                // Initialize download permissions as false for new files
                setLocalDownloadPermissions((prev) => [
                    ...prev,
                    ...new Array(files.length).fill(false),
                ]);
                setDownloadPermissions((prev) => [
                    ...prev,
                    ...new Array(files.length).fill(false),
                ]);
            }}
            className={classes.dropzone}
            radius="md"
            maxSize={500 * 1024 ** 2}
          >
            <div style={{ pointerEvents: "none" }}>
              <Group position="center">
                <Dropzone.Accept>
                  <IconDownload
                    size={50}
                    color={theme.colors[theme.primaryColor][6]}
                    stroke={1.5}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX size={50} color={theme.colors.red[6]} stroke={1.5} />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconCloudUpload
                    size={50}
                    color={
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[0]
                        : theme.black
                    }
                    stroke={1.5}
                  />
                </Dropzone.Idle>
              </Group>

              <Text align="center" weight={700} size="lg" mt="xl">
                <Dropzone.Accept>Drop files here</Dropzone.Accept>
                <Dropzone.Reject>files less than 500mb</Dropzone.Reject>
                <Dropzone.Idle>Upload files</Dropzone.Idle>
              </Text>
              <Text align="center" size="sm" mt="xs" color="dimmed">
                Drag&apos;n&apos;drop files here to upload. We can accept only
                files that are less than 500mb in size.
              </Text>
            </div>
          </Dropzone>

          <Button
            className={classes.control}
            size="md"
            radius="xl"
            onClick={() => openRef.current?.()}
          >
            Select files
          </Button>
        </div>
        {filesWithPath?.map(function (file, i) {
          let extension = file.name.split(".").pop()?.toLocaleLowerCase();
          return (
            <div className="upload-file" key={i}>
              <div className="header-container">
                <div className='icon-container'>
                  {extension !== undefined
                    ? extensionsIcons[extension as keyof typeof extensionsIcons]
                    : extensionsIcons["default"]}
                </div>
                  {(setCurrentUser === "Global administrator")
                      ? <Checkbox
                          label="Allow download"
                          checked={downloadPermissions[i] || false}
                          onChange={() => handleDownloadPermissionChange(i)}
                      />
                      : <></>}
                  <ActionIcon  color="red" onClick={()=>removeFile(i)} >
                    <IconCircleMinus size={20} stroke={1.5}/>
                  </ActionIcon>
              </div>
              <span className="item-title" key={i}>
                {file.name}
              </span>
            </div>
          );
        })}
      </div>
    );
}

