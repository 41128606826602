import React, {useCallback, useEffect, useState} from "react";
import {Input, Select} from "antd";
import {countryRef, firmRef, taxSpecialismRef, usersRef,} from "../../../../../services/firebase/firebase";
import {API} from "../../../../../api";
import dataStore from "../../../../../stores/dataStore";
import {UserData} from "../../../../../models/general";
import {Radio, Tooltip} from "@mantine/core";
import TextArea from "antd/es/input/TextArea";
import {inject, observer} from "mobx-react";
var removeDiacritics = require('diacritics').remove
function EditFolderWithPermission({
                                                   prefixe,
                                                   listFirmIds,
                                                   listUserIds,
}: any) {
  const { Option } = Select;
  const listCountries = dataStore.countries;
  const [listTaxSpecialism, setListTaxSpecialism] = React.useState<string[]>([]);
  const [firms, setFirms] = React.useState<any>([]);
  const [users, setUsers] = React.useState<any>([]);
  const [status, setStatus] = React.useState<string>(prefixe.permission?.status);
  const [access, setAccess] = React.useState<string>(prefixe.permission?.access);
  const [description,setDescription]=useState<string>(prefixe.permission?.description);
  const [name,setName]=useState<string>(prefixe.permission?.name);

  //Handle change Data
  const handleChangeTaxSpecialism = useCallback((value) => {
    let listTaxSpecialism: any[] = [];
    for (let taxSpecialism in value) {
      listTaxSpecialism.push(getTaxSpecialismIdByName(value[taxSpecialism]))
    }
    dataStore.updatePermission(listTaxSpecialism as any,'taxSpecialism');
    return listTaxSpecialism;
  }, []);
  const handleChangeCountry = useCallback((value) => {
    let listCountry: any[] = [];
    for (let country in value) {
      listCountry.push(getCountryIdByName(value[country]))
    }
    dataStore.updatePermission(listCountry as any,'country');
    return listCountry;
  }, []);
  //Get data by name
  const getCountryIdByName = (countryName:string)=>{
    let idCountry;
    countryRef.on('value', async (snap) => {
      let result = snap.val();
      Object.keys(result).forEach(key => {
        if(result[key]===countryName){
          idCountry = key;
        }
      });
    });
    return idCountry;
  }
  const getTaxSpecialismIdByName = (taxSpecialismName:string)=>{
    let idTaxSpecialism;
    taxSpecialismRef.on('value', async (snap) => {
      let result = snap.val();
      Object.keys(result).forEach(key => {
        if(result[key]===taxSpecialismName){
          idTaxSpecialism = key;
        }
      });
    });
    return idTaxSpecialism;
  }
  // Load Data
  const loadDataCountry = () => {
    countryRef.on("value", async (snap) => {
      let newListCountry: any[] = [];
      let result = snap.val();
      for (let country in result) {
        const detailedCountry = await API.getCountryInfo(country);
        newListCountry.push(detailedCountry);
      }
      dataStore.updateCountries(newListCountry);
    });
  };
  const loadDataTaxSpecialism = () => {
    taxSpecialismRef.on("value", (snap) => {
      let result = snap.val();
      setListTaxSpecialism(result);
    });
  };
  const loadDataFirms = async () => {
    firmRef.on("value", async (snap) => {
      let firmsList: any[] = [];
      let firms = snap.val();
      for (let f in firms) {
        const detailedFirm = await API.getFirmInfo(f);
        if (!detailedFirm.deleted) {
          firmsList.push(detailedFirm);
        }
      }
      setFirms(firmsList);
      dataStore.updateFirms(firmsList);
    });
  };
  const loadDataUsers = async () => {
    usersRef.on("value", async (snap) => {
      let usersList: UserData[] = [];
      let users = snap.val();
      for (let f in users) {
        const detailedUser = await API.getUserInfo(f);
        if (!detailedUser.deleted) {
          usersList.push(detailedUser);
        }
      }
      setUsers(usersList);
      dataStore.updateUsers(usersList);
    });
  };
  // onChange Data
  const onChangeAccess = (e:any) => {
    setAccess(e);
    return dataStore.updatePermission(e, "access")
  };
  const onChangeStatus = (e:any) => {
    setStatus(e);
    return dataStore.updatePermission(e, "status")
  };

  const onChangeDescription = (e:any) => {
    setDescription(e)
    return dataStore.updatePermission(e.currentTarget.value, "description")
  };

  const onChangeName = (e:any) => {
    setName(e)
    return dataStore.updatePermission(e.currentTarget.value, "name")
  };

  function replaceSpecialChars(str:any)
  {
    let res=""
    if(str) {
      res = removeDiacritics(str)
    }

    return res?res:str
  }


  // First Load Component
  useEffect(() => {
    loadDataCountry();
    loadDataTaxSpecialism();
    loadDataFirms();
    loadDataUsers();
  }, []);
  useEffect(() => {
     dataStore.updatePermission(listFirmIds, "firm")
     dataStore.updatePermission(listUserIds, "user")
     dataStore.updatePermission(handleChangeCountry(prefixe?.permission?.country),'country')
     dataStore.updatePermission(handleChangeTaxSpecialism(prefixe?.permission?.taxSpecialism),'taxSpecialism')
     dataStore.updatePermission(prefixe?.permission?.status, "status")// eslint-disable-next-line
     dataStore.updatePermission(prefixe?.permission?.access, "access")// eslint-disable-next-line
     dataStore.updatePermission(prefixe?.permission?.description, "description")// eslint-disable-next-line
     dataStore.updatePermission(prefixe?.permission?.name, "name")// eslint-disable-next-line
  }, [listFirmIds,listUserIds,handleChangeCountry,handleChangeTaxSpecialism,prefixe])// eslint-disable-next-line
  return (
    <div>
      <div className="createFolderInput">
        <label className="InputLabel">Name</label>
        <br />
        <Input
            name="name"
            placeholder="Enter name Folder"
            style={{borderRadius: "5px"}}
            onChange={(name) => onChangeName(name)}
            defaultValue={name}
        />
      </div>
      <div className="createFolderInput">
        <label className="InputLabel">Description</label>
        <br />
        <TextArea
            rows={4}
            name="description"
            placeholder="Enter description"
            style={{borderRadius: "5px"}}
            onChange={(description) => onChangeDescription(description)}
            defaultValue={description}
        />
      </div>
      <div className="createFolderContainer">
        <Radio.Group
          name="status"
          label="Select folder status"
          description="Choose whether the folder is public or private"
          onChange={(status)=>onChangeStatus(status)}
          defaultValue={status}
          withAsterisk
        >
          <Radio value="public" label="Public" />
          <Radio value="private" label="Private" />
        </Radio.Group>
      </div>
      {status === "private" && (
        <div className="document-item-body-title">
          <span className="permision-title">Permissions</span>
          <div className="permissions-content">
            <label className="InputLabel">Firm</label>
            <br />
            <Select
              showSearch
              size="middle"
              placeholder="Select the firm"
              defaultValue={(!listFirmIds.includes(''))? listFirmIds.map((c: any) =>(c)) : []}
              mode="multiple"
              optionFilterProp="children"
              onChange={(value) => dataStore.updatePermission(value, "firm")}
              style={{ marginTop: "10px", width: "100%" , borderRadius: "5px" }}
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {firms
                .sort((a: any, b: any) => (a.firm > b.firm ? 1 : -1))
                .map((c: any) => (
                  <Option key={c.id} value={c.id}>
                    {c.firm !== undefined && c.firm !== "" ? c.firm : " "}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="input-container">
            <label className="InputLabel">Tax Specialism</label>
            <br />
            <Select
              size="middle"
              mode="multiple"
              placeholder="Select the tax specialism"
              optionFilterProp="children"
              defaultValue={(prefixe.permission?.taxSpecialism !== undefined && prefixe.permission?.taxSpecialism !== "")? prefixe.permission?.taxSpecialism : undefined}
              showArrow={true}
              allowClear={true}
              onChange={handleChangeTaxSpecialism ? (e) => handleChangeTaxSpecialism(e) : undefined}
              style={{ marginTop: "10px", width: "100%" , borderRadius: "5px" }}
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listTaxSpecialism.map((c: any) => (
                <Option key={c} value={c}>
                  {c}
                </Option>
              ))}
            </Select>
          </div>
          <div className="input-container">
            <label className="InputLabel">Country</label>
            <br />
            <Select
              size="middle"
              placeholder="Select a country"
              mode="multiple"
              optionFilterProp="children"
              defaultValue={(prefixe.permission.country !== undefined && prefixe.permission?.country !== "")? prefixe.permission?.country : undefined}
              onChange={handleChangeCountry ? (e) => handleChangeCountry(e) : undefined}
              style={{ marginTop: "10px", width: "100%" , borderRadius: "5px"}}
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listCountries
                .slice()
                .sort((a: any, b: any) => (a > b ? 1 : -1))
                .map((c: any) => (
                  <Option key={c} value={c}>
                    {c}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="input-container">
            <label className="InputLabel">Users</label>
            <br />
            <Select
              showSearch
              size="middle"
              placeholder="Select a user(s)"
              mode="multiple"
              defaultValue={listUserIds.filter((userId:string)=> dataStore.userExists(userId))}
              onChange={(value) => dataStore.updatePermission(value, "user")}
              style={{ marginTop: "10px", width: "100%" , borderRadius: "5px" }}
              filterOption={(input, option) =>
                  replaceSpecialChars(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {users
                .slice()
                .sort((a: any, b: any) => (a > b ? 1 : -1))
                .map((c: any) => (
                  <Option key={c.id} value={c.id}>
                    {c.firstName + " " + c.lastName}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      )}
      <div className="input-container">
        <Radio.Group
          name="access"
          label="Select type of access"
          description="Choose whether the folder had full access or limited access"
          onChange={(access)=>onChangeAccess(access)}
          defaultValue={access}
          withAsterisk
        >
          <Tooltip transition="slide-right" transitionDuration={300} label="Allow a full access on the folder except delete." withArrow>
              <Radio value="Full access" label="Full access" />
          </Tooltip>
          <Tooltip transition="slide-left" transitionDuration={300} label="Restricted access to folder and file downloads." withArrow>
             <Radio value="Limited" label="Limited" />
          </Tooltip>
        </Radio.Group>
      </div>
    </div>
  );
}

export default inject("authStore", "dataStore", "messageStore")(observer(EditFolderWithPermission));
