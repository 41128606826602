import React from 'react'
import { Button, Input, Tooltip } from 'antd';
import { Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
const { TextArea } = Input;

const { Option } = Select;


type Props = {
    
    data : any,
    handleChangefirm?: (value: any) => void,
    handleChangeGrade ?: (value: any) => void,
    handleChangeAreaOfIndustryExpertise?: (value: any) => void,
    handleChangeTaxSpecialism ?: (value: any) => void
    handleDataChange?: ({ currentTarget }: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void,
    handleOpen ?: () => void,
    firms ?: any,
    firm ?: any , 
    grades ?: any,
    grade ?: string ,
    listAreaOfIndustryExpertise ?: any,
    areaOfIndustryExpertise ?: string ,
    listTaxSpecialism ?: any,
    taxSpecialism ?: string ,
    action ?: 'Info' | 'Add' | 'Edit' | undefined,
    isArVis ?: boolean,
    dataRow ?: any
    checkUserCountryWithFirmCountryActive?:boolean
}

export default function PeopleInfo({
  handleChangefirm,
  handleChangeGrade,
  handleChangeAreaOfIndustryExpertise,
  handleChangeTaxSpecialism,
  firms,
  action,
  firm,
  grades,
  grade,
  listAreaOfIndustryExpertise,
  areaOfIndustryExpertise,
  listTaxSpecialism,
  taxSpecialism,
  handleOpen,
  data,
  handleDataChange,
  isArVis,
  dataRow, checkUserCountryWithFirmCountryActive
}: Props) {
  return (
    <div className="ContactContainer">
      <div className="InputSameRow">
        <div className="InputHalfSize">
          <label className="InputLabel">Firm {action !== "Info" ?
              <span style={{color: 'red'}}>*</span> : null}</label>
          <br />
          {action === 'Add' || action === 'Edit' ? (
              <>
            <Select
            showSearch
            size="large"
            placeholder="Select the firm"
            optionFilterProp="children"
            defaultValue={data.firm !== undefined ? data.firm.firm : undefined}
            value={action==="Add"? undefined : firm.firm}
            onChange={handleChangefirm ? (e) => handleChangefirm(e) : undefined}
            style={!!dataRow?.firm?.firm && dataRow?.type !== "Create" ? {border: "2px solid #5dc149", width: "100%"} : {marginTop: "10px", width: "100%"}}
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {firms
              .sort((a: any, b: any) => (a.firm > b.firm ? 1 : -1))
              .map((c: any) => (
                <Option key={c.id} value={c.id}>
                  {(c.firm !== undefined && c.firm !== "")? c.firm : " "}
                </Option>
              ))}
          </Select>
              {!checkUserCountryWithFirmCountryActive && (
              <label style={{alignItems:"center",display:"flex",marginTop:"5px"}} className="WarningLabel">
              <InfoIcon />
              <span>The user's country is not in the firm's list of countries</span>
              </label>
              )}
              </>
          ) : (
            <span style={{ fontFamily: "Arial", color: "#696868" }}>
              {data.firm !== undefined && data.firm.firm !== "" ? data.firm.firm : '- -'}
            </span>
          )}

        </div>
        <div className="InputHalfSize">
          <label className="InputLabel">Grade</label>
          <br />
          {action === 'Add' || action === 'Edit' ? (
            <Select
            showSearch
            size="large"
            placeholder="Select the grade"
            optionFilterProp="children"
            defaultValue={data.grade !== undefined ? data.grade : undefined}
            value={action === "Add" ? undefined : (grade && grade?.length>0? grade:[])}
            onChange={handleChangeGrade ? (e) => handleChangeGrade(e) : undefined}
            style={!!dataRow?.grade && dataRow?.type !== "Create" ? {border: "2px solid #5dc149",marginTop: "10px", width: "100%"} : {marginTop: "10px", width: "100%"}}
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {grades.map((c: any) => (
              <Option key={c} value={c}>
                {c}
              </Option>
            ))}
          </Select>
          ) : (
            <span style={{ fontFamily: "Arial", color: "#696868" }}>
              {data.grade !== undefined && data.grade !== "" ? data.grade : '- -'}
            </span>
          )}

        </div>
      </div>
      <div className="InputSameRow">
        <div className="InputHalfSize">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label className="InputLabel">Area of industry expertise</label>
            {(action === 'Add' || action === 'Edit' ) ? (
              <Tooltip title="Add new area">
              <Button
                type="primary"
                icon={<PlusOutlined style={{ fontSize: "14px" }} />}
                shape="circle"
                size="small"
                onClick={handleOpen}
              />
            </Tooltip> 
            ) : null}

          </div>
          {action === 'Add' || action === 'Edit' ? (
            <Select
            showSearch
            mode="multiple"
            size="large"
            showArrow={true}
            allowClear={true}
            placeholder="Select areas of industry Expertise"
            optionFilterProp="children"
            defaultValue={(data.areaOfIndustryExpertise !== undefined && data?.areaOfIndustryExpertise !== "") ? data.areaOfIndustryExpertise : undefined}
            value={action === "Add" ? undefined : (areaOfIndustryExpertise && areaOfIndustryExpertise?.length>0?areaOfIndustryExpertise:[])}
            onChange={handleChangeAreaOfIndustryExpertise ? (e) => handleChangeAreaOfIndustryExpertise(e) : undefined}
            style={dataRow?.areaOfIndustryExpertise !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #5dc149",marginTop: "10px", width: "100%"} : {marginTop: "10px", width: "100%"}}
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {listAreaOfIndustryExpertise.map((c: any) => (
              <Option key={c} value={c}>
                {c}
              </Option>
            ))}
          </Select>
          ) : (
            <span style={{ fontFamily: "Arial", color: "#696868" }}>
              {data.areaOfIndustryExpertise !== undefined && data.areaOfIndustryExpertise !== ""
                ? data.areaOfIndustryExpertise.join(", ")
                : '- -'}
            </span>
          )}
          
        </div>
        <div className="InputHalfSize">
          <label className="InputLabel">Tax Specialism</label>
          <br />
          {action === 'Add' || action === 'Edit' ? (
            <Select
            showSearch
            size="large"
            mode="multiple"
            placeholder="Select the tax specialism"
            optionFilterProp="children"
            showArrow={true}
            allowClear={true}
            defaultValue={(data.taxSpecialisme !== undefined && data?.taxSpecialisme !== "")? data.taxSpecialisme : undefined}
            value={action === "Add" ? undefined : taxSpecialism && taxSpecialism.length>0?taxSpecialism:[]}
            onChange={handleChangeTaxSpecialism ? (e) => handleChangeTaxSpecialism(e) : undefined}
            style={dataRow?.taxSpecialisme !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #5dc149",marginTop: "10px", width: "100%"} : {marginTop: "10px", width: "100%"}}
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {listTaxSpecialism.map((c: any) => (
              <Option key={c} value={c}>
                {c}
              </Option>
            ))}
          </Select>
          ) : (
              <span style={{ fontFamily: "Arial", color: "#696868" }}>
                {data.taxSpecialisme !== undefined && data.taxSpecialisme !== ""
                  ? data.taxSpecialisme.join(", ")
                  : '- -'} 
              </span>
            )}

        </div>
      </div>
      <br />
      <div className="InputFullSize">
        <label className="InputLabel">Professional Biography</label>
        <br /><br />
        {action === 'Add' || action === 'Edit' ? (
          <TextArea
          autoComplete="off"
          autoSize={false}
          style={dataRow?.profesionnalBiography !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #5dc149", height : "200px"} : { height : "200px"}}

          placeholder="Professional biography of the user"
          value={data.profesionnalBiography}
          name="profesionnalBiography"
          onChange={handleDataChange} 
        />
        ) : (
          <span style={{ fontFamily: "Arial", color: "#696868" }}>
            {(data.profesionnalBiography !== undefined && data.profesionnalBiography !== "" ) ? (
                <Typography paragraph style={{whiteSpace: 'pre-line'}}>{data.profesionnalBiography}</Typography>
            )  : '- -'}
          </span>
        )}

      </div>
      <br/>
      <div className="InputFullSize">
        <label className="InputLabel">Personal Biography</label>
        <br /><br />
        {action === 'Add' || action === 'Edit' ? (
          <TextArea
          autoComplete="off"
          autoSize={false}
          style={dataRow?.personnalBiography !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #5dc149",height : "200px"} : {height : "200px"}}

          placeholder="Personal biography of the user"
          value={data.personnalBiography}
          name="personnalBiography"
          onChange={handleDataChange}
        />
        ) : (
          <span style={{ fontFamily: "Arial", color: "#696868" }}>
            {(data.personnalBiography !== undefined && data.personnalBiography !== "" )? (
              <Typography paragraph style={{whiteSpace: 'pre-line'}}>{data.personnalBiography}</Typography>
            )  : '- -'}
          </span>
        )}
      </div>
    </div>
  );
}
