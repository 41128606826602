import { Button } from "@material-ui/core";
import React from "react";
import TaxandDialog from "../dialog/TaxandDialog";
import styles from "./DeleteConfirmationDialog.module.scss";

interface Props {
  parentStyle: string;
  open: boolean;
  resolveAction: any;
}

const DeleteConfirmationDialog = (props: Props) => {
  const { parentStyle, resolveAction, open } = props;

  return (
    <TaxandDialog
      title="Delete Confirmation"
      parentStyle={parentStyle}
      onClose={() => true}
      open={open}
    >
      <div  className={styles.dialogOutter}>
        <p>Are you sure you want to delete this request?</p>
        <div className={styles.buttonGroup}>
          <Button variant="contained" onClick={() => resolveAction(false)}>
            No
          </Button>
          <Button variant="outlined" onClick={() => resolveAction(true)}>
            Yes
          </Button>
        </div>
      </div>
    </TaxandDialog>
  );
};

export default DeleteConfirmationDialog;
