// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import 'firebase/database';
import 'firebase/functions';
import firebaseConfig from './firebaseConfig';

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app(); // if already initialized, use that one
}

const databaseRef = firebase.database();

const usersRef = databaseRef.ref().child("users");

const usersFirmRef = databaseRef.ref().child("users/firm");

const rightsRef=databaseRef.ref().child("rights");

const chatRef = databaseRef.ref().child("chats");

const firmRef = databaseRef.ref().child("firm");

const areaOfExpertiseRef = databaseRef.ref().child("area_of_expertise");

const countryRef = databaseRef.ref().child("country");

const groupChatRef = databaseRef.ref().child("groupChats");

const approvalRequestRef=databaseRef.ref().child("approval_request");

const taxSpecialismRef = databaseRef.ref().child("tax_specialism");

const areaOfIndustryExpertiseRef = databaseRef.ref().child("area_of_industry_expertise");

const gradeRef = databaseRef.ref().child("grade");

const fileRef = databaseRef.ref().child("document");

const folderRef = databaseRef.ref().child("folder");

const eventRef = databaseRef.ref().child("eventCalender");

const categoryRef = databaseRef.ref().child("category");



if (process.env.NODE_ENV === 'development') {
    firebase.functions().useFunctionsEmulator('http://localhost:5001');
}


export {
    firebase,
    databaseRef,
    usersRef,
    usersFirmRef,
    rightsRef,
    chatRef,
    firmRef,
    areaOfExpertiseRef,
    countryRef,
    groupChatRef,
    approvalRequestRef,
    taxSpecialismRef,
    areaOfIndustryExpertiseRef,
    gradeRef,
    fileRef,
    folderRef,
    eventRef,
    categoryRef
};



