import React, { createContext, useContext, useReducer } from 'react';
import { eventReducer } from "./eventReducer";

const EventContext = createContext<{ events: any[], dispatch: React.Dispatch<any> } | null>(null);

export function EventProvider({ children }: { children: React.ReactNode }) {
    const [events, dispatch] = useReducer(eventReducer, []);

    return (
        <EventContext.Provider value={{ events, dispatch }}>
            {children}
        </EventContext.Provider>
    );
}

export function useEventContext() {
    return useContext(EventContext)!;
}
