import React from "react";

export default function TooltipPassword(passwordVal: { displayVal: any; lowercase: any; uppercase: any; numeral: any; minChar: any; maxChar: any; specialChar: any; }) {
    return (
        <>
            <h5>Password must</h5>
            <ul className="field__rules divRight" style={{display: passwordVal.displayVal ,width: "100%" }}>
                <li id="letter" className={passwordVal.lowercase ? "valid" : "invalid"}>
                    have 1 Lower Case Alphabet
                </li>
                <li id="capital" className={passwordVal.uppercase ? "valid" : "invalid"}>
                    have 1 Upper Case Alphabet
                </li>
                <li id="number" className={passwordVal.numeral ? "valid" : "invalid"}>
                    have 1 Numeral Case Alphabet
                </li>
                <li id="length" className={passwordVal.minChar ? "valid" : "invalid"}>
                    Minimum 8 characters required
                </li>
                <li id="length" className={passwordVal.maxChar ? "valid" : "invalid"}>
                    Maximum of 20 characters only
                </li>
                <li id="specialchar" className={passwordVal.specialChar ? "valid" : "invalid"}>
                    have 1 Special Character [-@#$%^&]
                </li>
            </ul>
        </>
    );
}
